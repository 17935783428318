import React, { useEffect,useState,useRef } from 'react'
import './Stepper2.css'
import MapContainer from '../MapContainer/MapContainer'
import Loader from '../Loader/Loader'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import {setStep ,setLocation} from '../../../../redux/slices/locationSlice'

function Stepper2({nextStep,backStep,setForm,form,selectedLocation}) {
  const [loader,setLoader] = React.useState(false)
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const dispatch = useDispatch()
  const  addressContent = useSelector(state=>state.location)
  useEffect(() => {
    setForm({
      ...form,
      address: addressContent.location.formatted_address,
    });
     setCenter({
      lat: addressContent.location.geometry.location.lat(),
      lng: addressContent.location.geometry.location.lng(),
     })
  }, [])
  const mapRef = useRef(null);
  const handleNext = ()=>{
    setLoader(true);
    let intervalId = setInterval(() => {
      nextStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  }
  const handleBack = ()=>{
    setLoader(true);
    dispatch(setLocation(null))
    setForm({
      ...form,
      address: '',
    });
    let intervalId = setInterval(() => {
      backStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  }
  return (
    <div className='step2'>

<p className='addreser'>Is this your house? <small>{form.address}</small></p>
{/* <InputField/> */}
<div className='map'>
 {/* <MapContainer/> */}
 <GoogleMap
        mapContainerStyle={{ height: '400px', width: '100%' }}
        center={center}
        zoom={15}
        ref={mapRef}
      >
        {center.lat !== 0 && center.lng !== 0 && (
          <Marker position={center} />
        )}
      </GoogleMap>
  </div>
<div></div>
{loader && <Loader/>}
<div className='search'>
<button className='back' onClick={handleBack}>No, it isn't.</button>
<button  onClick={handleNext} disabled={loader}>Yes, it is.</button>
</div>

</div>
  )
}

export default Stepper2