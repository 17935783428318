import React from "react";
import "./fifthReady.css";

import Slide from "react-reveal/Slide";

const FifthReady = () => {
  return (
    <>
      <Slide left>
        <div className="fifthSec">
          <p className="ThirdParaFifth">
            Guaranteed Offer Frequently Asked Questions
          </p>

          <div className="quesAns">
            <h1>
              Q: What types of homes fit Yuna Homes' Guaranteed Offer program?
            </h1>
            <p className="ans">
              A: Our Guaranteed Offer program is tailored for single-family
              homes built after 1950 and valued between $50,000 - $450,000. We
              embrace various living situations, whether the homes are
              owner-occupied, tenant-occupied, vacant, or even distressed. The
              only exceptions are properties in flood zones, those that have
              fire damage, or those with any major foundation issues. We've
              designed our program to be as flexible and inclusive as possible,
              aligning with our commitment to ease and convenience in the home
              selling process.
            </p>
          </div>

          <div className="quesAns">
            <h1>Q: How does the Guaranteed Offer process work?</h1>
            <p className="ans">
              A: With Yuna Homes, submitting your home for a Guaranteed Offer is
              a straightforward process. Once we verify your home meets our
              program parameters, we'll extend a cash offer to you. Our unique
              approach allows you to close swiftly, without the typical hassles
              of the traditional selling process. With Yuna Homes, you can leave
              behind the burden of repairs, closing costs, and commissions,
              focusing instead on the excitement of moving on to the next
              chapter of your life.
            </p>
          </div>

          <div className="quesAns">
            <h1>
              Q: What type of offer will I receive on my home through the
              Guaranteed Offer program?
            </h1>
            <p className="ans">
              A: At Yuna Homes, we understand that selling your home is not just
              about speed, but about having the flexibility to choose a path
              that fits your unique needs and timeline. Our Guaranteed Offer
              provides you with a competitive all-cash offer, crafted to align
              with your real estate objectives. We're committed to working with
              you, offering the support and understanding you need to transition
              smoothly and comfortably to the next phase of your life. Our
              Guaranteed Offer isn't just a transaction; it's a tailored
              experience designed to suit you.
            </p>
          </div>

          <div className="quesAns">
            <h1>
              Q: How quickly will Yuna Homes Real Estate present my Guaranteed
              Offer?
            </h1>
            <p className="ans">
              A: At Yuna Homes, we value your time and strive to make the
              process as smooth and rapid as possible. Unlike other providers,
              we can present you with a Guaranteed Offer during our initial
              phone consultation. Our goal is to quickly assess your needs and
              present an offer that's tailored to you, without any unnecessary
              delays. Your peace of mind is our priority, and we're committed to
              moving at a pace that suits you.
            </p>
          </div>
          <div className="quesAns">
            <h1> Q: Can I receive multiple Guaranteed Offers?</h1>
            <p className="ans">
              A: Absolutely! At Yuna Homes Real Estate, we understand that every
              homeowner's situation is unique, and flexibility is key. Our
              Guaranteed Offer program is designed to provide you with several
              offer options tailored to your specific needs and goals. This way,
              you have the freedom to choose the option that fits your situation
              best. We're not just about selling your home; we're about finding
              the solution that's perfect for you.
            </p>
          </div>
          <div className="quesAns">
            <h1>
              Q: What is the benefit of working with Yuna Homes Real Estate to
              sell my home?
            </h1>
            <p className="ans">
              A: At Yuna Homes Real Estate, we're more than just a leading real
              estate team; we're your partners in achieving your unique
              homeownership goals. With our Guaranteed Offer program, you're not
              locked into a single path. We provide you with the flexibility to
              choose from diverse options tailored to your individual needs and
              circumstances. Whether you want to close quickly or need more time
              to plan your next move, we're here to support you with
              personalized solutions that put you in control. Experience the
              Yuna Homes difference, where your satisfaction and empowerment are
              our top priorities!
            </p>
          </div>
          <div className="quesAns">
            <h1>
              Q. Can I still sell my home if it does not meet the program
              parameters?
            </h1>
            <p className="ans">
              A: Absolutely! If your home is submitted to our Guaranteed Offer
              program and does not meet the parameters, we can pair you with one
              of our Exclusive Listing Agents to sell your home with our
              on-market and off-market strategies designed to make selling your
              home as easy, stress-free, and enjoyable as possible. Whether you
              prioritize time, convenience, or profit potential, we’ve got you
              covered!
       
            </p>
          </div>

          <p className="italic">*subject to terms and conditions*</p>
        </div>
      </Slide>
    </>
  );
};

export default FifthReady;
