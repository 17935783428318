/** @format */

import "./FormModal.css";
import "./ContactContact.css";
import crossModal from "../images/crossModal.png";

import { React, useState, useEffect } from "react";
import "./info.css";
import logoYuna from "../images/logoYuna.png";
import { BsChevronDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Modal from "react-modal";

const FormModal = ({ isOpen, onClose }) => {
  const selectForms = useSelector((state) => state);

  const navigate = useNavigate();

  const isSmallScreen = window.innerWidth < 450;
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    //meray
    street_address: "",
    street_address_two: "",
    city: "",
    state: "",
    zip: "",
    tell_us_more: "",
    when_to_sell: "",
    //end
    // interest: "",
    market: "",
    how_hear_about_us_platform: "",
    how_hear_about_us_specific: "",
  });
  const [prevPhone, setPrevPhone] = useState("");
  const [validFormat, setValidFormat] = useState(false);
  const selectedMarket = selectForms?.formCategroies?.marketplace?.find(
    (item) => item.name === data.market
  );

  let sourceOptionsKeys = [];
  if (selectedMarket) {
    sourceOptionsKeys = Object.keys(selectedMarket?.options);
  }

  let specificOptionsArray = [];
  if (selectedMarket && data.how_hear_about_us_platform) {
    specificOptionsArray =
      selectedMarket?.options[data?.how_hear_about_us_platform];
  }
  const optionsToRender = Array.isArray(specificOptionsArray)
    ? specificOptionsArray
    : [];

  const formatPhoneNumber = (str) => {
    // Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    // Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return {
        formattedNumber: "+1 (" + match[1] + ") " + match[2] + "-" + match[3],
        isValid: true,
      };
    }

    return {
      formattedNumber: str,
      isValid: false,
    };
  };

  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleChange = (event) => {
    const value = event.target.value;

    if (event.target.name === "phone") {
      if (
        value.length > prevPhone.length &&
        (value.match(/\d/g) || []).length <= 10
      ) {
        const result = formatPhoneNumber(value);
        setData({
          ...data,
          [event.target.name]: result.formattedNumber,
        });
        setValidFormat(result.isValid);
      } else if (value.length < prevPhone.length) {
        // User has pressed backspace
        setData({
          ...data,
          [event.target.name]: value,
        });
        setValidFormat(false);
      }

      setPrevPhone(value);
    } else {
      setData({
        ...data,
        [event.target.name]: value,
      });
    }
  };

  const handleSubmit = () => {
    const {
      first_name,
      last_name,
      email,
      phone,
      //meray
      street_address,
      street_address_two,
      city,
      state,
      zip,
      tell_us_more,
      when_to_sell,
      //end
      // interest,
      market,
      how_hear_about_us_platform,
      how_hear_about_us_specific,
    } = data;
    if (
      first_name === "" ||
      last_name === "" ||
      email === "" ||
      phone === "" ||
      street_address === "" ||
      street_address_two === "" ||
      city === "" ||
      state === "" ||
      zip === "" ||
      tell_us_more === "" ||
      when_to_sell === "" ||
      market === "" ||
      how_hear_about_us_platform === "" ||
      how_hear_about_us_specific === ""
    ) {
      toast.error("All fields must be filled");
      return;
    }

    // Email validation regex. It checks for most standard email formats.
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      toast.error("Email format is incorrect");
      return;
    }

    // Phone number validation: Checks for exactly 10 digits.
    const phoneRegex = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneRegex.test(phone)) {
      toast.error("Phone number format is incorrect");
      return;
    }

    if (how_hear_about_us_platform === "") {
      return toast.error("Please Select A How'd You Hear About Us?");
    }
    if (how_hear_about_us_platform === "Online") {
      if (how_hear_about_us_specific === "") {
        return toast.error("Please Select A How'd You Hear About Us?");
      }
    }

    let url = `${process.env.REACT_APP_API_KEY_URL}?type=feedback`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(data).toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Feedback submitted successfully.") {
          setData({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            street_address,
            street_address_two,
            city,
            state,
            zip,
            tell_us_more,
            when_to_sell,
            market: "",
            how_hear_about_us_platform: "",
            how_hear_about_us_specific: "",
          });

          // navigate.push("/response");
          navigate("/response");
        }
      })

      .catch((error) => {
        // console.error("Error:", error);
      });
  };
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  // Modal styles
  const modalStyles = {
    content: {
      top: "0",
      left: "0",
      //   transform: 'translate(-50%, -50%)',
      width: "100%", // Set the desired width
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      padding: "0px",
      border: "none",
    },
    overlay: {
      // backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000, // Adjust the zIndex value as needed
    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
      <div onClick={onClose} className='formModalMain'>
        <div
          className='FormModal'
          onClick={(event) => {
            event.stopPropagation();
          }}>
          <div onClick={onClose} className='crossModal'>
            <div style={{ color: "#fff", fontSize: 25, cursor: "pointer" }}>
              x
            </div>
          </div>
          <div className='FormModalHeadPara'>
            <h3>Need to sell your home? Contact us today!</h3>
            <p>
              "<span style={{ color: "#ffd400" }}>*</span>" indicates required
              fields
            </p>
          </div>
          <div className='FormModalsConnected'>
            <form className='ModalformMain' action=''>
              <div className='ModalTwoInputs'>
                <input
                  className='ModalFormm'
                  value={data.first_name}
                  onChange={handleChange}
                  name='first_name'
                  type='text'
                  placeholder='First Name'
                />
                <input
                  className='ModalFormm'
                  onChange={handleChange}
                  value={data.last_name}
                  name='last_name'
                  type='text'
                  placeholder='Last Name'
                />
              </div>
              <div className='ModalTwoInputs'>
                <input
                  className='ModalFormm'
                  onChange={handleChange}
                  value={data.email}
                  name='email'
                  type='text'
                  placeholder='Email'
                />
                <input
                  className='ModalFormm'
                  name='phone'
                  type='text'
                  value={data.phone}
                  placeholder='Phone'
                  onChange={handleChange}
                  onKeyUp={handleChange}
                />
              </div>
              <div className='ModalTwoInputs'>
                <input
                  className='ModalFormm'
                  value={data.street_address}
                  onChange={handleChange}
                  name='street_address'
                  type='text'
                  placeholder='Street Address'
                />
                <input
                  className='ModalFormm'
                  onChange={handleChange}
                  value={data.street_address_two}
                  name='street_address_two'
                  type='text'
                  placeholder='Address Line 2'
                />
              </div>

              <div className='inputNDrop'>
                <input
                  className='ModalFormm'
                  onChange={handleChange}
                  value={data.city}
                  name='city'
                  type='text'
                  placeholder='City'
                />
                <div className='ModalInputtt'>
                  <div className='selectArrowsInfo'>
                    <BsChevronDown />
                  </div>
                  <select
                    className='ModalSelect'
                    onChange={handleChange}
                    value={data.state}
                    name='state'
                    id='cars'>
                    <option value=''>State</option>
                    <option value='Market'>Allabama</option>
                    <option value='mercedes'>Alaska</option>
                    <option value='mercedes'>American Samoa</option>
                    <option value='mercedes'>Arizona</option>
                  </select>
                </div>
              </div>

              <div className='zipMain'>
                <input
                  className='ZIP'
                  onChange={handleChange}
                  value={data.zip}
                  name='zip'
                  type='text'
                  placeholder='ZIP'
                />
              </div>
              <div className='ModalMessage'>
                <textarea
                  className='ModaltextArea'
                  onChange={handleChange}
                  value={data.tell_us_more}
                  name='tell_us_more'
                  type='text'
                  placeholder='Tell Us More'
                />
              </div>

              <div className='ModalInputtt'>
                <div className='selectArrowsInfo'>
                  <BsChevronDown />
                </div>
                <select
                  className='ModalSelect'
                  name='when_to_sell'
                  // name="how_hear_about_us_platform"
                  value={data.when_to_sell}
                  onChange={handleChange}>
                  <option value=''>When do you plan sell</option>
                  <option value='Online'>30 - 60 days</option>
                  <option value='Billboard'>61 - 120 days</option>
                  <option value='Billboard'>Within next 6 months</option>
                  <option value='Billboard'>This year</option>
                  <option value='Billboard'>More than one year from now</option>
                </select>
              </div>

              <div className='ModalInputtt'>
                <div className='selectArrowsInfo'>
                  <BsChevronDown />
                </div>{" "}
                <select
                  className='ModalSelect'
                  name='market'
                  value={data.market}
                  onChange={(e) => {
                    setData({
                      ...data,
                      market: e.target.value,
                      how_hear_about_us_platform: "",
                      how_hear_about_us_specific: "",
                    });
                  }}>
                  <option value=''>Market</option>
                  <option value='Atlanta'>Atlanta</option>
                  <option value='Brimingham'>Brimingham</option>
                  <option value='Charlotte'>Charlotte</option>
                  <option value='Chattanoga'>Chattanoga</option>
                  <option value='Dalla-Fort Worth'>Dalla-Fort Worth</option>
                  <option value='Greensboro'>Greensboro</option>
                  <option value='Greensville'>Greensville</option>
                  <option value='Jackensville'>Jackensville</option>
                  <option value='Nashville'>Nashville</option>
                  <option value='Orlando'>Orlando</option>
                  <option value='Raleigh'>Raleigh</option>
                  <option value='Tampa'>Tampa</option>
                </select>
              </div>
              {data.market != "" && (
                <div className='inputtt'>
                  <div className='selectArrowsInfo'>
                    <BsChevronDown />
                  </div>
                  <select
                    className='ModalSelect'
                    name='how_hear_about_us_platform'
                    value={data.how_hear_about_us_platform}
                    onChange={handleChange}>
                    <option value=''>How'd you hear about us?</option>
                    {/* <option value="tv">TV</option> */}
                    <option value='Online'>Online</option>
                    {/* <option value="Radio">Radio</option> */}
                    <option value='Billboard'>Billboard</option>
                  </select>
                </div>
              )}
              {data.market != "" &&
                data.how_hear_about_us_platform != "" &&
                data.how_hear_about_us_platform != "Billboard" && (
                  <div className='inputtt'>
                    <div className='selectArrowsInfo'>
                      <BsChevronDown />
                    </div>{" "}
                    <select
                      className='ModalSelect'
                      name='how_hear_about_us_specific'
                      value={data.how_hear_about_us_specific}
                      onChange={handleChange}>
                      <option value=''>How'd you hear about us?</option>
                      <option value='Instagram'>Instagram</option>
                      <option value='Facebook'>Facebook</option>
                      <option value='Nextdoor'>Nextdoor</option>
                    </select>
                  </div>
                )}
            </form>

            <div className='formModalButtonn'>
              {data.message === "Feedback submitted successfully." ? (
                <Link to='/response'>
                  <button
                    onClick={() => {
                      handleSubmit();
                      navigate("/response");
                    }}
                    className='formToButton'>
                    Contact Us
                  </button>
                </Link>
              ) : (
                <Link to=''>
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className='formToButton'>
                    Contact Us
                  </button>
                </Link>
              )}
              <div className='ModalAboutParas'>
                {" "}
                <p style={{ cursor: "pointer" }}>Terms of Use </p>{" "}
                <p style={{ cursor: "pointer" }}>Privacy Policy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
