import React, { useEffect, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
// import "./overrides.css";
import "./TestimonialsCarousel.css";
import Card from "./Card";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loade
import ReviewPic1 from "../images/ReviewPic1.jpeg";
import ReviewPic2 from "../images/ReviewPic2.jpeg";
import ReviewPic3 from "../images/ReviewPic3.jpeg";
import Slide from "react-reveal/Slide";

import { useState } from "react";

const TestimonialsCarousel = () => {
  const [index, setIndex] = useState(0);
  const carouselRef = useRef();
  return (
    <>
 
        <div
          style={{
            position: "relative",
          }}
          
        >
           <Slide left >
          <Carousel
            ref={carouselRef}
            autoPlay={false}
            stopOnHover={true}
            showStatus={false}
            infiniteLoop={true}
            emulateTouch
            swipeable={true}
            showArrows={false}
            showThumbs={false}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            onChange={(e) => {
              setIndex(e);
              console.log("");
            }}
          >
            <Card
              testi="As someone who had never sold a home before, the process seemed
            daunting. But the moment I reached out to Yuna Homes, all my worries
            vanished. The team at Yuna Homes handled everything, from providing
            a competitive cash offer to taking care of all the closing costs,
            without involving any middlemen. They have a simple, straightforward
            process, which saved me from the stress of doing any repairs,
            staging or commissions. "
              image={ReviewPic1}
              name="— Satisfied Yuna Homes Client"
            />
            <Card
              testi="Yuna Homes was a lifesaver for my family and me. We inherited an older property that needed numerous repairs. The idea of fixing it up to sell seemed impossible until we found Yuna Homes. They accepted our property as-is, saving us the time, 
          money, and stress of renovating. On top of that, they covered all closing costs and didn't 
          charge any commissions, which was a huge relief financially."
              image={ReviewPic2}
              name="— Grateful Yuna Homes Client"
            />
            <Card
              testi="When I landed a new job across the country, I was over the moon. But the excitement quickly faded when I realized I had to sell my home. I feared the process would be long, drawn out, and filled with endless paperwork and showings. That's when a friend recommended Yuna Homes.
Their process was like a breath of fresh air. They bought my home as-is, saving me the trouble of scrambling for repairs amidst my move. "
              image={ReviewPic3}
              name="— Relieved Yuna Homes Client"
            />
          </Carousel>
          <div
            className="dotsCarousel"
            style={{
              position: "absolute",
              bottom: 30,
              left: 70,
              display: "flex",
              cursor: "pointer",
            }}
          >
            <div
              onClick={() => {
                carouselRef.current.moveTo(0);
              }}
              style={{ background: index == 0 ? "#ffd400" : "#000" }}
              className="ControlDot"
            ></div>
            <div
              onClick={() => {
                carouselRef.current.moveTo(1);
              }}
              style={{ background: index == 1 ? "#ffd400" : "#000" }}
              className="ControlDot"
            ></div>
            <div
              onClick={() => {
                carouselRef.current.moveTo(2);
              }}
              style={{ background: index == 2 ? "#ffd400" : "#000" }}
              className="ControlDot"
            ></div>
          </div>
          </Slide>
        </div>
  
    </>
  );
};

export default TestimonialsCarousel;
