import { React, useEffect, useState } from "react";
import "./bannerTwo.css";
import { Link } from "react-router-dom";
import Header from "./Header";
import Slide from 'react-reveal/Slide';

const BannerTwo = (first_name) => {
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
  
    return () => {

      window.removeEventListener('resize', handleResize);
    };
  }, []); 


  return (
    <>
      <Header />
      <Slide up >
      {windowWidth < 915 ?       <div className="feedbackResponseBannnerTwo">
        <h1>Get a guaranteed offer on your home, today.</h1>
  
        <Link to="/form">
          <button onClick={() => setTop(!top)}>Get My Offer</button>
        </Link>
      </div> :       <div className="feedbackResponseBannnerTwo">
        <h1>Get a guaranteed offer <br /> on your home, today.</h1>
  
        <Link to="/form">
          <button onClick={() => setTop(!top)}>Get My Offer</button>
        </Link>
      </div> }
      </Slide>

    </>
  );
};

export default BannerTwo;
