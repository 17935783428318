import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import "./Stepper6.css";
import {BsChevronDown} from 'react-icons/bs'
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
function Stepper6({ nextStep, backStep,setForm,form }) {
  const selectForms = useSelector(state=>state)
  console.log("selectForms",selectForms)
  function toPascalCase(str) {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word?.slice(1))
        .join(' ');
}
  const [focusedInput, setFocusedInput] = useState(null);

  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };
  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };
  const handleBack = ()=>{
    setLoader(true);
    let intervalId = setInterval(() => {
      backStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  }
  const [loader,setLoader] = useState(false);
  const handleNext = () => {
    const { kitchen_countertops, kitchen_appliances } = form;

    if (kitchen_countertops
      //  && kitchen_appliances
       ) {
      setLoader(true);
    let intervalId = setInterval(() => {
      nextStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
    } else {
      if(form.kitchen_countertops === ""){
        toast.error("Please Select Kitchen Countertops");
      }
      // else if(form.kitchen_appliances === ""){
      //   toast.error("Please Select Kitchen Appliances");
      // }
      // alert("Please fill all fields");
    }
  };

  return (
    <div className="step3">
      <ToastContainer/> 
      {/* <div className="input-field">
        <label>Kitchen Countertops<span className="importent">*</span></label>
       <div className="select-arrows">
        <BsChevronDown/>
        </div>  <select
          onFocus={() => handleInputFocus('kitchen_countertops')}
          onBlur={handleInputBlur}
          className={focusedInput === 'kitchen_countertops' ? 'focuseder' : ''}
          name="kitchen_countertops"
          value={form.kitchen_countertops}
          onChange={handleChange}
        >  
          <option value="" selected="selected">
            Select an Option
          </option>
          <option value="Laminate">Laminate</option>
          <option value="Tile">Tile</option>
          <option value="Granite">Granite</option>
          <option value="Stone">Stone</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div className="input-field">
        <label>Kitchen Appliances<span className="importent">*</span></label>
      <div className="select-arrows">
        <BsChevronDown/>
        </div>   <select
          onFocus={() => handleInputFocus('kitchen_appliances')}
          onBlur={handleInputBlur}
          className={focusedInput === 'kitchen_appliances' ? 'focuseder' : ''}
          name="kitchen_appliances"
          value={form.kitchen_appliances}
          onChange={handleChange}
        >  
       <option value="" selected="selected" >
    Select an Option
  </option>
  <option value="White">White</option>
  <option value="Black">Black</option>
  <option value="Stainless">Stainless</option>
  <option value="Other">Other</option>

        </select>
      </div> */}
        {selectForms?.formCategroies?.categories?.slice(4,6).map((category,index)=>{
       return    <div className="input-field" key={category.id}>
             <label>{toPascalCase(category.category_name)}</label>
             <div className="select-arrows">
            <BsChevronDown />
          </div>
          <select
            name={category.category_name}
            value={form[category.category_name]}
            onChange={(event) => {handleChange(event);
            }}
          >
           {category.options.map((option) => (
              <option key={option.id} value={option.option_value}>
                {option.option_label}
              </option>
            ))} 
            </select>
            </div>
      })}
      {loader && <Loader/>}
      <div className="search">
        <button className="back" onClick={handleBack}>
          Back
        </button>
        <button onClick={handleNext} disabled={loader}>Next</button>
      </div>
    </div>
  );
}

Stepper6.propTypes = {
  nextStep: PropTypes.func.isRequired,
  backStep: PropTypes.func.isRequired,
};

export default Stepper6;
