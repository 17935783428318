import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./Strategy.css";
import strategyZero from "../images/strategyZeroylw.png";
import strategyOne from "../images/strategyOneylw.png";
import strategyTwo from "../images/strategyTwoylw.png";
import strategyThree from "../images/strategyThreeylw.png";
import strategyFour from "../images/strategyFourylw.png";
import strategyFive from "../images/strategyFivelw.png";
import strategySix from "../images/strategySixylw.png";
import strategySeven from "../images/strategySevenylw.png";
import strategyLast from "../images/strategyEightylw.png";
import arrowcropped from "../images/arrowcropped.png";
 
const Strategy = () => {
  const imageUrls = [
    strategyZero,
    strategyOne,
    strategyTwo,
    strategyThree,
    strategyFour,
    strategyFive,
    strategySix,
    strategySeven,
    strategyLast,

  ];

  const DarkCircleContent = [
    "a team dedicated 7 days/week to calling & emailing about your listing",
    "radio ads tv ads billboards",
    "Facebook Instagram Twitter YouTube LinkedIn",
    "strong traffic to Yunahomes.com & high SEO ranking on page 1 of Google",
    "on Zillow and Trulia",
    "#1 most reviewed real estate company on Zillow in US",
    "paid Google ads on all our listings",
    "of experience and reputation with strong brand recognition in marketplace",
  ];
  const circleContent = [
    "Constant Support",
    "Traditional Marketing Footprint",
    "Social Media",
    "Web Traffic",
    "Premier Agent & Supervisor",
    "Online Reviews",
    "Google ads",
    "Years",
  ];
  const calculateImagePosition = (index) => {
    const totalImages = imageUrls.length - 1;
    const angle = (360 / totalImages) * index;

    const radius = 50; // Adjust the radius for spacing between images
    const centerX = 50; // Center X coordinate (percentage of the container's width)
    const centerY = 50; // Center Y coordinate (percentage of the container's height)

    // Convert the angle to radians
    const angleInRadians = (angle - 90) * (Math.PI / 180);

    const x = centerX + radius * Math.cos(angleInRadians);
    const y = centerY + radius * Math.sin(angleInRadians);

    return { x, y };
  };
  const [show, setShow] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  const [hoveredIndex, setHoveredIndex] = useState();
  const [isAnimating, setIsAnimating] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);



  const targetRef = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold as needed
    };
  
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isPlayed) {
          setIsAnimating(true);
          setIsPlayed(true);
        } else {
          setIsAnimating(false);
        }
      });
    };
  
    const observer = new IntersectionObserver(callback, options);
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
  
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [isPlayed]);
  const scaleFactor = isAnimating || isPlayed ? 1 : 0;
  const divStyle = {
    transform: `scale(${scaleFactor})`,
    transition: "transform 3s ease",
  };


  return (
    <div className="strategyMain">
      <p className="strategyPara">Our Strategy</p>
      <div className="circular-gallery" style={divStyle}>
        <div className="center-image">
          <img src={imageUrls[0]} alt="Center" />
          <p
          >Your Listing</p>
        </div>
        {imageUrls.slice(1).map((imageUrls, index) => {
          const position = calculateImagePosition(index);
          return (
            <div
              ref={targetRef}
              className="outer-image"
              style={{ left: `${position.x - 4}%`, top: `${position.y - 4}%` }}
            >
              <div
                onMouseEnter={() => {
                  setHoveredIndex(index);
                  setShow(true);
                }}
                onMouseLeave={() => {
                  setHoveredIndex();
                  setShow(false);
                }} 
                key={index}
              >
                {hoveredIndex != index && (
                  <div className="textTextImage">
                    <img 
                    style={{
                      marginTop: (windowWidth > 1024 && (index == 6 || index == 7)) ?  "17px" : (index == 6 || index == 7) ? "5px" : null, 
                      marginLeft: index == 4 ? "px" : null 
                    }}
                    src={imageUrls} alt={`Image ${index + 1}`} />
                    <div className="CircleImageText">
                      {circleContent[index]}
                    </div>
                  </div>
                )}
                {hoveredIndex == index && (
                  <div className="onHoverBlackCircle">
                    {/* <img src={imageUrl} alt={`Image ${index + 1}`} /> */}
                    <div
                      className="onHoverBlackCircleText"
                      style={
                        {
                          // color:"yellow",
                        }
                      }
                    >
                      {" "}
                      {DarkCircleContent[index]}
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  bottom:
                    windowWidth <= 630
                      ? 6 * -14
                      : windowWidth <= 1023
                      ? 14 * -14
                      : 19 * -13.3157,
                  width: windowWidth <= 630 ? 6 : windowWidth <= 1023 ? 14 : 19,
                  left: windowWidth >= 629 ? "51%" : -1.5,
                  // top: windowWidth <= 630 ? -1.5 : 0,
                  transform:
                    index == 0
                      ? "translateX(30%) translateY(-8%) rotate(0deg)"
                      : index == 1
                      ? "translateX(-640%) translateY(-35%) rotate(45deg)"
                      : index == 2
                      ? "translateX(-900%) translateY(-100%) rotate(90deg)"
                      : index == 3
                      ? "translateX(-640%) translateY(-172%) rotate(135deg)"
                      : index == 4
                      ? "translateX(-0%) translateY(-200%) rotate(180deg)"
                      : index == 5
                      ? "translateX(640%) translateY(-172%) rotate(225deg)"
                      : index == 6
                      ? "translateX(900%) translateY(-100%) rotate(270deg)"
                      : index == 7
                      ? "translateX(640%) translateY(-35%) rotate(310deg)"
                      : "none",
                }} 
                className="arrow"
                onMouseOver={(e) => {
                  e.stopPropagation();
                }}
              >
                <img
                  src={arrowcropped}
                  alt=""
                  style={{
                    height: "50%",
                    //  transition:"height 3s ease"
                  }}
                />{" "}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Strategy;
