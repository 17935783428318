import React from "react";
import "./HousesFlorida.css";
import proudIconThree from "../images/proudIconThree.png";
import thumbProud from "../images/thumbProud.png";
import proudIconTwo from "../images/proudIconTwo.png";
import proudIconFour from "../images/proudIconFour.png";
import proudIconFive from "../images/proudIconFive.png";
import proudIconSix from "../images/proudIconSix.png";
import proudIconSeven from "../images/proudIconSeven.png";
import proudIconEight from "../images/proudIconEight.png";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

const HousesFlorida = ({subHeadingg, title, one, two, three, four, five, onet, twot, threet, fourt, fivet,}) => {
  return (
    <>
      <div className="housesFlorida">
        <Slide left >
          <p className="subHeadingTrustedPartner">{subHeadingg}</p>
          <p style={{marginBottom:"10px"}}>{title} </p>
            <p className="secondParaHousesFlorida">The Yuna Homes Advantage</p>
        </Slide>
    
          <div className="floridaProudIconsMain">
            <div className="floridaIconDiv">
              
              <div className="floridaiconPara">
              <Slide up >
                <p style={{marginBottom:"10px", fontSize:"24px"}}>{onet}</p>
                {/* <img src={thumbProud} alt="" /> */}
                <div className="floridaYellow"></div>
                <p>
                {one}
                </p>
                </Slide>
              </div>
            </div>
            <div className="floridaIconDiv">
              
              <div className="floridaiconPara">
              <Slide up >
                <p style={{marginBottom:"10px", fontSize:"24px"}}>{twot}</p>
                {/* <img src={thumbProud} alt="" /> */}
                <div className="floridaYellow"></div>
                <p>
                {two}
               </p>
                </Slide>
              </div>
            </div>
            <div className="floridaIconDiv">
              
              <div className="floridaiconPara">
              <Slide up >
                <p style={{marginBottom:"10px", fontSize:"24px"}}>{threet}</p>
                {/* <img src={thumbProud} alt="" /> */}
                <div className="floridaYellow"></div>
                <p>
                {three}
                </p>
                </Slide>
              </div>
            </div>
            <div className="floridaIconDiv">
              
              <div className="floridaiconPara">
              <Slide up >
                <p style={{marginBottom:"10px", fontSize:"24px"}}>{fourt}</p>
                {/* <img src={thumbProud} alt="" /> */}
                <div className="floridaYellow"></div>
                <p>
                {four}
                </p>
                </Slide>
              </div>
            </div>
            <div className="floridaIconDiv">
              
              <div className="floridaiconPara">
              <Slide up >
                <p style={{marginBottom:"10px", fontSize:"24px"}}>{fivet}</p>
                {/* <img src={thumbProud} alt="" /> */}
                <div className="floridaYellow"></div>
                <p>
                {five}
                </p>
                </Slide>
              </div>
            </div>

          </div>
          <div className="floridaContactButtonn">
                <Link to="">
                  <button
                    onClick={() => {
                      {
                        // handleSubmit();
                        // console.log("dataaaaaaaa", data);
                      }
                    }}
               
                    className="formToButton"
                  >
                    Contact Us Today for Cash Offer!
                  </button>
                </Link>
              </div>
       
      </div>
    </>
  );
};

export default HousesFlorida;
