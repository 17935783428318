// import React from 'react'
// import room from '../images/room.webp';
// import './parallexEffectDrip.css'
// import FourthSec from './FourthSec';
// import ThirdSec from './ThirdSec';
// import FifthReadyDrip from './FifthReadyDrip';
// import house from "../images/house.png"
// const ParallexEffectDrip = () => {
//   return (
//    <>  
//   <ThirdSec />
//   <div class="">
//       <FourthSec
//        heading = "Ready For Your Offer? "
//       buttonText = "Get My Offer"
//       link = "/form"
//       backgroundd = {`url(${house})`}
//       />
//     <FifthReadyDrip />
//   </div>
//    </>
//   )
// }

// export default ParallexEffectDrip;


import React from 'react'
import room from '../images/room.webp';
import './parallexEffectDrip.css'
import FourthSec from './FourthSec';
import ThirdSec from './ThirdSec';
import FifthReadyDrip from './FifthReadyDrip';
import house from "../images/house.png"
import FloridaThirdSec from './FloridaThirdSec';
import FloridaFourthSec from './FloridaFourthSec';
import FloridaLast from './FloridaLast';

const ParallexEffectDrip = () => {
  return (
   <>  
  {/* <ThirdSec /> */}
  <FloridaThirdSec title={"Our Simple 3-Step Process of Buying a House in Georgia"} />
  <div class="">
    <FloridaFourthSec title={"Sell a House Fast in Georgia"} 
    summary={"Selling your home fast in Georgia is a significant decision, and at Yuna Homes, we're here to make the process not only quick and seamless but also incredibly rewarding. When you choose us to help you sell your home, you'll enjoy a host of benefits that set us apart."} 
    onet={"1. Quick and Hassle-Free Sales:"} 
    twot="2.No Agent Commissions or Fees:" 
    threet="3.Fair and Competitive Offers:" 
    one={"We specialize in swift, no-fuss home sales. Say goodbye to the lengthy listing process and the uncertainties that come with it. We buy houses as-is, sparing you the hassle of repairs and showings."} 
    two="When you work with us, you avoid the hefty commissions and fees associated with traditional real estate transactions. What you see is what you get, and you keep more of your hard-earned money." 
    three="Our offers are based on a fair market evaluation, ensuring you get a competitive price for your home. We're committed to making sure you're satisfied with the deal."/>
    <FloridaLast 
    title={"Your Trusted Cash Home Buyers in Georgia"} 
    p1="As your trusted cash home buyers in Georgia, we understand the hassles that
come with the traditional selling process. With Yuna Homes, you can bypass 
the stress and enjoy a seamless home sale experience." 
p2="Say goodbye to the daunting task of finding a real estate agent. Our team is 
here to assist you every step of the way, without the need for expensive 
commissions or fees. We'll buy your house for cash, as-is, so there's no need 
to worry about making costly repairs or spending time on renovations." 
p3="Cleaning and staging for open houses and showings? Not with us. We're 
ready to purchase your home in its current condition. No need to invest time 
and money in preparing your property for potential buyers." 
p4="Our commitment is to simplify the selling process, ensuring you can continue 
with your busy life without the added stress of a home sale. By selling your 
house fast, you can count on a straightforward and hassle-free experience. 
Selling a house in Georgia has never been this simple!"/>
  </div>
   </>
  )
}

export default ParallexEffectDrip;
