/** @format */

import Header from "../components/Header";
import Banner from "../components/Banner";
import SecondSec from "../components/SecondSec";
import ThirdSec from "../components/ThirdSec";
import FourthSec from "../components/FourthSec";
import FifthReady from "../components/FifthReady";
import SixthFAQ from "../components/SixthFAQ";
import Info from "../components/Info";
import FormPageMain from "./FormPageMain";
import { useDispatch } from "react-redux";
import { setMarketplace, setCategories } from "../redux/slices/formSlice";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FeedbackResponeMain from "../components/FeedbackResponseMain";
import { Helmet } from "react-helmet";

import backgroundBanner from "../images/CMA-Background.webp";
import ParallexEffect from "../components/ParallexEffect";
import { LiveChatWidget } from "@livechat/widget-react";

const HomePage = () => {
  function handleNewEvent(event) {
    console.log("LiveChatWidget.onNewEvent", event);
  }

  // const ChatWedget = (

  // );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Helmet></Helmet> */}
      {/* <LiveChatWidget
        license="13155753"
        visibility="maximized"
        onNewEvent={handleNewEvent}
      /> */}
      <Banner
        background={backgroundBanner}
        headingg={
          "Ready for a seamless sale? \n Let us take on the work, not you."
        }
        subHeadingg={
          "No repairs, no commissions, just a straightforward \n Guaranteed Offer on your home. Act today!"
        }
        // headinggPhone="Ready for a seamless sale? Let us take on the work, not you."
        // subHeadinggPhone="No repairs, no commissions, just a straightforward Guaranteed Offer on your home. Act today!"
        // subHeadingg="No repairs, no commissions, just a straightforward Guaranteed Offer on your home. Act today!"
        isAddressFieldEnable
      />
      <SecondSec
        Heading={
          <p className='secondPara'>
            We transform the home-selling process. <br /> Get a{" "}
            <span className='secondRed'>cash offer,</span> close swiftly and
            leave the burden of{" "}
            <span className='secondRed'>
              {" "}
              repairs, closing costs, and commissions
            </span>{" "}
            to us.
          </p>
        }
      />
      <ParallexEffect />
      <SixthFAQ buttonText='Get My Offer' />
      <Info />
    </>
  );
};

export default HomePage;
