import React from "react";
import AboutBanner from "../components/AboutBanner";
import AboutSecond from "../components/AboutSecond";
import AboutAbout from "../components/AboutAbout";
import AboutBlwStrategy from "../components/AboutBlwStrategy";
import AboutHistory from "../components/AboutHistory";
import AboutStoryMain from "../components/AboutStoryMain";
import AboutCarousel from "../components/AboutCarousel";
import AboutKnow from "../components/AboutKnow";
import "./About.css";
import AboutProud from "../components/AboutProud.js";
import AboutJoinTeam from "../components/AboutJoinTeam";
import FourthSec from "../components/FourthSec";
import Info from "../components/Info";
import AboutUsFourth from "../images/AboutUs_fourth.jpg"

const AboutUs = () => {
  return (
    <>
       <div style={{width:"100%"}}>
      <div className="AboutScreen">
        <AboutBanner />
        <AboutSecond />
        <AboutAbout />
        <div id="scrollCulture">
        <AboutBlwStrategy />
        </div>
        <div id="scrollHistory">
          <AboutHistory />
        </div>

        <AboutStoryMain />
     
        <AboutCarousel />
      
        <div id="scrollCarousel">
        <AboutProud />
        </div>
        <AboutKnow />
        <AboutJoinTeam
          leftHead="Agents"
          leftParaa="We are seeking professional, self-motivated and consultative team members in our residential real estate sales department."
          rightParaa="We are seeking a full time Client Concierge to join our growing team. This person will be a key contributor to the success of our team by providing marketing and listing support, managing the listing process, and customer interaction. We are looking for an individual who is a superstar, passionate about achievement, and learning-based. Working on our team, you will bring your positive attitude, consultative spirit, highly motivated drive, and desire for excellence in all that you accomplish."
          rightHead="Client Concierge"
          JoinTeamButtonContent="Explore job openings in a city near you."
          link = ""
        />
        <FourthSec
          heading="Get a Guaranteed Offer on your home today."
          buttonText="Start Now"
          backgroundd = {`url(${AboutUsFourth})`}
          link = "/form"
        />
        <Info />
      </div>
      </div>
    </>
  );
};

export default AboutUs;
