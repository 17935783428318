import { React, useState, useEffect } from "react";
import "./SecondSecThreeSell.css";

const SecondSecThreeSell = () => {
  //   const isSmallScreen = window.innerWidth < 470;


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener('resize', handleResize);
    };
  }, []);  // Empty array ensures that effect is only run on mount and unmount

  return (
    <>
      {windowWidth < 768 ?
        (

          <div className="secondSecSell">
            <p className="secondParaSell">
             <span style={{color:"#ffd400", fontWeight:"bold"}}>$2.9 billion</span>  in real estate sold since <span style={{color:"#ffd400", fontWeight:"bold"}}>2001</span>
              {/* The highest <span className="secondRed">guaranteed </span>cash  <span className="secondRed">offer </span>
    on your home.<span className="secondRed"> Forget</span> about closing costs, repairs or commissions. */}
            </p>
          </div>
        ) : (
          <div className="secondSecSell">
            <p className="secondParaSell">
            <span style={{color:"#ffd400", fontWeight:"bold"}}>$2.9 billion</span> in real estate sold since <span style={{color:"#ffd400", fontWeight:"bold"}}>2001</span>
              {/* The highest <span className="secondRed">guaranteed </span>cash  <span className="secondRed">offer </span>
    on your home. <br />
    <span className="secondRed"> Forget</span> about closing costs, repairs or commissions. */}
            </p>
          </div>

        )
      }

    </>
  );
};

export default SecondSecThreeSell;
