import React from "react";
import location from "../images/location.png";
import features from "../images/features.png";
import home from "../images/home.PNG";
import "./SellSettingPrice.css";
import Slide from "react-reveal/Slide";

const SellSettingPrice = () => {
  return (
    <div className="SellSettingPrice">
      <p className="SellPara">Setting the price of your home</p>

      <Slide left>
        <p className="sellParaTwo">
          To set the list price of your home, we conduct what’s called a
          Comparative Market Analysis (CMA) that gives you a real-time snapshot
          of market conditions and other homes similar to yours.
        </p>
      </Slide>
      <Slide left>
        <div className="LineYelloww"></div>
        <h4
          style={{
            width: "80%",
            fontFamily: "proxima-nova, sans-serif",
            fontStyle: "normal",
            fontWeight: 300,
            color: "#4a4646",
            marginTop: "60px",
            marginBottom: "0px",
            fontSize: "25px", // Set font size to 25px
            textAlign: "center",
            lineHeight: 1.5,
            opacity: 0.9,
          }}
          class="Selltext-center"
        >
          We will consider:
        </h4>
      </Slide>

      <div className="selliconsMain">
      <Slide up> 
        <div className="sellIcons">
            <img 
              src={location}
              className="zoom-on-hover"
              alt=""
              style={{
                width: "16%",
              }} 
            />
            <h4>Location</h4>
        </div>
        </Slide> 
        <Slide up> 
        <div className="sellIcons">
         
            <img
              src={features}
              alt=""
              style={{
                width: "16%",
              }}
            />
            <h4>Home Features</h4>
            {/* <p>Don’t worry about repairs, closing costs, and commissions.</p> */}
         
        </div>
        </Slide>
         <Slide up>
        <div className="sellIcons"> 
         
            <img
              src={home}
              alt=""
              style={{
                width: "16%",
              }}
            />
            <h4>Comparables</h4>
        
        </div>
        </Slide>
      </div>

      <p className="SellParaLast">
        Get Your Comparative Market Analysis and Sell Your Home Today With Yuna
        Homes Real Estate.{" "}
      </p>
    </div>
  );
};

export default SellSettingPrice;
