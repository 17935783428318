import { React, useState, useEffect } from "react";
import "./secondSecTwo.css";
import Slide from "react-reveal/Slide";
const SecondSecTwo = () => {
  //   const isSmallScreen = window.innerWidth < 470;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <>
      <Slide left>
        {windowWidth < 768 ? (
          <div className="secondSec">
            <p className="secondPara">
              The highest <span className="secondRed">guaranteed </span>cash{" "}
              <span className="secondRed">offer </span>
              on your home.<span className="secondRed"> Forget</span> about
              closing costs, repairs or commissions.
            </p>
          </div>
        ) : (
          <div className="secondSec">
            <p className="secondPara">
              The highest <span className="secondRed">guaranteed </span>cash{" "}
              <span className="secondRed">offer </span>
              on your home. <br />
              <span className="secondRed"> Forget</span> about closing costs,
              repairs or commissions.
            </p>
          </div>
        )}
      </Slide>
    </>
  );
};

export default SecondSecTwo;
