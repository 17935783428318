import React from "react";
import AboutStoryBlack from "./AboutStoryBlack";
import './AboutStoryMain.css'
import AboutStoryWhite from "./AboutStoryWhite";
import proudIconEightWhite from "../images/proudIconEightWhite.png";

const AboutStoryMain = () => {
  return (
    <>
      <div className="AboutStoryMain">
        <div className="aboutStoryMainBlack"><AboutStoryBlack /></div>
        <div className="aboutStoryMainWhite"> <AboutStoryWhite/></div>
       <div className="ourSuccess">
            <img src={proudIconEightWhite} alt="" />

            <p>Our Success</p>
       </div>
      </div>
    </>
  );
};

export default AboutStoryMain;
