import Header from "../components/Header";
import Banner from "../components/Banner";
import BannerTwo from "../components/BannerTwo";
// import SecondSecTwo from "../components/secondSecTwo";
// import SecondSecTwo from "../../src/components/SecondSecTwo"
import SecondSecTwo from "../components/SecondSecTwo";
import ThirdSecGuaranteed from "../components/ThirdSecGuaranteed";
import FourthSec from "../components/FourthSec";
import FifthReady from "../components/FifthReady";
import SixthFAQ from "../components/SixthFAQ";
import Info from "../components/Info";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import Card from "../components/Card";
import house from "../images/house.png"

const GuaranteedOffer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
   <div style={{width:"100%"}}>
      <BannerTwo/>
      <SecondSecTwo />
      <ThirdSecGuaranteed />
      <FourthSec 
      heading = "Ready For An Offer? "
      buttonText = "Get a Guaranteed Offer"
      link = "/form"
      backgroundd = {`url(${house})`}
      />
      <FifthReady />
      <SixthFAQ
      heading = "Ready For An Offer"
      buttonText = "Get My Offer"/>
      <Info />
      {/* <TestimonialsCarousel />
      <Card /> */}
      </div>
    </>
    
  );
};

export default GuaranteedOffer;
