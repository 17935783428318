import { configureStore } from '@reduxjs/toolkit';
import {locationSlice} from '../slices/locationSlice';
import  {fcSlice} from '../slices/formSlice';
// import {formComponentSlice} from '../slices/formSlice';  
  const store = configureStore({
    reducer: {
        location: locationSlice.reducer,
        formCategroies: fcSlice.reducer ,
    }
});

export default store;   