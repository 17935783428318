import React from 'react'
import './FloridaThirdSec.css'
import consultation from '../images/consult.png'
import propertySearch from '../images/propertySearch.png'
import shake from '../images/shake.png'
import Slide from "react-reveal/Slide";

const FloridaThirdSec = ({title}) => {
  return (
    <>
    <div className ='floridaThird'>
        <div className='floridaInnerBlack'>
      <Slide left>
        {/* <div className="LineYelloww"></div> */}
        <h4
          style={{
            width: "80%",
            fontSize: 31,
            fontFamily: "proxima-nova, sans-serif",
            fontStyle: "normal",
            fontWeight: 300,
            color: "white",
            marginTop: "60px",
            marginBottom: "0px",
            textAlign: "center",
            lineHeight: 1.5,
            opacity: 0.9,
          }}
          class="floridaSelltextCenter"
        >
          {title}
          {/* Our Simple 3-Step Process of Buying a House in Florida */}
        </h4>
      </Slide>

      <div className="floridaSelliconsMain">
      <Slide up> 
        <div className="floridasellIcons">
            <img 
              src={consultation}
              className="zoom-on-hover"
              alt=""
              style={{
                width: "16%",
              }} 
            />
            <h4>Consultation</h4>
            <h5
            style={{marginTop:"0px"}}>
                Understand your unique needs and preferences. Discuss your budget, location preferences, and must-have features for your new home.
                </h5>
        </div>
        </Slide> 
        <Slide up> 
        <div className="floridasellIcons">
         
            <img
              src={propertySearch}
              alt=""
              style={{
                width: "16%",
              }}
            />
            <h4>Property Search and Selection</h4>
            {/* <p>Don’t worry about repairs, closing costs, and commissions.</p> */}
            <h5
            style={{marginTop:"0px"}}>
               We'll identify options that match your criteria, 
providing you with a carefully curated list of potential homes. You'll have the opportunity to view and evaluate these properties, 
narrowing down your choices until you find 
the one that feels like the perfect fit.
 </h5>
        </div>
        </Slide>
         <Slide up>
        <div className="floridasellIcons"> 
         
            <img
            
              src={shake}
              alt=""
              style={{
                width: "16%",
              }}
            />
            <h4>Closing</h4>
            <h5
            style={{marginTop:"0px"}}>
               Handle all the paperwork and 
documentation, ensuring a smooth 
and efficient closing process.
 </h5>
        </div>
        </Slide>
      </div>

      <p className="floridaSellParaLast">
      Our 3-step process, backed by our expertise and dedication to your satisfaction, is designed to do just that. 
Let us help you find your next home and turn your homeownership dreams into reality.
      </p>
        </div>
    </div>
    </>
  )
}

export default FloridaThirdSec