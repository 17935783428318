import React from "react";
import "./sixth.css";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
const SixthFAQ = (props) => {
  return (
    <>
      <Slide left >
        <div className="fourthSec2">
          <div className="redCard2">
            <h3>Get a Guaranteed Offer on your home today.</h3>
            <Link to="/form">
              <button>{props.buttonText}</button>
            </Link>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default SixthFAQ;
