import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import "./Stepper4.css";
import Loader from "../Loader/Loader";
import {BsChevronDown} from 'react-icons/bs'
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';

function Stepper4({ nextStep, backStep,setForm,form }) {
  // const [form, setForm] = useState({
  //   bedrooms: "",
  //   bathrooms: "",
  //   squareFootage: "",
  //   propertyType: "",
  //   hoaType: "",
  //   annualHoaFee: "",
  // });
  const [focusedInput, setFocusedInput] = useState(null);
  const selectForms = useSelector(state=>state)
  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };
  const handleBack = ()=>{
    setLoader(true);
    let intervalId = setInterval(() => {
      backStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  }
  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };
  const [loader,setLoader] = useState(false);
  const handleNext = () => {
    const {
      bedrooms,
      bathrooms,
      square_footage,
      property_type,
      // hoa_type,
      // annual_hoa_fee,
    } = form;

    if (
      bedrooms &&
      bathrooms &&
      square_footage
      //  &&
      // property_type &&
      // hoa_type 
    ) {
      if (form.bedrooms == '' ) {
        return   toast.error("Please Enter Number of Bedrooms")
         } 
         if(form.bedrooms < 0){
           return   toast.error("Please Enter Valid Number of Bedrooms")
         }
         if (form.bathrooms == '') {
     return      toast.error("Please Enter Number of Bathrooms")
         }   if(form.bathrooms <  0){
           return   toast.error("Please Enter Valid Number of Bathtooms")
         }
         
         if (form.square_footage == '') {
         return  toast.error("Please Enter Square Foot")
         } if(form.square_footage < 500){
       return    toast.error("Squar Foot Must be Greater Than 500")
         }
         if(form.square_footage > 10000){
          return    toast.error("Squar Foot Must be Lesser Than 10000")
            }
        //  if (form.property_type == '') {
        // return   toast.error("Please Select Property Type")
        //  }   if (form.hoa_type == '') {
        // return   toast.error("Please Select HOA Type")
        //  }if( form.hoa_type !== 'None'){
        //     if(form.annual_hoa_fee == ''){
        // return   toast.error("Please Enter Annual HOA Fee")}
        //  }else{
          
        //  }
       if(
        // form.hoa_type !== ''   && form.property_type !== '' && 
        form.square_footage !== '' && form.bathrooms !== '' && form.bedrooms !== '')  
      setLoader(true);
      let intervalId = setInterval(() => {
        nextStep();
      }, 2000);
      setTimeout(() => {
        setLoader(false);
        clearInterval(intervalId);
      }, 2000);
    } else{
      if (form.bedrooms == '' ) {
        return   toast.error("Please Enter Number of Bedrooms")
         } 
         if(form.bedrooms < 0){
           return   toast.error("Please Enter Valid Number of Bedrooms")
         }
         if (form.bathrooms == '') {
     return      toast.error("Please Enter Number of Bathrooms")
         }   if(form.bathrooms <  0){
           return   toast.error("Please Enter Valid Number of Bathtooms")
         }
         
         if (form.square_footage == '') {
         return  toast.error("Please Enter Square Foot")
         } if(form.square_footage < 500){
       return    toast.error("Squar Foot Must be Greater Than 500")
         } if(form.square_footage > 10000){
       return    toast.error("Squar Foot Must be Less Than 10000")
      }
        //  if (form.property_type == '') {
        // return   toast.error("Please Select Property Type")
        //  }   if (form.hoa_type == '') {
        // return   toast.error("Please Select HOA Type")
        //  }
     
    }
     
    }
    function toPascalCase(str) {
      return str
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
  }
  return (
    <div className="step3">
      <ToastContainer />
      <div className="input-field">
        <label>How many bedrooms does your home have?<span className="importent">*</span></label>
        <input
          name="bedrooms"
          onFocus={() => handleInputFocus('bedrooms')}
          onBlur={handleInputBlur}
          className={focusedInput === 'bedrooms' ? 'focused' : ''}
          
          value={form.bedrooms}
          onChange={handleChange}
          placeholder=""
          type="number"
          min={1}
        />
      </div>
      <div className="input-field">
        <label>How many bathrooms does your home have?<span className="importent">*</span></label>
        <input
          name="bathrooms"
          value={form.bathrooms}
          onFocus={() => handleInputFocus('bathrooms')}
          onBlur={handleInputBlur}
          className={focusedInput === 'bathrooms' ? 'focused' : ''}
          
          onChange={handleChange}
          placeholder=""
          type="number"
          min={1}
        />
      </div>
      <div className="input-field">
        <label>Square footage of the property<span className="importent">*</span></label>
        <input
          name="square_footage"
          value={form.square_footage}
          onChange={handleChange}
          onFocus={() => handleInputFocus('square_footage')}
          onBlur={handleInputBlur}
          className={focusedInput === 'square_footage' ? 'focused' : ''}
          max={10000}
          placeholder=""
          min={500}
          type="number"
        />
      </div>
      {/* <div className="input-field">
        <label>Property Type<span className="importent">*</span></label>
      <div className="select-arrows">
        <BsChevronDown/>
        </div>  <select
          onFocus={() => handleInputFocus('property_type')}
          onBlur={handleInputBlur}
          className={focusedInput === 'property_type' ? 'focuseder' : ''}
      
          name="property_type"
          value={form.property_type}
          onChange={handleChange} >  
          <option value=""> Select a Property Type</option>
          <option value="Single Family Home Detached">
            Single Family Home Detached
          </option>
          <option value="Single Family Home Attached">
            Single Family Home Attached
          </option>
          <option value="Modular Or Manufactured Home">
            Modular Or Manufactured Home
          </option>
          <option value="Mobile Home"> Mobile Home</option>
        </select>
      </div>
      <div className="input-field">
        <label>HOA Type<span className="importent">*</span></label><div className="select-arrows">
        <BsChevronDown/>
        </div>
        <select 
          onFocus={() => handleInputFocus('hoa_type')}
          onBlur={handleInputBlur}
          className={focusedInput === 'hoa_type' ? 'focuseder' : ''}
      
        name="hoa_type" value={form.hoa_type} onChange={handleChange}>   
         <option value="">Select A HOA Type</option>
  <option value="None">None</option>
  <option value="Mandatory">Mandatory</option>
  <option value="Annual Amount">Annual Amount</option>
  <option value="Voluntary">Voluntary</option>
        </select>
      </div> */}
      {selectForms?.formCategroies?.categories?.slice(0,2).map((category,index)=>{
       return    <div className="input-field" key={category.id}>
             <label>{toPascalCase(category.category_name)}</label>
             <div className="select-arrows">
            <BsChevronDown />
          </div>
          <select
            name={category.category_name}
            value={form[category.category_name]}
            onChange={(event) => {handleChange(event);
            }}
          >
           {category.options.map((option) => (
              <option key={option.id} value={option.option_value}>
                {option.option_label}
              </option>
            ))} 
            </select>
            </div>
      })}
  { 
  (form.hoa_type === 'None' || form.hoa_type === '' || form.hoa_type === undefined || form.hoa_type === null) ?  null :
  <div className="input-field">
    <label>Annual HOA Fee <span className="importent">*</span></label>
    <input 
      name="annual_hoa_fee"
      type="number"
      onFocus={() => handleInputFocus('annual_hoa_fee')}
      onBlur={handleInputBlur}
      className={focusedInput === 'annual_hoa_fee' ? 'focused' : ''}
       min={1}
      value={form.annual_hoa_fee}
      onChange={handleChange} />
  </div>
  
}
      <div>
        {loader && <Loader/>}
        <div className="search">
          <button className="back" onClick={handleBack}>
            Back
          </button>
          <button onClick={handleNext} disabled={loader}>Next</button>
        </div> 
      </div>
    </div>
  );
}

Stepper4.propTypes = {
  nextStep: PropTypes.func.isRequired,
  backStep: PropTypes.func.isRequired,
};

export default Stepper4;


//     Select an HOA Type
//   
// 
