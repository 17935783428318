import { React, useState, useEffect } from "react";
import "./AboutStoryBlack.css";
import { Link } from "react-router-dom";
import FormModal from "./FormModal";

const AboutStoryBlack = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
 
      window.removeEventListener("resize", handleResize);
    };
  }, []); 
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      {windowWidth < 768 ? (
        <div className="AboutStoryBlack">
          <p className="AboutSecondRed">Why You'll Choose Us </p>
          <p className="AboutSecondPara">
            Our drive to deliver an unprecedented client experience is what
            makes us work tirelessly to become the most trusted name in
            residential real estate.
          </p>
          <Link to="/form">
            <button
              onClick={() => {
                setTop(!top);
                // openModal();
              }}
            >
              Sell Your Home
            </button>
          </Link>
          <FormModal isOpen={modalIsOpen} onClose={closeModal} />
        </div>
      ) : (
        <div className="AboutStoryBlack">
          <p className="AboutSecondRed">Why You'll Choose Us</p>
          <p className="AboutSecondPara">
            Our drive to deliver an unprecedented client experience is what
            makes us work tirelessly to become the most trusted name in
            residential real estate.
          </p>
    
          <Link to="/form">
            <button
              onClick={() => {
                setTop(!top);
                // openModal();
              }}
            >
              Sell Your Home
            </button>
          </Link>
          <FormModal isOpen={modalIsOpen} onClose={closeModal} />
        </div>
      )}
    </>
  );
};

export default AboutStoryBlack;
