/** @format */

// import React, { useState, useEffect, useRef } from "react";
// import googlepore from "../../../../images/download.png";
// import { ImLocation } from "react-icons/im";
// import asyncScript from "react-async-script";

// // const loadGoogleMapsApi = () => new Promise((resolve, reject) => {
// //   // Check if the Google Maps API has already been loaded
// //   if (window.google) {
// //     return resolve(window.google);
// //   }
// //   // Create a script element to insert into the page
// //   const script = document.createElement('script');
// //   script.src = `https://maps.googleapis.com/maps/api/js?key=GOOGLE_API_KEY_PLACEHOLDER&libraries=places`;
// //   script.async = true;
// //   script.defer = true;
// //   script.addEventListener('load', () => {
// //     // The Google Maps API has just finished loading
// //     resolve(window.google);
// //   });
// //   script.addEventListener('error', (error) => {
// //     reject(error);
// //   });
// //   // Insert the script element into the page
// //   document.body.appendChild(script);
// // });

// const CustomSelection = ({ isScriptLoaded, isScriptLoadSucceed, onSelect }) => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [searchResults, setSearchResults] = useState([]);
//   const autocompleteService = useRef(null);
//   const placesService = useRef(null);
//   const googleMapsApiUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAA169LGvdR7tgT5dcPBq0Ga3l-cml6frA&libraries&callback=initMap`;

//   useEffect(() => {
//     // Only append the script if it does not already exist
//     if (!document.querySelector(`script[src="${googleMapsApiUrl}"]`)) {
//       const googleMapScript = document.createElement('script');
//       googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAA169LGvdR7tgT5dcPBq0Ga3l-cml6frA&libraries&callback=initMap`;
//       googleMapScript.async = true;
//       window.initMap = () => {
//         // Google Maps is ready to use, you can initialize your Autocomplete or Places services here
//       };
//       document.body.appendChild(googleMapScript);
//     }
//   }, []);

//   // useEffect(() => {
//   //   if (
//   //     isScriptLoaded &&
//   //     isScriptLoadSucceed &&
//   //     !autocompleteService.current &&
//   //     !placesService.current
//   //   ) {
//   //     autocompleteService.current =
//   //       new window.google.maps.places.AutocompleteService();
//   //     placesService.current = new window.google.maps.places.PlacesService(
//   //       document.createElement("div")
//   //     );
//   //   }
//   // }, [isScriptLoaded, isScriptLoadSucceed]);
//   // useEffect(() => {
//   //   if (!window.google) {
//   //     // console.error('Google Places API not available');
//   //     return;
//   //   }

//   //   autocompleteService.current = new window.google.maps.places.AutocompleteService();
//   //   placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
//   // }, []);
//   // useEffect(() => {
//   //   window.initMap = () => {
//   //     autocompleteService.current = new window.google.maps.places.AutocompleteService();
//   //     placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
//   //   };
//   //   if (window.google) {
//   //     window.initMap();
//   //   }
//   //   if (!window.google) {
//   //         // console.error('Google Places API not available');
//   //         return;
//   //       }
//   // }, []);
//   // useEffect(() => {
//   //   loadGoogleMapsApi()
//   //     .then((googleMaps) => {
//   //       autocompleteService.current = new googleMaps.places.AutocompleteService();
//   //       placesService.current = new googleMaps.places.PlacesService(document.createElement('div'));
//   //     })
//   //     .catch((error) => {
//   //       console.error('Error loading Google Maps JavaScript API: ', error);
//   //     });
//   // }, []);

//   const handleInputChange = (event) => {
//     const inputValue = event.target.value;
//     setSearchTerm(inputValue);

//     if (inputValue.trim() === "") {
//       setSearchResults([]);
//       return;
//     }

//     if (!autocompleteService.current) {
//       return;
//     }

//     // Fetch place predictions based on the user's input
//     autocompleteService.current.getPlacePredictions(
//       { input: inputValue },
//       (predictions, status) => {
//         if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
//           return;
//         }

//         setSearchResults(predictions);
//       }
//     );
//   };

//   const handleOptionSelect = (option) => {
//     setSearchTerm(option.description);
//     setSearchResults([]);
//     if (!placesService.current) {
//       return;
//     }
//     placesService.current.getDetails(
//       { placeId: option.place_id },
//       (place, status) => {
//         if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
//           return;
//         }
//         onSelect(place);
//       }
//     );
//   };
//   const [isInputFocused, setInputFocused] = useState(false);

//   const handleInputFocus = () => {
//     setInputFocused(true);
//   };

//   const handleInputBlur = () => {
//     setInputFocused(false);
//   };
//   return (
//     <>
//       <input
//         type="text"
//         value={searchTerm}
//         style={{ width: "100%", margin: "0", height: "45px", fontSize: "18px" }}
//         onChange={handleInputChange}
//         placeholder="Enter your address"
//         onFocus={handleInputFocus}
//         onBlur={handleInputBlur}
//         className={isInputFocused ? "focused" : ""}
//       />
//       {searchResults.length > 0 && (
//         <ul>
//           {searchResults.map((result) => (
//             <li
//               key={result.place_id}
//               onClick={() => handleOptionSelect(result)}
//             >
//               <span>
//                 <ImLocation />
//               </span>{" "}
//               {result.description}
//             </li>
//           ))}
//           <li className="googless">
//             <img src={googlepore} />
//           </li>
//         </ul>
//       )}
//     </>
//   );
// };
// export default CustomSelection;
// // const callbackName = "onloadcallback";

// // const URL = `https://maps.googleapis.com/maps/api/js?key=GOOGLE_API_KEY_PLACEHOLDER&libraries=places&callback=${callbackName}`;

// // // Defines the callback function if it doesn't exist already in the global context
// // window[callbackName] = function () {
// //   // Indicates that the script has loaded
// // };

// // export default asyncScript(URL, {
// //   callbackName: callbackName,
// //   globalName: "google",
// //   exposeFuncs: [callbackName],
// // })(CustomSelection);

import React, { useState, useEffect, useRef } from "react";
import { ImLocation } from "react-icons/im";
import googlepore from "../../../../images/download.png";

const CustomSelection = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  useEffect(() => {
    if (window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, []);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);

    if (inputValue.trim() === "") {
      setSearchResults([]);
      return;
    }

    if (!autocompleteService.current) {
      return;
    }
    autocompleteService.current.getPlacePredictions(
      { input: inputValue },
      (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          return;
        }
        setSearchResults(predictions);
      }
    );
  };

  const handleOptionSelect = (option) => {
    setSearchTerm(option.description);
    setSearchResults([]);
    if (!placesService.current) {
      return;
    }
    placesService.current.getDetails(
      { placeId: option.place_id },
      (place, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          return;
        }
        onSelect(place);
      }
    );
  };

  const [isInputFocused, setInputFocused] = useState(false);

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  return (
    <>
      <input
        type='text'
        value={searchTerm}
        style={{ width: "100%", margin: "0", height: "45px", fontSize: "18px" }}
        onChange={handleInputChange}
        placeholder='Enter your address'
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        className={isInputFocused ? "focused" : ""}
      />
      {window.google && searchResults.length > 0 && (
        <ul className='search-ul'>
          {searchResults.map((result) => (
            <li
              key={result.place_id}
              onClick={() => handleOptionSelect(result)}
              className='search-li'>
              <span>
                <ImLocation />
              </span>{" "}
              {result.description}
            </li>
          ))}
          <li className='googless'>
            <img src={googlepore} alt='Google' />
          </li>
        </ul>
      )}
    </>
  );
};

export default CustomSelection;
