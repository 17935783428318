/** @format */

import "./ContactContact.css";
import { React, useState, useEffect } from "react";
import "./info.css";
import { BsChevronDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ContactContact = (props) => {
  const selectForms = useSelector((state) => state);

  const navigate = useNavigate();

  const isSmallScreen = window.innerWidth < 450;
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    how_can_we_help: "",
    message: "",
    // market: "",
    // how_hear_about_us_platform: "",
    // how_hear_about_us_specific: "",
  });
  const [prevPhone, setPrevPhone] = useState("");
  const [validFormat, setValidFormat] = useState(false);
  const selectedMarket = selectForms?.formCategroies?.marketplace?.find(
    (item) => item.name === data.market
  );

  let sourceOptionsKeys = [];
  if (selectedMarket) {
    sourceOptionsKeys = Object.keys(selectedMarket?.options);
  }

  let specificOptionsArray = [];
  if (selectedMarket && data.how_hear_about_us_platform) {
    specificOptionsArray =
      selectedMarket?.options[data?.how_hear_about_us_platform];
  }
  const optionsToRender = Array.isArray(specificOptionsArray)
    ? specificOptionsArray
    : [];

  const formatPhoneNumber = (str) => {
    // Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    // Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return {
        formattedNumber: "+1 (" + match[1] + ") " + match[2] + "-" + match[3],
        isValid: true,
      };
    }

    return {
      formattedNumber: str,
      isValid: false,
    };
  };

  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleChange = (event) => {
    const value = event.target.value;

    if (event.target.name === "phone") {
      if (
        value.length > prevPhone.length &&
        (value.match(/\d/g) || []).length <= 10
      ) {
        const result = formatPhoneNumber(value);
        setData({
          ...data,
          [event.target.name]: result.formattedNumber,
        });
        setValidFormat(result.isValid);
      } else if (value.length < prevPhone.length) {
        // User has pressed backspace
        setData({
          ...data,
          [event.target.name]: value,
        });
        setValidFormat(false);
      }

      setPrevPhone(value);
    } else {
      setData({
        ...data,
        [event.target.name]: value,
      });
    }
  };

  const handleSubmit = () => {
    const {
      first_name,
      last_name,
      email,
      phone,
      how_can_we_help,
      message,
      // market,
      // how_hear_about_us_platform,
      // how_hear_about_us_specific,
    } = data;
    if (
      first_name === "" ||
      last_name === "" ||
      email === "" ||
      phone === "" ||
      how_can_we_help === "" ||
      message === ""
      // market === "" ||
      // how_hear_about_us_platform === ""
    ) {
      toast.error("All fields must be filled");
      return;
    }

    // Email validation regex. It checks for most standard email formats.
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      toast.error("Email format is incorrect");
      return;
    }

    // Phone number validation: Checks for exactly 10 digits.
    const phoneRegex = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneRegex.test(phone)) {
      toast.error("Phone number format is incorrect");
      return;
    }

    // if (how_hear_about_us_platform === "") {
    //   return toast.error("Please Select A How'd You Hear About Us?");
    // }
    // if (how_hear_about_us_platform === "Online") {
    //   if (how_hear_about_us_specific === "") {
    //     return toast.error("Please Select A How'd You Hear About Us?");
    //   }
    // }

    // let url = `${process.env.REACT_APP_API_KEY_URL}?type=feedback`;

    //my api call start
    var formdata = new FormData();
    formdata.append("first_name", first_name);
    formdata.append("last_name", last_name);
    formdata.append("email", email);
    formdata.append("phone", phone);
    formdata.append("how_can_we_help", how_can_we_help);
    formdata.append("message", message);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://rhbkctw.com/yunahomes/index.php?type=contactus",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Data inserted successfully.") {
          setData({
            first_name: "",
            last_name: "",
            email: "",
            how_can_we_help: "",
            message: "",
            // market: "",
            phone: "",
            // how_hear_about_us_platform: "",
            // how_hear_about_us_specific: "",
          });

          // navigate.push("/response");
          navigate("/response");
        }
      })

      .catch((error) => {
        // console.error("Error:", error);
      });

    //yhn

    //my api call end

    // let url = 'https://rhbkctw.com/yunahomes/index.php?type=contactus';

    // fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   },
    //   body: new URLSearchParams(data).toString(),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.message === "Feedback submitted successfully.") {
    //       setData({
    //         first_name: "",
    //         last_name: "",
    //         email: "",
    //         how_can_we_help: "",
    //         message: "",
    //         // market: "",
    //         phone: "",
    //         // how_hear_about_us_platform: "",
    //         // how_hear_about_us_specific: "",
    //       });

    //       // navigate.push("/response");
    //       navigate("/response");
    //     }
    //   })

    //   .catch((error) => {
    //     // console.error("Error:", error);
    //   });
  };
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const resetHowHear = () => {
    // data.how_hear_about_us_specific = "";
    // data.how_hear_about_us_platform = "";
  };
  return (
    <>
      <div className='ContactContact'>
        <div
          style={{
            width: windowWidth < 630 ? "100%" : "70%",
            display: "flex",
            justifyContent: "center",
          }}>
          <div className='contactPara'>
            <h3>Get in Touch </h3>
            <p>
              Yuna Homes has been a game-changer in the real estate industry,
              delivering outstanding service to over thousands of satisfied
              clients. As a full-service real estate solution, we tailor our
              strategies to meet your unique needs. Reach out to us today!
            </p>
            <p>Phone: (470) 660-3532</p>
            <p>
              Our team is available to assist you from 8:30 am – 8:30 pm, 7 days
              a week, ensuring you have the support you need when you need it.
            </p>
            <h3>About Yuna Homes</h3>
            <p>
              Yuna Homes was born out of a desire to transform the traditional
              real estate experience. As a leading figure in the industry, our
              founder combined their love for homes and neighborhoods with a
              relentless drive for customer service. This passion led to the
              creation of a company that prioritizes relationships over
              transactions, people over profits. Yuna Homes has consistently
              been recognized for its excellence in the real estate sector.
              Contact us today to navigate your home selling journey with the
              best in the business!
            </p>
          </div>
        </div>
        <div
          className='blackLine'
          style={{
            display: windowWidth < 1024 ? "flex" : "none",
          }}></div>

        <div className='contactParaImage'>
          <h3>Send Us A Message</h3>
          <div className='AboutsConnected'>
            <form className='AboutformMain' action=''>
              <div className='twoInputs'>
                <input
                  className='AboutFormm'
                  value={data.first_name}
                  onChange={handleChange}
                  name='first_name'
                  type='text'
                  placeholder='First Name'
                />
                <input
                  className='AboutFormm'
                  onChange={handleChange}
                  value={data.last_name}
                  name='last_name'
                  type='text'
                  placeholder='Last Name'
                />
              </div>
              <div className='twoInputs'>
                <input
                  className='AboutFormm'
                  onChange={handleChange}
                  value={data.email}
                  name='email'
                  type='text'
                  placeholder='Email'
                />
                <input
                  className='AboutFormm'
                  name='phone'
                  type='text'
                  value={data.phone}
                  placeholder='Phone'
                  onChange={handleChange}
                  onKeyUp={handleChange}
                />
              </div>

              <div className='AboutInputttSmall'>
                <div className='selectArrowsInfo'>
                  <BsChevronDown />
                </div>
                <select
                  className='ContactSelectSmall'
                  onChange={handleChange}
                  value={data.how_can_we_help}
                  name='how_can_we_help'
                  id='how_can_we_help'>
                  <option value=''>How Can We Help?</option>
                  <option value='Market'>Seller</option>
                  <option value='mercedes'>Buyer</option>
                  <option value='mercedes'>Speak To An Agent</option>
                  <option value='mercedes'>Other</option>
                </select>
              </div>

              <div className='message'>
                <textarea
                  className='ContacttextArea'
                  onChange={handleChange}
                  value={data.message}
                  name='message'
                  type='text'
                  placeholder='Message'
                />
              </div>

              {/* <div className="AboutInputtt">
                <div className="selectArrowsInfo">
                  <BsChevronDown />
                </div>
                <select
                  className="ContactSelect"
                  name="market"
                  value={data.market}
                  onChange={handleChange}
                  onClick={()=>{
                 resetHowHear();

                  }}
                >
                  <option value="">Market</option>
                  <option value="Atlanta">Atlanta</option>
                  <option value="Brimingham">Brimingham</option>
                  <option value="Charlotte">Charlotte</option>
                  <option value="Chattanooga">Chattanooga</option>
                  <option value="Dallas-Fort Worth">Dallas-Fort Worth</option>
                  <option value="Greensboro">Greensboro</option>
                  <option value="Greenville">Greenville</option>
                  <option value="Jacksonville">Jacksonville</option>
                  <option value="Nashville">Nashville</option>
                  <option value="Orlando">Orlando</option>
                  <option value="Raleigh">Raleigh</option>
                  <option value="Tempa">Tempa</option>
                </select>
              </div> */}

              {/* {data.market != "" && (
                <div className="AboutInputtt">
                  <div className="selectArrowsInfo">
                    <BsChevronDown />
                  </div>{" "}
                  <select
                    className="ContactSelect"
                    name="how_hear_about_us_platform"
                    value={data.how_hear_about_us_platform}
                    onChange={handleChange}
                  >
                    <option value="">How'd you hear about us?</option>
                   
                    <option value="Online">Online</option>
                    <option value="Billboard">Billboard</option>
                  </select>
                </div>
              )} */}
              {/* {data.market && data.how_hear_about_us_platform != "" && (
                <div className="AboutInputtt">
                  <div className="selectArrowsInfo">
                    <BsChevronDown />
                  </div>
                  <select
                    className="ContactSelect"
                    name="how_hear_about_us_specific"
                    value={data.how_hear_about_us_specific}
                    onChange={handleChange}
                  >
                     <option value="">How'd you hear about us?</option>
                      <option value="Instagram">Instagram</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Nextdoor">Nextdoor</option>
                  </select>
                </div>
              )} */}
            </form>

            {data.message === "Feedback submitted successfully." ? (
              <div className='contactButtonn'>
                <Link to='/response'>
                  <button
                    onClick={() => {
                      {
                        handleSubmit();
                      }
                    }}
                    className='formToButton'>
                    Contact Us
                  </button>
                </Link>
              </div>
            ) : (
              <div className='contactButtonn'>
                <Link to=''>
                  <button
                    onClick={() => {
                      {
                        handleSubmit();
                      }
                    }}
                    className='formToButton'>
                    Contact Us
                  </button>
                </Link>
              </div>
            )}

            <div className='AboutParas'>
              {" "}
              <p style={{ cursor: "pointer" }}>Terms of Use </p>{" "}
              <p style={{ cursor: "pointer" }}>Privacy Policy</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactContact;
