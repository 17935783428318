import React from "react";
import "./FloridasecondSec.css";

import Slide from 'react-reveal/Slide';

const FloridaSecondSec = () => {
  const isSmallScreen = window.innerWidth < 450;
  return (
    <>
    {isSmallScreen?
  (
    <div className="floridaSecondSec">
  
      <Slide left>
    <p className="floridaSecondPara">
 <span className="secondRed">We believe that buying a house should be a joyous and simple experience.</span>      </p>
      </Slide>
    
  </div>
  ):(
    <div className="secondSec">
  <Slide left>
    <p className="floridaSecondPara">
 <span className="secondRed">We believe that buying a house should be a joyous and simple experience.</span>      </p>
      </Slide>
  </div>

  )  
  }

    </>
  );
};

export default FloridaSecondSec;
