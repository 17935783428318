import { useEffect, useState } from "react";
import { React } from "react";
import "./ContactBlack.css";
import { Link, useLocation } from "react-router-dom";

const ContactBlack = (props) => {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
     
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="ContactBlack">
        <div className="ContactBlackCard">
          <h3>
            The Wall Street Journal ranked Yuna Homes Real Estate <span style={{fontWeight:"900"}}>#1</span> in closed
            transactions in the United States for the fifth year.
          </h3>
        </div>
      </div>
    </>
  );
};

export default ContactBlack;

