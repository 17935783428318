import React from 'react'
import room from '../images/room.webp';
import './parallexEffectDrip.css'
import FourthSec from './FourthSec';
import ThirdSec from './ThirdSec';
import FifthReadyDrip from './FifthReadyDrip';
import house from "../images/house.png"
import FloridaThirdSec from './FloridaThirdSec';
import FloridaFourthSec from './FloridaFourthSec';
import FloridaLast from './FloridaLast';

const ParallexEffectFlorida = () => {
  return (
   <>  
  {/* <ThirdSec /> */}
  <FloridaThirdSec title={"Our Simple 3-Step Process of Buying a House in Florida"} />
  <div class="">
    <FloridaFourthSec title={"Sell a House Fast in Florida"} 
    summary={"Selling your house fast in Florida is a significant decision, and at Yuna Homes, we're dedicated to making this process not only fast and straightforward but also incredibly advantageous for you. When you entrust us to assist you in selling your home, you'll reap a wide range of benefits that set us apart from the rest."} 
    onet={"Swift and Stress-Free Sales"} 
    twot="No Agent Commissions or Hidden Fees" 
    threet="Fair and Competitive Offers" 
    one={"Our specialty lies in expediting home sales without unnecessary hassles. Bid farewell to the protracted listing process and the uncertainties often accompanying it. We purchase houses in their current condition, sparing you from the headaches of repairs and constant showings."} 
    two="By choosing us, you sidestep the burdensome agent commissions and hidden charges typically associated with traditional real estate transactions. What you see is precisely what you get, ensuring you retain more of your hard-earned money from the sale." 
    three="Our offers are meticulously determined based on a thorough market assessment, guaranteeing you receive a competitive and equitable price for your property. We're wholeheartedly committed to ensuring your satisfaction with the deal, giving you peace of mind throughout the process."/>

    <FloridaLast title={"Your Trusted Cash Home Buyers in Florida"} 
    p1="When it comes to your trusted cash home buyers in Florida, we understand the challenges that can arise during the conventional selling process. At Sunshine House Buyers, we offer a solution that allows you to sidestep the stress and relish a seamless home sale experience." 
    p2="Say goodbye to the daunting task of searching for a real estate agent. Our dedicated team is here to guide you through every step of the process, all without the burden of costly commissions or fees. We're committed to purchasing your house for cash, just as it is, eliminating the need for you to worry about expensive repairs or time-consuming renovations." 
    p3="Say goodbye to the hassle of cleaning and staging your home for open houses and showings. Your property is ready to be purchased with us in its current condition. You can avoid investing your valuable time and resources in preparing your home for potential buyers." 
    p4="Our primary goal is to simplify the selling process, allowing you to continue with your busy life without the added stress of a home sale. By choosing to sell your house quickly, you can count on a straightforward and hassle-free experience. Selling a house in Florida has never been this easy!" 
    p5="At Yuna Homes, we're your reliable partners in selling your home for cash. We understand the unique needs of Florida homeowners, and our commitment is to provide you with a hassle-free and efficient way to sell your property. Embrace a stress-free home sale process and discover the ease of working with Florida's trusted cash home buyers." />
  </div>
   </>
  )
}

export default ParallexEffectFlorida;


     {/* <FourthSec
       heading = "Ready For Your Offer? "
      buttonText = "Get My Offer"
      link = "/form"
      backgroundd = {`url(${house})`}
      /> */}
    {/* <FifthReadyDrip /> */}