import React from "react";
import "./SecondSec.css";

import Slide from "react-reveal/Slide";

const SecondSec = ({ Heading }) => {
  const isSmallScreen = window.innerWidth < 450;
  return (
    <>
      {isSmallScreen ? (
        <div className="secondSec">
          <Slide left>{Heading}</Slide>
        </div>
      ) : (
        <div className="secondSec">
          <Slide left>{Heading}</Slide>
        </div>
      )}
    </>
  );
};

export default SecondSec;
