import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Info from "../components/Info";
import "./FormPageMain.css";
import FormPage from "../components/form/FormPage";
import { FormStepProvider } from "../components/form/Component/FormStepContext";

const FormPageMain = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    // Scroll to the top of the page whenever the component is mounted (navigated to)
    window.scrollTo(0, 0);

    // Prevent scroll restoration on page refresh
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the scroll listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <FormStepProvider>
      <div className="formPage ">
        <Header />
        <div className="banners"></div>
        <div className="overlayeds">
          <FormPage />
          <Info />
        </div>
      </div>
    </FormStepProvider>
  );
};

export default FormPageMain;
