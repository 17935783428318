import { React, useEffect } from "react";
import Header from "./Header";
import Info from "./Info";

import FeedbackResponse from "./FeedbackResponse";
import FormPageMain from "../screens/FormPageMain";

const FeedbackResponseMain = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="formPage ">
        <Header />
        <div className="banners"></div>
        <div className="overlayeds">
          <FeedbackResponse />
          <Info />
        </div>
      </div>
    </>
  );
};

export default FeedbackResponseMain;
