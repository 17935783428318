import { React, useState, useEffect } from "react";
import "./AboutKnow.css";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
const AboutKnow = () => {
  //   const isSmallScreen = window.innerWidth < 470;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);
  return (
    <>
      {windowWidth < 768 ? (
        <div className="AboutKnow">

          <p className="KnowSecondRed">Our Clients Want To Know</p>
   
       
          <div className="knowSecondParaMain">
          <Slide up>

            <div className="parNline">
              <p className="knowSecondPara">Can I Trust You?</p>
              <div className="lineKnow"></div>
            </div>
            </Slide>
            <Slide up>
            <div className="parNline">
              <p className="knowSecondPara">Do You Care About Me?</p>
              <div className="lineKnow"></div>
            </div>
            </Slide>
            <Slide up>
            <div className="parNline">
              <p className="knowSecondPara">How Good Are You?</p>
              <div className="lineKnow"></div>
            </div>
            </Slide>
            <Slide up>
            <div className="parNline">
              <p className="knowSecondPara">Can You Help Me?</p>
              <div className="lineKnow"></div>
           
            </div>
            </Slide>
          </div>
          {/* <Link to="/form">
       <button onClick={() => setTop(!top)}>The Yuna Homes Guaranteed Offer</button>
     </Link> */}
          <div className="knowWhiteLine"></div>
          
         
            <div className="knowPara">
               <Slide left>
              At Yuna Homes, we pride ourselves on providing unparalleled
              training, arming our team with essential tools, knowledge, and
              skills to excel in their roles. Our collaborative environment
              encourages continuous learning, growth, and empowers everyone to
              aspire beyond their perceived limits. Every department interlinks
              seamlessly to deliver the highest client experience possible.
              Rooted in our core values, we foster a culture that prioritizes
              the needs of our clients above all else.
                    </Slide>
            </div>
    
        </div>
      ) : (
        <div className="AboutKnowMain">
        <div className="AboutKnow">
  
          <p className="KnowSecondRed">Our Clients Want To Know</p>
       
          <Slide up>
          <div className="knowSecondParaMain">
            <div className="parNline">
              <p className="knowSecondPara">Can I Trust You?</p>
              <div className="lineKnow"></div>
            </div>

            <div className="parNline">
              <p className="knowSecondPara">Do You Care About Me?</p>
              <div className="lineKnow"></div>
            </div>

            <div className="parNline">
              <p className="knowSecondPara">How Good Are You?</p>
              <div className="lineKnow"></div>
            </div>
            <div className="parNline">
              <p className="knowSecondPara">Can You Help Me?</p>
              <div className="lineKnow"></div>
            </div>
          </div>
          </Slide>
          {/* <Link to="/form">
          <button onClick={() => setTop(!top)}>The Yuna Homes Guaranteed Offer</button>
        </Link> */}
          <div className="knowWhiteLine"></div>
          <Slide left >
            <div className="knowPara">
              At Yuna Homes, we pride ourselves on providing unparalleled
              training, arming our team with essential tools, knowledge, and
              skills to excel in their roles. Our collaborative environment
              encourages continuous learning, growth, and empowers everyone to
              aspire beyond their perceived limits. Every department interlinks
              seamlessly to deliver the highest client experience possible.
              Rooted in our core values, we foster a culture that prioritizes
              the needs of our clients above all else.
            </div>
          </Slide>
        </div>
        </div>
      )}
    </>
  );
};

export default AboutKnow;
