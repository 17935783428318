import { createSlice } from "@reduxjs/toolkit";
export const fcSlice = createSlice({
    name: "location",
    initialState: {
        marketplace: null,
        categories:null,
        modalOpen:false,
        
        modalOpenTwo: false,
    },
    reducers: {
        setMarketplace: (state, action) => {
            state.marketplace = action.payload.marketplaces;
        },
        setCategories:(state,action) => {
            state.categories = action.payload.categories;
        },
        setModalOpen:(state, action) => {
            state.modalOpen = action.payload;
        },
        setModalOpenTwo:(state, action) => {
            state.modalOpenTwo = action.payload;
        },
    },


})

export const {setMarketplace,setCategories,setModalOpen, setModalOpenTwo } = fcSlice.actions;
export default fcSlice.reducer;