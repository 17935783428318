/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Stepper3.css";
import { BsChevronDown } from "react-icons/bs";
import Loader from "../Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

function Stepper3({
  nextStep,
  backStep,
  setForm,
  form,
  recordId,
  setRecordId,
}) {
  const [loader, setLoader] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [coolDown, setCoolDown] = useState(false);
  const selectForms = useSelector((state) => state);
  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };
  const handleInputBlur = () => {
    setFocusedInput(null);
  };
  const loseFocusApi = async (body) => {
    if (!coolDown) {
      setCoolDown(true);
      let urlWithParams = `${process.env.REACT_APP_API_KEY_URL}?type=query`;
      if (recordId) {
        urlWithParams = `${process.env.REACT_APP_API_KEY_URL}?type=query&record_id=${recordId}`;
      }
      await fetch(urlWithParams, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(form),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.record_id) {
            setRecordId(data?.record_id);
          }
        })
        .catch((error) => {
          console.log("ERROR =========", error);
        });
      setTimeout(() => {
        setCoolDown(false);
      }, 6000);
    }
  };
  // const updatedData = [
  //   {
  //     name: "Atlanta",
  //     options: {
  //       TV: {
  //         type: "TV",
  //         TV: [
  //           "WSB - TV Channel 2",
  //           "WAGA - TV FOX 5",
  //           "FOX NEWS Channel - TV",
  //           "11 Alive NBC WXIA - TV",
  //           "CBS Channel 46 - TV WANF",
  //           "Golf Channel - TV",
  //           "CNBC - TV",
  //           "The Weather Channel - TV",
  //         ],
  //       },
  //       Online: {
  //         type: "Online",
  //         Online: ["Facebook", "Instagram", "Nextdoor"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         Radio: [
  //           "WSB - 95.5 FM / AM 750 - radio",
  //           "104.7 The Fish - radio",
  //           "101.5 New Country / Kicks - radio",
  //           "92.9 The Game WZGC - radio",
  //           "97.1 The River - radio",
  //           "106.1 WNGC Your Georgia Country - radio",
  //           "99X - radio",
  //         ],
  //       },
  //       Billboards: {
  //         type: "Billboards",
  //         Billboards: ["Billboard"],
  //       },
  //     },
  //   },
  //   {
  //     name: "Birmingham",
  //     options: {
  //       TV: {
  //         type: "TV",
  //         TV: ["FOX6 WBRC - TV"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         Radio: [
  //           "106.9 The Eagle WBPT - radio",
  //           "WAGG 610 AM and 100.1 FM - radio",
  //         ],
  //       },
  //     },
  //   },
  //   {
  //     name: "Charlotte",
  //     options: {
  //       TV: {
  //         type: "TV",
  //         TV: [
  //           "WSOC - TV ABC Channel 9",
  //           "NBC WCNC - TV",
  //           "FOX NEWS Channel - TV",
  //           "Spectrum News 1 - TV",
  //           "CNBC - TV",
  //           "HGTV",
  //           "The Weather Channel - TV",
  //           "MSNBC - TV",
  //         ],
  //       },
  //       Online: {
  //         type: "Online",
  //         Online: ["Facebook", "Instagram"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         Radio: [
  //           "Country 103.7 WSOC FM - radio",
  //           "96.9 The Kat WKKT - radio",
  //           "99.7 The Fox WRFX - radio",
  //           "95.7FM The Ride WXRC - radio",
  //           "Sports Radio WFNZ 92.7 FM / 610 AM - radio",
  //           "FOX Sports 94.7 FM - radio",
  //           "V101.9 WBAV FM - radio",
  //         ],
  //       },
  //       Billboards: {
  //         type: "Billboards",
  //         Billboards: ["Billboard"],
  //       },
  //     },
  //   },
  //   {
  //     name: "Chattanooga",
  //     options: {
  //       TV: {
  //         type: "TV",
  //         TV: ["FOX Chattanooga - TV"],
  //       },
  //       Online: {
  //         type: "Online",
  //         Online: ["Facebook", "Instagram"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         Radio: [
  //           "US 101 Big Country WUSY - radio",
  //           "Rock 105 WRXR - radio",
  //           "98.1 The Lake WLND - radio",
  //           "103.7 KISS FM WKXJ - radio",
  //           "Real 96.1 WUSY - radio",
  //         ],
  //       },
  //       Billboards: {
  //         type: "Billboards",
  //         Billboards: ["Billboard"],
  //       },
  //     },
  //   },
  //   {
  //     name: "Dallas-Fort Worth",
  //     options: {
  //       Radio: {
  //         type: "Radio",
  //         Radio: [
  //           "WBAP News Talk 820 AM / 99.5 FM - Radio",
  //           "New Country 96.3 KSCS - Radio",
  //           "99.5 The Wolf Texas Country KPLX - Radio",
  //           "KLIF 570 News AM - Radio",
  //         ],
  //       },
  //     },
  //   },
  //   {
  //     name: "Greensboro",
  //     options: {
  //       TV: {
  //         type: "TV",
  //         TV: ["Fox 8 WGHP - TV"],
  //       },
  //       Online: {
  //         type: "Online",
  //         Online: ["Facebook", "Instagram"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         Radio: ["Rock 92 WKRR - Radio"],
  //       },
  //       Billboards: {
  //         type: "Billboards",
  //         Billboards: ["Billboard"],
  //       },
  //     },
  //   },
  //   {
  //     name: "Greenville",
  //     options: {
  //       TV: {
  //         type: "TV",
  //         TV: ["WYFF News 4 NBC - TV", "FOX Caroline WHNS - TV"],
  //       },
  //       Online: {
  //         type: "Online",
  //         Online: ["Facebook", "Instagram"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         Radio: [
  //           "Whistle 100.5 New Country WSSL - radio",
  //           "92.5 WESC Country - radio",
  //           "102.5 The Lake WMYI - radio",
  //           "Fox Sports Upstate 104.9 FM WROO - radio",
  //         ],
  //       },
  //       Billboards: {
  //         type: "Billboards",
  //         Billboards: ["Billboard"],
  //       },
  //     },
  //   },
  //   {
  //     name: "Jacksonville",
  //     options: {
  //       TV: {
  //         type: "TV",
  //         TV: ["News4JAX - WJXT Channel 4 TV"],
  //       },
  //       Online: {
  //         type: "Online",
  //         Online: ["Facebook", "Instagram"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         Radio: [
  //           "104.5 WOKV Jacksonville's News Talk - radio",
  //           "96.9 The Eagle Jacksonville's Classic Hits WJGL - radio",
  //           "ESPN Jacksonville 690 AM - radio",
  //           "HOT 99.5 FM Duval's Only R&amp;B - radio",
  //         ],
  //       },
  //     },
  //   },
  //   {
  //     name: "Nashville",
  //     options: {
  //       TV: {
  //         type: "TV",
  //         TV: ["WKRN - TV News 2 ABC", "WSMV - TV NBC News Channel 4"],
  //       },
  //       Online: {
  //         type: "Online",
  //         Online: ["Facebook", "Instagram"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         Radio: [
  //           "WTN - Super Talk 99.7 - radio",
  //           "WGFX - 104.5 The Zone - radio",
  //           "107.5 The River WRVW - radio",
  //           "WKDF - 103.3 Today’s Country - radio",
  //           "The Big 98 WSIX - radio",
  //           "WSM - 95.5 Nash Icon / 650 AM Country - radio",
  //           "94 FM The Fish - radio",
  //           "105.9 The Rock WNRQ - radio",
  //           "101.1 The Beat WUBT - radio",
  //         ],
  //       },
  //       Billboards: {
  //         type: "Billboards",
  //         Billboards: ["Billboard"],
  //       },
  //     },
  //   },
  //   {
  //     name: "Orlando",
  //     options: {
  //       Online: {
  //         type: "Online",
  //         Online: ["Facebook", "Instagram"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         Radio: [
  //           "98.9 WMMO Classic Hits - Radio",
  //           "WDBO 107.3 FM 580 AM News Talk - Radio",
  //           "K92.3 Country WWKA - Radio",
  //         ],
  //       },
  //     },
  //   },
  //   {
  //     name: "Raleigh",
  //     options: {
  //       TV: {
  //         type: "TV",
  //         TV: [
  //           "WRAL - TV NBC Channel 5",
  //           "ABC11 WTVD News, Good Morning America - TV",
  //           "FOX NEWS Channel - TV",
  //           "Spectrum News 1 - TV",
  //           "CNBC - TV",
  //           "HGTV",
  //           "The Weather Channel - TV",
  //           "MSNBC - TV",
  //         ],
  //       },
  //       Online: {
  //         type: "Online",
  //         Online: ["Facebook", "Instagram", "Nextdoor"],
  //       },
  //       Radio: {
  //         type: "Radio",
  //         radio: [
  //           "WTKK - 106.1 News Talk - radio",
  //           "94.7 Today's Best Country WQDR-FM - radio",
  //           "B93.9 New Country - radio",
  //           "100.7 Classic Rock - radio",
  //           "G105 Top 40 - radio",
  //           "95.3 The Beat - radio",
  //           "KIX 102.9 FM Carolina's Greatest Hits - radio",
  //           "The Triangle's Rock 92.9 WQDR-AM - radio",
  //         ],
  //       },
  //       Billboards: {
  //         type: "Billboards",
  //         Billboards: ["Billboard"],
  //       },
  //     },
  //   },
  //   {
  //     "name": "Tampa",
  //     "options": {
  //       "Online": {
  //         "type": "Online",
  //         "Online": [
  //           "Facebook",
  //           "Instagram"
  //         ]
  //       },
  //       "Radio": {
  //         "type": "Radio",
  //         "Radio": [
  //           "102.5 The Bone WHPT - radio",
  //           "107.3 The EAGLE Classic Hits WXGL - radio",
  //           "105.5 The Dove WDUV - radio",
  //           "97X New Alternative WSUN - radio",
  //           "Magic 94.9 WWRM - radio"
  //         ]
  //       }
  //     }
  //   }

  // ];

  const [prevPhone, setPrevPhone] = useState("");
  const [validFormat, setValidFormat] = useState(false);

  const formatPhoneNumber = (str) => {
    // Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    // Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return {
        formattedNumber: "+1 (" + match[1] + ") " + match[2] + "-" + match[3],
        isValid: true,
      };
    }

    return {
      formattedNumber: str,
      isValid: false,
    };
  };

  const handleChange = (event) => {
    if (event.target.name === "phone") {
      // Only format the phone number when a digit is added, not when backspace is pressed
      if (
        event.target.value.length > prevPhone.length &&
        (event.target.value.match(/\d/g) || []).length <= 10
      ) {
        const result = formatPhoneNumber(event.target.value);
        setForm({
          ...form,
          [event.target.name]: result.formattedNumber,
        });
        setValidFormat(result.isValid);
      } else if (event.target.value.length < prevPhone.length) {
        // User has pressed backspace
        setForm({
          ...form,
          [event.target.name]: event.target.value,
        });
        setValidFormat(false);
      }
      setPrevPhone(event.target.value);
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
    }
  };

  const selectedMarket = selectForms.formCategroies.marketplace?.find(
    (item) => item.name === form.market_type
  );

  let sourceOptionsKeys = [];
  if (selectedMarket) {
    sourceOptionsKeys = Object.keys(selectedMarket?.options);
  }

  let specificOptionsArray = [];
  if (selectedMarket && form.how_hear_about_us_platform) {
    specificOptionsArray =
      selectedMarket?.options[form?.how_hear_about_us_platform];
  }
  const optionsToRender = Array.isArray(specificOptionsArray)
    ? specificOptionsArray
    : [];

  const handleNext = () => {
    const {
      first_name,
      last_name,
      email,
      phone,
      // market_type,
      how_hear_about_us_platform,
      how_hear_about_us_specific,
    } = form;

    if (first_name === "") {
      return toast.error("Please Fill First Name");
    }

    if (last_name === "") {
      return toast.error("Please Fill Last Name");
    }

    if (email === "") {
      return toast.error("Please Fill Email");
    }
    const isValidEmail = (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
    if (email !== "" && !isValidEmail(email)) {
      return toast.error("Please Enter Valid Email");
    }

    if (phone === "") {
      return toast.error("Please fill Phone Number");
    }
    // const isValidPhoneNumber = (phoneNumber) => {
    //         const phoneNumberPattern = /^\+\d \(\d{3}\) \d{3}-\d{4}$/;
    //         return phoneNumberPattern.test(phoneNumber);
    //       };
    // if (phone !== '' && !isValidPhoneNumber(phone)) {
    //   return toast.error("Please Enter valid Phone Number");
    // }

    // if (market_type === '') {
    //   return toast.error("Please Select A Market");
    // }

    if (how_hear_about_us_platform === "") {
      return toast.error("Please Select A How'd You Hear About Us?");
    }
    if (how_hear_about_us_platform === "Online") {
      if (how_hear_about_us_specific === "") {
        return toast.error("Please Select A How'd You Hear About Us?");
      }
    }

    // All fields validated
    setLoader(true);
    let intervalId = setInterval(() => {
      nextStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  };

  const handleBack = () => {
    setLoader(true);
    let intervalId = setInterval(() => {
      backStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  };
  //   const formatPhoneNumber = (value) => {
  //     const phoneNumber = value.replace(/\D/g, '');
  //     if(phoneNumber.length < 4) {
  //         return phoneNumber;
  //     }
  //     if(phoneNumber.length < 7) {
  //         return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3)}`;
  //     }
  //     return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
  // };
  return (
    <div className='step3'>
      <ToastContainer />
      <div className='input-field'>
        <label style={{ color: "black" }}>
          First Name <span className='importent'>*</span>
        </label>
        <input
          onFocus={() => handleInputFocus("first_name")}
          onBlur={handleInputBlur}
          className={focusedInput === "first_name" ? "focused" : ""}
          name='first_name'
          // className={isInputFocused ? 'focused' : ''}
          value={form.first_name}
          onChange={handleChange}
          placeholder=''
          maxLength={40}
          type='text'
        />
        <p className='total'>{form.first_name.length} of 40 max characters</p>
      </div>
      <div className='input-field'>
        <label style={{ color: "black" }}>
          Last Name <span className='importent'>*</span>
        </label>
        <input
          onFocus={() => handleInputFocus("last_name")}
          onBlur={handleInputBlur}
          className={focusedInput === "last_name" ? "focused" : ""}
          name='last_name'
          value={form.last_name}
          onChange={handleChange}
          placeholder=''
          maxLength={40}
          type='text'
        />
        <p className='total'>{form.last_name.length} of 40 max characters</p>
      </div>
      <div className='input-field'>
        <label style={{ color: "black" }}>
          Email <span className='importent'>*</span>
        </label>
        <input
          onFocus={() => handleInputFocus("email")}
          onBlur={() => {
            const emailRegex =
              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (emailRegex.test(form.email)) {
              loseFocusApi({ email: form.email });
            }
            handleInputBlur();
          }}
          className={focusedInput === "email" ? "focused" : ""}
          name='email'
          value={form.email}
          onChange={handleChange}
          placeholder=''
          type='email'
        />
      </div>
      <div className='input-field'>
        <label style={{ color: "black" }}>
          Phone <span className='importent'>*</span>
        </label>
        <input
          onFocus={() => handleInputFocus("phone")}
          onBlur={() => {
            if (validFormat) {
              loseFocusApi({ phone: form.phone });
            }
            handleInputBlur();
          }}
          className={focusedInput === "phone" ? "focused" : ""}
          name='phone'
          value={form.phone}
          onkeyup={formatPhoneNumber}
          onChange={(event) => {
            handleChange(event);
          }}
          placeholder='### ### ####'
          type='tel'
        />
      </div>

      {/* <div className="input-field">
        <label style={{ color: 'black' }}>Market <span className="importent">*</span></label>
        <div className="select-arrows">
          <BsChevronDown />
        </div>
        <select 
           onFocus={() => handleInputFocus('market_type')}
           onBlur={handleInputBlur}
           className={focusedInput === 'market_type' ? 'focuseder' : ''}
        name="market_type" value={form.market_type} onChange={handleChange}>
          <option value="">Market</option>
          {selectForms.formCategroies.marketplace.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div> */}

      <div className='input-field'>
        <label style={{ color: "black" }}>
          How'd you hear about us? <span className='importent'>*</span>
        </label>
        <div className='select-arrows'>
          <BsChevronDown />
        </div>
        <select
          onFocus={() => handleInputFocus("how_hear_about_us_platform")}
          onBlur={handleInputBlur}
          className={
            focusedInput === "how_hear_about_us_platform" ? "focuseder" : ""
          }
          name='how_hear_about_us_platform'
          value={form.how_hear_about_us_platform}
          onChange={handleChange}>
          <option value=''>How'd you hear about us?</option>
          <option value='Online'>Online</option>
          <option value='Billboard'>Billboard</option>
          {/* {sourceOptionsKeys.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))} */}
        </select>
      </div>

      {form.how_hear_about_us_platform == "Online" && (
        <div className='input-field'>
          <label style={{ color: "black" }}>
            How'd you hear about us? <span className='importent'>*</span>
          </label>
          <div className='select-arrows'>
            <BsChevronDown />
          </div>{" "}
          <select
            onFocus={() => handleInputFocus("how_hear_about_us_specific")}
            onBlur={handleInputBlur}
            className={
              focusedInput === "how_hear_about_us_specific" ? "focuseder" : ""
            }
            name='how_hear_about_us_specific'
            value={form.how_hear_about_us_specific}
            onChange={handleChange}>
            <option value=''>How'd you hear about us?</option>
            <option value='Instagram'>Instagram</option>
            <option value='Facebook'>Facebook</option>
            <option value='Nextdoor'>Nextdoor</option>
            {/* {optionsToRender.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))} */}
          </select>
        </div>
      )}
      <div>
        {loader && <Loader />}
        <div className='search'>
          <button className='back' onClick={handleBack}>
            Back
          </button>
          <button onClick={handleNext} disabled={loader}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

Stepper3.propTypes = {
  nextStep: PropTypes.func.isRequired,
  backStep: PropTypes.func.isRequired,
};

export default Stepper3;
