import { useEffect, useState } from "react";
import { React } from "react";
import "./SellBlwStrategy.css";
import { Link, useLocation } from "react-router-dom";

import Slide from 'react-reveal/Slide';
const SellBlwStrategy = (props) => {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
   
      <div className="SellBlwStrategy">
      <Slide left>
        <div className="blackCard">
        
          <h3>
            If you’re not <span className="boldWhite"> 100% </span> completely <span className="boldWhite"> satisfied </span> with our customer service or
            marketing efforts, you can cancel the listing and pay nothing,
            <span className="boldWhite"> hassle free. </span> 
          </h3>
          
        </div>
        
      </Slide>
      </div>
    </>
  );
};

export default SellBlwStrategy;
