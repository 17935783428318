import { React, useEffect, useState } from "react";
import "./bannerThreeSell.css";
import { Link } from "react-router-dom";

import Slide from "react-reveal/Slide";
import Header from "./Header";
import FormModal from "../components/FormModal";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen } from "../redux/slices/formSlice";

const BannerThreeSell = (first_name) => {
  const [top, setTop] = useState(false);
  const dispatch = useDispatch()
  const {modalOpen} = useSelector(state=>state.formCategroies)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
   
    return () => {

      window.removeEventListener("resize", handleResize);
    };
  }, []); 
  const openModal = () => {
    dispatch(setModalOpen(true))
  };

  const closeModal = () => {

    dispatch(setModalOpen(false))
  };
  return (
    <>
      <Header />
      <Slide left>
        {windowWidth < 850 ? (
          <div className="feedbackResponseBannnerThreeSell">
            <h1>It’s more than just a For Sale sign.</h1>
            <p>
              Selling your home with us gives you maximum exposure to the
              largest audience of potential buyers.
            </p>
            <Link to="/form">
              <button
                onClick={() => {
                  setTop(!top);

                  // openModal();
                }}
              >
                Sell Your Home
              </button>
            </Link>
            <FormModal isOpen={modalOpen} onClose={closeModal} />
          </div>
        ) : (
          <div className="feedbackResponseBannnerThreeSell">
            <h1>
              It’s more than just a <br /> For Sale sign.
            </h1>
            <p>
              Selling your home with us gives you maximum exposure to the
              largest audience of potential buyers.
            </p>
            <Link to="/form">
              <button
                onClick={() => {
                  setTop(!top);

                  // openModal();
                }}
              >
                Sell Your Home
              </button>
            </Link>
            <FormModal isOpen={modalOpen} onClose={closeModal} />
          </div>
        )}
      </Slide>
    </>
  );
};

export default BannerThreeSell;
