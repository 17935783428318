import React, { createContext, useState } from "react";

const FormStepContext = createContext();

export const FormStepProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <FormStepContext.Provider value={{ currentStep, setCurrentStep }}>
      {children}
    </FormStepContext.Provider>
  );
};

export default FormStepContext;
