/** @format */

import React, { useEffect } from "react";
import { useState } from "react";
import "./banner.css";
import Header from "./Header";
import { useSelector, useDispatch } from "react-redux";
import { setLocation, setStep } from "../redux/slices/locationSlice";
import { Link, useNavigate } from "react-router-dom";
import CustomSelection from "../components/form/Component/Form1/CustomSelection";
import { ToastContainer, toast } from "react-toastify";
const Banner = (props) => {
  const isSmallScreen = window.innerWidth < 450;
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({});
  const [loader, setLoader] = useState(false);
  const handleRouteChange = (url, datas) => {
    navigate(url, { state: { data: datas } });
  };
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      <div
        className="banner"
        style={{

        }}
      >
        <ToastContainer />
        <div className="bannerInner">
          <h1>
          {props.headingg.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
          </h1>
          {windowWidth < 1024 ? (
  // props.subHeadingg.includes("Yuna Homes:") ? (
  //   <h2>
  //     {props.subHeadingg.split('\n').map((line, index) => (
  //       <React.Fragment key={index}>
  //         {line}
  //         <br />
  //       </React.Fragment>
  //     )
  //     )}
  //   </h2>
  // ) :
  (
    <h2>{props.subHeadingg}</h2>
  )
          ) : (
            <h2>
            {props.subHeadingg.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}

            </h2>
          )}
        </div>

        <div className="inputt">
          <div className="input-field" style={{ width: "50%" }}>
            <CustomSelection
              onSelect={(selectedOption) => {
                setAddress(selectedOption.formatted_address);
                dispatch(setLocation(selectedOption));
                dispatch(setStep(2));
              }}
            />
          </div>
          <button
            onClick={() => {
              const matches = (address.match(/,/g) || []).length;
              if (address == "") {
                toast.error("Please Enter the Address");
              } else if (matches < 2) {
                toast.error("Address Must Be Valid");
                return;
              } else {
                handleRouteChange("/form");
              }
            }}
          >
            Get My Offer
          </button>
        </div>
      </div>
    </>
  );
};

export default Banner;
