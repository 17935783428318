import { createSlice } from "@reduxjs/toolkit";
export const locationSlice = createSlice({
    name: "location",
    initialState: {
        location: null,
        step:1
    },
    reducers: {
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setStep: (state, action) => {
          state.step = action.payload;
        }
    },


})

export const {setLocation,setStep } = locationSlice.actions;
export default locationSlice.reducer;