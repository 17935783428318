
import React from 'react'
import ContactBanner from '../components/ContactBanner'
import ContactBlack from "../components/ContactBlack";
import ContactHistory from '../components/ContactHistory';
import './About.css'
import AboutProud from '../components/AboutProud.js';
import AboutJoinTeam from '../components/AboutJoinTeam';
import Info from '../components/Info';
import ContactContact from '../components/ContactContact';

const Contact = () => {
  return (
    <>
    <div className='AboutScreen'>
    <ContactBanner />
    <ContactContact/>
    <ContactBlack/>
    <ContactHistory/>

    <AboutJoinTeam leftHead = " Real Estate Agent" 
    leftParaa="We are looking for top talent and full-time real estate agents to join our supportive and fun culture. You will receive unparalleled guidance and mentorship from the best in the business, competitive commission with no cap, and guaranteed booked appointments with qualified buyers and sellers."  rightHead = "Buyer Agent" rightParaa="We are looking for Buyer Agents to join our team. If you're ready to work hard, take your business to the next level, care for your customers and earn an income that matches your work ethic, we want to talk to you!" 
    JoinTeamButtonContent = "Join Our Team"
    link= "/response"
    />
      <Info/>
    </div>
    </>
  )
}

export default Contact