/** @format */

import "./App.css";
import FormPageMain from "./screens/FormPageMain";
// import GuaranteedOffer from "./screens/GuaranteedOffer";
import { setMarketplace, setCategories } from "../src/redux/slices/formSlice";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FeedbackResponeMain from "./components/FeedbackResponseMain";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import FeedbackResponseMain from "./components/FeedbackResponseMain";
//import screens
import GuaranteedOffer from "./screens/GuaranteedOffer";
import HomePage from "./screens/HomePage";
import Sell from "./screens/Sell";
import AboutUs from "./screens/AboutUs";
import Contact from "./screens/Contact";
import ParallexEffect from "./components/ParallexEffect";
import Drip from "./screens/Drip";
import HomePageFlorida from "./screens/Florida";
import { LiveChatWidget, WidgetConfig } from "@livechat/widget-react";

function App() {
  const dispatch = useDispatch();

  function handleNewEvent(event) {
    console.log("LiveChatWidget.onNewEvent", event);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const submitName = async () => {
      let formData = new FormData();
      formData.append("name", "weweame");
      const url = process.env.REACT_APP_API_KEY_URL;
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          dispatch(setMarketplace(json));
          dispatch(setCategories(json));
        })
        .catch((error) => {
          console.error("Error:", error);
          console.error("Error:", error);
        });
    };

    submitName();
  }, []);

  return (
    <>
      <LiveChatWidget
        license='13155753'
        visibility='maximized'
        onNewEvent={handleNewEvent}
      />
      <Helmet></Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/guaranteedoffer" element={<GuaranteedOffer />} />
          <Route path="/form" element={<FormPageMain />} />
          <Route path="/response" element={<FeedbackResponseMain />} />
          <Route path="/sell_your_home" element={<Sell />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/test" element={<ParallexEffect />} />
          <Route path="/sell-my-house-fast-georgia/" element={<Drip />} />
          <Route path="/sell-my-house-fast-florida/" element={<HomePageFlorida />} />
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
