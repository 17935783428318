
import React, { useState, useEffect, useRef } from 'react';
import './Stepper1.css';
import Loader from '../Loader/Loader';
import CustomSelection from './CustomSelection';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {setStep ,setLocation} from '../../../../redux/slices/locationSlice'
function Stepper1({ nextStep, setForm, form, setSelectedLocation }) {
  const [address, setAddress] = useState('');
  const [loader, setLoader] = useState(false);
const dispatch = useDispatch()
  const handleSearchClick = () => { 
    const matches = (form.address.match(/,/g) || []).length;
    if (form.address.trim() === '') {
      toast.error("Please Enter Address", {
      });
      return;
    }else if (matches < 2) {
        toast.error("Address Must Be Valid");
        return;
      }
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      nextStep();
    }, 2000);
  };

  return (
    <div className='step1'>
      <ToastContainer />
        <div className='input-field'>

          <label style={{ color: 'black' }}> Address</label>
          <CustomSelection
            onSelect={(selectedOption) => {
              setAddress(selectedOption.formatted_address);

              dispatch(setLocation(selectedOption))
              dispatch(setStep(1))
              
              setForm({
                ...form,
                address: selectedOption.formatted_address,
              });
            }}
          />
        </div>
      <div></div>
      {loader && <Loader />}
      <div className='search'>
        <button onClick={handleSearchClick} disabled={loader}>Search</button>
      </div>
    </div>
  );
}

export default Stepper1;
