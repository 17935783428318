/** @format */

import React, { useEffect, useState } from "react";
import "./Stepper7.css";
import jstz from "jstz";
import Loader from "../Loader/Loader";
import { BsChevronDown } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import DatePickerForm from "./DatePickerForm";
import { useDispatch } from "react-redux";
import { setStep } from "../../../../redux/slices/locationSlice";
import axios from "axios";
import { setLocation } from "../../../../redux/slices/locationSlice";
import { ImOpera } from "react-icons/im";
function Stepper7({
  nextStep,
  backStep,
  form,
  setForm,
  setTotalSteps,
  setConfirmation,
  confirmation,
  recordId,
  setRecordId,
}) {
  var timezone = jstz.determine();
  const selectForms = useSelector((state) => state);
  function toPascalCase(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
      "Content-Type": "application/json",
      Method: "POST",
    },
  };
  // const dummy = {
  //   "2023-07-25": [
  //     "08:00",
  //     "08:30",
  //     "09:00",
  //     "09:30",
  //     "10:00",
  //     "10:30",
  //     "11:00",
  //     "11:30",
  //     "12:00",
  //     "12:30",
  //     "13:00",
  //     "13:30",
  //     "14:00",
  //     "14:30",
  //     "15:00",
  //     "15:30",
  //     "16:00",
  //     "16:30",
  //     "17:00",
  //     "17:30",
  //     "18:00",
  //     "18:30",
  //     "19:00",
  //     "19:30",
  //     "20:00",
  //     "20:30",
  //   ],
  //   "2023-07-26": [
  //     "09:00",
  //     "09:30",
  //     "10:00",
  //     "10:30",
  //     "11:00",
  //     "11:30",
  //     "12:00",
  //     "12:30",
  //     "13:00",
  //     "13:30",
  //     "14:00",
  //     "14:30",
  //     "15:00",
  //     "15:30",
  //     "16:00",
  //     "16:30",
  //     "17:00",
  //     "17:30",
  //     "18:00",
  //     "18:30",
  //     "19:00",
  //     "19:30",
  //     "20:00",
  //     "20:30",
  //   ],
  //   "2023-07-27": [
  //     "09:00",
  //     "09:30",
  //     "10:00",
  //     "10:30",
  //     "11:00",
  //     "11:30",
  //     "12:00",
  //     "12:30",
  //     "13:00",
  //     "13:30",
  //     "14:00",
  //     "14:30",
  //     "15:00",
  //     "15:30",
  //     "16:00",
  //     "16:30",
  //     "17:00",
  //     "17:30",
  //     "18:00",
  //     "18:30",
  //     "19:00",
  //     "19:30",
  //     "20:00",
  //     "20:30",
  //   ],
  //   "2023-07-28": [
  //     "09:00",
  //     "09:30",
  //     "10:00",
  //     "10:30",
  //     "11:30",
  //     "12:00",
  //     "12:30",
  //     "13:00",
  //     "13:30",
  //     "14:00",
  //     "14:30",
  //     "15:00",
  //     "15:30",
  //     "16:00",
  //     "16:30",
  //     "17:00",
  //     "17:30",
  //     "18:00",
  //     "18:30",
  //     "19:00",
  //     "19:30",
  //     "20:00",
  //     "20:30",
  //   ],
  //   "2023-07-29": [
  //     "09:00",
  //     "09:30",
  //     "10:00",
  //     "10:30",
  //     "11:00",
  //     "11:30",
  //     "12:00",
  //     "12:30",
  //     "13:00",
  //     "13:30",
  //     "14:00",
  //     "14:30",
  //     "15:00",
  //     "15:30",
  //     "16:00",
  //     "16:30",
  //     "17:00",
  //     "17:30",
  //     "18:00",
  //     "18:30",
  //     "19:00",
  //     "19:30",
  //     "20:00",
  //     "20:30",
  //   ],
  //   "2023-07-30": [
  //     "09:00",
  //     "09:30",
  //     "10:00",
  //     "10:30",
  //     "11:00",
  //     "11:30",
  //     "12:00",
  //     "12:30",
  //     "13:00",
  //     "13:30",
  //     "14:00",
  //     "14:30",
  //     "15:00",
  //     "15:30",
  //     "16:00",
  //     "16:30",
  //     "17:00",
  //     "17:30",
  //     "18:00",
  //     "18:30",
  //     "19:00",
  //     "19:30",
  //     "20:00",
  //     "20:30",
  //   ],
  //   "2023-07-31": [
  //     "09:00",
  //     "09:30",
  //     "10:00",
  //     "10:30",
  //     "11:00",
  //     "11:30",
  //     "12:00",
  //     "12:30",
  //     "13:00",
  //     "13:30",
  //     "14:00",
  //     "14:30",
  //     "15:00",
  //     "15:30",
  //     "16:00",
  //     "16:30",
  //     "17:00",
  //     "17:30",
  //     "18:00",
  //     "18:30",
  //     "19:00",
  //     "19:30",
  //     "20:00",
  //     "20:30",
  //   ],
  // };
  const [focusedInput, setFocusedInput] = useState(null);
  const [timeslots, setTimeslots] = useState(null);
  const dispatch = useDispatch();
  const [booking, setBooking] = useState("");
  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };
  const [loader, setLoader] = useState(false);
  const addAddition = () => {
    setTotalSteps(8);
  };

  const [show, setShow] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    const {
      has_pool,
      is_listed,
      // waste_handling,
      interest_in_selling,
      property_owner,
    } = form;
    if (
      has_pool !== "" &&
      is_listed !== "" &&
      // waste_handling !== "" &&
      interest_in_selling !== "" &&
      property_owner !== ""
    ) {
      setShow(true);
    }
  }, [
    form.has_pool,
    form.is_listed,
    // form.waste_handling,
    form.interest_in_selling,
    form.property_owner,
  ]);

  useEffect(() => {
    if (currentDate !== null) {
      const date = new Date();
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0-indexed in JavaScript
      const day = ("0" + date.getDate()).slice(-2);
      const formattedDate = `${year}-${month}`;
      setCurrentDate((prevState) => {
        prevState = formattedDate;
        return prevState;
      });
    }
  }, [currentDate]);
  const handleBack = () => {
    setLoader(true);
    let intervalId = setInterval(() => {
      backStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  };
  const handleSubmit = (event) => {
    const {
      // has_pool,
      // is_listed,
      // // waste_handling,
      // interest_in_selling,
      // property_owner,
      // setup_meeting,
    } = form;
    // if (
    //   // has_pool &&
    //   // is_listed &&
    //   // // waste_handling &&
    //   // interest_in_selling &&
    //   // property_owner &&
    //   // setup_meeting
    // ) {
      setLoader(true);
      if (form.setup_meeting === "Yes") {
        let url = `${
          process.env.REACT_APP_API_KEY_URL
        }?type=timeslots&month=${currentDate}&timezone=${timezone.name()}`;
        fetch(url, {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify(currentDate), // body data type must match "Content-Type" header
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setTimeslots(data);
          })
          .catch((error) => {});
        setShowPicker(true);
        setTotalSteps(8);
        nextStep();
        addAddition();
        setShowPicker(true);
      }

      // }
      else if (
        form.setup_meeting === "" ||
        form.setup_meeting === null ||
        form.setup_meeting === undefined ||
        form.setup_meeting === "No"
      ) {
        setTimeslots(null);
        setShowPicker(false);
        setTotalSteps(7);

        event.preventDefault();
        let params = new URLSearchParams();
        params.append("type", "query");

        let urlWithParams = `${process.env.REACT_APP_API_KEY_URL}?type=query`;
        if (recordId) {
          urlWithParams = `${process.env.REACT_APP_API_KEY_URL}?type=query&record_id=${recordId}`;
        }
        fetch(urlWithParams, {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify(form), // body data type must match "Content-Type" header
        })
          .then((response) => response.json())
          .then((data) => {
            if (
              data?.message == "Data inserted successfully" ||
              data?.message == "Data updated successfully"
            ) {
              toast.success("Form submitted successfully");
              setLoader(false);
              setConfirmation(true);
            }
            if (data?.record_id) {
              setRecordId(data?.record_id);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    // } else {
    //   // if (form.has_pool === "") {
    //   //   toast.error("Please Select Pool");
    //   // } else if (form.is_listed === "") {
    //   //   toast.error("Please Select Currently Listed");
    //   // }
    //   // // else if (form.waste_handling === "") {
    //   // //   toast.error("Please Select Waste Handled");
    //   // // }
    //   // else if (form.interest_in_selling === "") {
    //   //   toast.error("Please Select Selling Interest");
    //   // } else if (form.property_owner === "") {
    //   //   toast.error("Please Select Property Owner");
    //   // } else if (form.setup_meeting === "") {
    //   //   toast.error("Please Select How Would You Like To Hear From Us?");
    //   // }
    // }
  };

  return (
    <>
      {confirmation == false ? (
        <>
          {" "}
          {timeslots !== null && showPicker ? (
            <DatePickerForm
              timeslotData={timeslots}
              setTimeslots={setTimeslots}
              setConfirmation={setConfirmation}
              setForm={setForm}
              backStep={backStep}
              form={form}
              setTotalSteps={setTotalSteps}
              nextStep={nextStep}
              recordId={recordId}
              setRecordId={setRecordId}
            />
          ) : (
            <>
              <div className='step3'>
                <ToastContainer />
                {/* <div className="input-field">
                  <label>
                    Does the property have a pool?
                    <span className="importent">*</span>
                  </label>
                  <div className="select-arrows">
                    <BsChevronDown />
                  </div>
                  <select
                    onFocus={() => handleInputFocus("has_pool")}
                    onBlur={handleInputBlur}
                    className={focusedInput === "has_pool" ? "focuseder" : ""}
                    name="has_pool"
                    value={form.has_pool}
                    onChange={handleChange}
                  >
                    <option value="" selected="selected">
                      Select an Option
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div className="input-field">
                  <label>
                    Are you currently listed?
                    <span className="importent">*</span>
                  </label>
                  <div className="select-arrows">
                    <BsChevronDown />
                  </div>{" "}
                  <select
                    onFocus={() => handleInputFocus("is_listed")}
                    onBlur={handleInputBlur}
                    className={focusedInput === "is_listed" ? "focuseder" : ""}
                    name="is_listed"
                    value={form.is_listed}
                    onChange={handleChange}
                  >
                    {" "}
                    <option value="" selected="selected">
                      Select an Option
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div> */}
                {/* <div className="input-field">
                  <label>
                    How is waste handled?<span className="importent">*</span>
                  </label>
                  <div className="select-arrows">
                    <BsChevronDown />
                  </div>{" "}
                  <select
                    onFocus={() => handleInputFocus("waste_handling")}
                    onBlur={handleInputBlur}
                    className={
                      focusedInput === "waste_handling" ? "focuseder" : ""
                    }
                    name="waste_handling"
                    value={form.waste_handling}
                    onChange={handleChange}
                  >
                    {" "}
                    <option value="" selected="selected">
                      Select an Option
                    </option>
                    <option value="Public Sewer">Public Sewer</option>
                    <option value="Septic">Septic</option>
                    <option value="Don't Know">Don't Know</option>
                    <option value="Private">Private</option>
                    <option value="Public">Public</option>
                  </select>
                </div> */}
                {selectForms.formCategroies.categories
                  .slice(6, 8)
                  .map((category, index) => {
                    return (
                      <div className='input-field' key={category.id}>
                        <label style={{textTransform:"capitalize"}}>
                          {category.category_name.replace(/_/g, ' ')}
                          
                        </label>
                        <div className='select-arrows'>
                          <BsChevronDown />
                        </div>
                        <select
                          name={category.category_name}
                          value={form[category.category_name]}
                          onChange={(event) => {
                            handleChange(event);
                          }}>
                          {category.options.map((option) => (
                            <option key={option.id} value={option.option_value}>
                              {option.option_label}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  })}
                {/* <div className="input-field">
                  <label>
                    When would you be interested in selling?
                    <span className="importent">*</span>
                  </label>
                  <div className="select-arrows">
                    <BsChevronDown />
                  </div>{" "}
                  <select
                    onFocus={() => handleInputFocus("interest_in_selling")}
                    onBlur={handleInputBlur}
                    className={
                      focusedInput === "interest_in_selling" ? "focuseder" : ""
                    }
                    name="interest_in_selling"
                    value={form.interest_in_selling}
                    onChange={handleChange}
                  >
                    {" "}
                    <option value="" selected="selected">
                      Select an Option
                    </option>
                    <option value="30 - 60 Days">30 - 60 Days</option>
                    <option value="61 - 120 Days">61 - 120 Days</option>
                    <option value="Within next 6 months">
                      Within next 6 months
                    </option>
                    <option value="This year">This year</option>
                    <option value="More than one year from now">
                      More than one year from now
                    </option>
                  </select>
                </div>
                <div className="input-field">
                  <label>
                    Who is the owner of the property
                    <span className="importent">*</span>
                  </label>
                  <div className="select-arrows">
                    <BsChevronDown />
                  </div>{" "}
                  <select
                    onFocus={() => handleInputFocus("property_owner")}
                    onBlur={handleInputBlur}
                    className={
                      focusedInput === "property_owner" ? "focuseder" : ""
                    }
                    name="property_owner"
                    value={form.property_owner}
                    onChange={handleChange}
                  >
                    {" "}
                    <option value="" selected="selected" class="gf_placeholder">
                      Select an Option
                    </option>
                    <option value="Myself">Myself</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Myself and my spouse">
                      Myself and my spouse
                    </option>
                    <option value="Relative">Relative</option>
                    <option value="My client (I'm the agent)">
                      My client (I'm the agent)
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div> */}
                {selectForms.formCategroies.categories
                  .slice(9, 11)
                  .map((category, index) => {
                    return (
                      <div className='input-field' key={category.id}>
                        <label>{toPascalCase(category.category_name)}</label>
                        <div className='select-arrows'>
                          <BsChevronDown />
                        </div>
                        <select
                          name={category.category_name}
                          value={form[category.category_name]}
                          onChange={(event) => {
                            handleChange(event);
                          }}>
                          {category.options.map((option) => (
                            <option key={option.id} value={option.option_value}>
                              {option.option_label}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  })}
                {show && (
                  <>
                    <div className='input-field'>
                      <label>
                        Would like to hear from us?
                        <span className='importent'>*</span>
                      </label>
                      <div className='select-arrows'>
                        <BsChevronDown />
                      </div>
                      <select
                        onFocus={() => handleInputFocus("setup_meeting")}
                        onBlur={handleInputBlur}
                        className={
                          focusedInput === "setup_meeting" ? "focuseder" : ""
                        }
                        name='setup_meeting'
                        value={form.setup_meeting}
                        onChange={handleChange}>
                        <option value='' selected='selected'>
                          Select an Option
                        </option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                      </select>
                    </div>
                  </>
                )}

                {timeslots !== null && showPicker ? (
                  <DatePickerForm
                    timeslotData={timeslots}
                    setConfirmation={setConfirmation}
                    setForm={setForm}
                    backStep={backStep}
                    form={form}
                    setTotalSteps={setTotalSteps}
                    nextStep={nextStep}
                    recordId={recordId}
                    setRecordId={setRecordId}
                  />
                ) : (
                  <>
                    {loader && <Loader />}
                    <div className='search'>
                      <button className='back' onClick={handleBack}>
                        Previous
                      </button>

                      <button onClick={handleSubmit} disabled={loader}>
                        Submit
                      </button>
                    </div>{" "}
                  </>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <div className='step3'>
          <h3
            style={{
              textAlign: "center",
              fontSize: "31px",
              fontWeight: "400",
              fontFamily: "proxima-nova",
            }}>
            Thank you for your interest in selling your home!{" "}
          </h3>
          <p
            style={{
              textAlign: "center",
              lineHeight: "34px",
              fontFamily: "proxima-nova",
            }}>
            {" "}
            Thank you for allowing us to learn more about your home! A team
            member will be in touch within the next 24-48 hours to discuss next
            steps and options as it relates to selling your home. We're
            committed to selling your home as fast as possible for the most
            amount of money.
          </p>
          <p
            style={{
              textAlign: "center",
              lineHeight: "34px",
              fontFamily: "proxima-nova",
            }}>
            For any questions in the meantime, check out our FAQ or feel free to
            email us at{" "}
            <a
              style={{
                textDecoration: "none",
                color: "#000000",
                fontWeight: "bold",
              }}
              href='mailto:info@markspain.com'>
              info@yunahomes.com
            </a>{" "}
            We look forward to working with you!
          </p>
        </div>
      )}
    </>
  );
}

export default Stepper7;
