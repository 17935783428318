import { React, useEffect, useState } from "react";
import "./ThirdSec.css";
// import CompaniesRev from './CompaniesRev'
import stars from "../images/stars.png";
import fbmain from "../images/fbmain.png";
import googlemain from "../images/googlemain.png";
import starsSahi from "../images/starsSahi.PNG";
import person from "../images/person.PNG";
import clock from "../images/clock.PNG";
import home from "../images/home.PNG";
import { BiHome } from "react-icons/bi";
import { HiOutlineUser } from "react-icons/hi";
import { AiOutlineClockCircle } from "react-icons/ai";
import axios from "axios";

const ThirdSec = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = window.innerWidth < 450;

  const [reviews, setReviews] = useState([]);

  let reviewss = [
    {
      companyName: "Oudonex",
      companyRev:
        "Yuna Homes's real estate team was awesome! The guaranteed offer program sold my home within a week. The offer came in more than we thought we could get. I'm so glad I chose to work with Yuna Homes's team.",
    },
    {
      companyName: "Jonathan Andrews",
      companyRev:
        "  The guaranteed offer was the primary draw for me. I did not expect much in the way of customer service, as I assumed they probably get so many calls that they don't focus on client experience. I could not have been more wrong. My sales agent with Yuna Homes Real Estate was beyond attentive. She was knowledgeable, patient and genuinely considerate of me as her client. She and her team worked tirelessly to make sure my experience was a successful one. For that I am grateful. I will most certainly use her services again and recommend her and the Yuna Homes team very highly.    ",
    },
    {
      companyName: "Meredith Hill",
      companyRev:
        "Yuna Homes made it easy to sell our house with their guaranteed offer! Working with Yuna Homes has been a wonderful experience. This is our 2nd transaction with them and we would recommend them to all our friends and family! Home buying and selling can be stressful but NOT with Yuna Homes by our side.",
    },
  ];

  let reviewss2 = [
    {
      companyName: "Sophia Haynes",
      companyRev:
        "Our agent did a fantastic job. I had a guaranteed offer within 2 days and was able to close in less than 30. He took care of all of the details and the process was very smooth. I highly recommend it if you have a property to sell.",
    },
    {
      companyName: "Melissa Kemp",
      companyRev:
        'My husband and I decided to start married life with a big change - a move from Atlanta, GA to Rapid City, SD. We did not want the hassle of showing a home for a traditional sale but after reading some horror stories about investment buyers, we were not sure how to navigate that process either. We had heard of Yuna Homes Real Estate and the "Guaranteed Offer" and decided to give them a call. The next day, our agent was available for a home visit. We immediately felt comfortable and trusting of our agent. He took a genuine interest in us as fellow human beings, listened to what our goals were, and gave us the options available. He answered all of our questions and helped us navigate the process. We really enjoyed working with him because he is very friendly, professional and did not try to push or sell us into anything. The entire process from the initial home visit to leaving the closing table was very smooth, thanks to our agent\'s hard work and extensive knowledge. We were very lucky in that we had a great realtor who became a great friend!',
    },
  ];

  let reviewss3 = [
    {
      companyName: "Joel Mahaney",
      companyRev:
        "We contacted Yuna Homes, and in no time our Agent came to our home, gave us a rundown on our options, and within a matter of days gave us a Guaranteed Offer well above what we were expecting. We were so impressed with his knowledge of the process, and his ability to put our apprehension at ease. After that, it was a smooth and speedy road to the closing table. Our agent was not only a huge help, but now our friend. We found a new home, and he made it all happen. He was there when we had questions, and gave us the attention we didn't even know we needed.",
    },
    {
      companyName: "Mark Lidke",
      companyRev:
        'What could be easier than choosing to sell your home with Yuna Homes? With The Yuna Homes "Guaranteed Offer" to the "Market Listing Program", they have a program that will fit your needs and timeline. It\'s as easy as that. Yuna Homes knows how to market your home for maximum return and their Professional Agents are the BEST!',
    },
    {
      companyName: "Lucas Freeman",
      companyRev:
        'Terrific experience from start to finish. An agent met me at my home to discuss viable options such as the "Guaranteed Offer" and a traditional listing. Explained everything to me thoroughly so that Ii could make an informed decision and get the very best offer for my house. When it came time to search for a home the agent was always eager to show me properties, always gave me valuable insight into the process and made it as stress-free as possible. Very satisfied with my experience with Yuna Homes and would happily recommend them and deal with them again.',
    },
  ];

  let reviewssOneTab = [
    {
      companyName: "Melissa Kemp",
      companyRev:
        'My husband and I decided to start married life with a big change - a move from Atlanta, GA to Rapid City, SD. We did not want the hassle of showing a home for a traditional sale but after reading some horror stories about investment buyers, we were not sure how to navigate that process either. We had heard of Yuna Homes Real Estate and the "Guaranteed Offer" and decided to give them a call. The next day, our agent was available for a home visit. We immediately felt comfortable and trusting of our agent. He took a genuine interest in us as fellow human beings, listened to what our goals were, and gave us the options available. He answered all of our questions and helped us navigate the process. We really enjoyed working with him because he is very friendly, professional and did not try to push or sell us into anything. The entire process from the initial home visit to leaving the closing table was very smooth, thanks to our agent\'s hard work and extensive knowledge. We were very lucky in that we had a great realtor who became a great friend!',
    },
    {
      companyName: "Joel Mahaney",
      companyRev:
        "We contacted Yuna Homes, and in no time our Agent came to our home, gave us a rundown on our options, and within a matter of days gave us a Guaranteed Offer well above what we were expecting. We were so impressed with his knowledge of the process, and his ability to put our apprehension at ease. After that, it was a smooth and speedy road to the closing table. Our agent was not only a huge help, but now our friend. We found a new home, and he made it all happen. He was there when we had questions, and gave us the attention we didn't even know we needed.",
    },
    {
      companyName: "Mark Lidke",
      companyRev:
        'What could be easier than choosing to sell your home with Yuna Homes? With The Yuna Homes "Guaranteed Offer" to the "Market Listing Program", they have a program that will fit your needs and timeline. It\'s as easy as that. Yuna Homes knows how to market your home for maximum return and their Professional Agents are the BEST!',
    },
    {
      companyName: "Lucas Freeman",
      companyRev:
        'Terrific experience from start to finish. An agent met me at my home to discuss viable options such as the "Guaranteed Offer" and a traditional listing. Explained everything to me thoroughly so that Ii could make an informed decision and get the very best offer for my house. When it came time to search for a home the agent was always eager to show me properties, always gave me valuable insight into the process and made it as stress-free as possible. Very satisfied with my experience with Yuna Homes and would happily recommend them and deal with them again.',
    },
  ];

  let reviewssTwoTab = [
    {
      companyName: "Oudonex",
      companyRev:
        "Yuna Homes's real estate team was awesome! The guaranteed offer program sold my home within a week. The offer came in more than we thought we could get. I'm so glad I chose to work with Yuna Homes's team.",
    },
    {
      companyName: "Jonathan Andrews",
      companyRev:
        "  The guaranteed offer was the primary draw for me. I did not expect much in the way of customer service, as I assumed they probably get so many calls that they don't focus on client experience. I could not have been more wrong. My sales agent with Yuna Homes Real Estate was beyond attentive. She was knowledgeable, patient and genuinely considerate of me as her client. She and her team worked tirelessly to make sure my experience was a successful one. For that I am grateful. I will most certainly use her services again and recommend her and the Yuna Homes team very highly.    ",
    },
    {
      companyName: "Meredith Hill",
      companyRev:
        "Yuna Homes made it easy to sell our house with their guaranteed offer! Working with Yuna Homes has been a wonderful experience. This is our 2nd transaction with them and we would recommend them to all our friends and family! Home buying and selling can be stressful but NOT with Yuna Homes by our side.",
    },
    {
      companyName: "Sophia Haynes",
      companyRev:
        "Our agent did a fantastic job. I had a guaranteed offer within 2 days and was able to close in less than 30. He took care of all of the details and the process was very smooth. I highly recommend it if you have a property to sell.",
    },
  ];


  return (
    <>
      <div className="thirdSec">
        <p
          className="ThirdParaaaa"
          style={{
            color: "black",
          }}
        >
          Why Our Clients <span className="bold">Love</span> Us?
        </p>
        <div className="companyReviews">
          <img src={stars} alt="" />
          <img src={fbmain} alt="" />
          <img src={googlemain} alt="" />
        </div>

        {windowWidth > 740 && windowWidth < 1024 ? (
          <div className="revCardsContTwo">
            <div className="revCardsTwo">
              {reviewssTwoTab.map((item, index) => (
                <div className="revCardTwo" key={index}>
                  <h4>{item.companyName}</h4>
                  <img src={starsSahi} alt="" />
                  <p>{item.companyRev}</p>
                </div>
              ))}
            </div>
            <div className="revCardsTwo">
              {reviewssOneTab.map((item, index) => (
                <div className="revCardTwo" key={index}>
                  <h4>{item.companyName}</h4>
                  <img src={starsSahi} alt="" />
                  <p>{item.companyRev}</p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="revCardsCont">
            <div className="revCards">
              {reviewss.map((item, index) => (
                <div className="revCard" key={index}>
                  <h4>{item.companyName}</h4>
                  <img src={starsSahi} alt="" />
                  <p>{item.companyRev}</p>
                </div>
              ))}
            </div>
            <div className="revCards">
              {reviewss2.map((item, index) => (
                <div className="revCard" key={index}>
                  <h4>{item.companyName}</h4>
                  <img src={starsSahi} alt="" />
                  <p>{item.companyRev}</p>
                </div>
              ))}
            </div>
            <div className="revCards">
              {reviewss3.map((item, index) => (
                <div className="revCard" key={index}>
                  <h4>{item.companyName}</h4>
                  <img src={starsSahi} alt="" />
                  <p>{item.companyRev}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        <h4 class="text-center">
          Embrace the power to steer your home selling journey with our flexible
          options. We’re here to serve your interests.
        </h4>

        <div className="iconsMain">
          <div className="Icons">
            <img
              src={home}
              alt=""
              style={{
                width: "16%",
              }}
            />
            <h4>Get your offer.</h4>
            <p>Unleash a premium cash offer on your home.</p>
          </div>
          <div className="Icons">
            <img
              src={person}
              alt=""
              style={{
                width: "16%",
              }}
            />
            <h4>Bypass the market.</h4>
            <p>Don’t worry about repairs, closing costs, and commissions.</p>
          </div>
          <div className="Icons">
            <img
              src={clock}
              alt=""
              style={{
                width: "16%",
              }}
            />
            <h4>Streamline the sale.</h4>
            <p>Speedy & flexible close. Move on to your future.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdSec;
