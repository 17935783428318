import { React, useEffect, useState } from "react";
import "./feedbackResponse.css";
import { Link } from "react-router-dom";

const FeedbackResponse = (first_name) => {
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);
  return (
    <>
      <div className="feedbackResponse">
        <h1>Thank you!</h1>
        <p>One of our team members will reach out to you shortly!</p>
        <Link to="/">
          <button onClick={() => setTop(!top)}>Back to Sell Your Home</button>
        </Link>
      </div>
    </>
  );
};

export default FeedbackResponse;
