import { React, useState, useEffect } from "react";
import "./header.css";

import logoYuna from "../images/logoYuna.png";
import logoYunaTwo from "../images/logoYunaTwo.png";
import YHRELarge from "../images/YHRELarge.png";
import Menu from "../images/menu.png";
import crossss from "../images/crossss.png";
import yunaLogoTwoPhone from "../images/yunaLogoTwoPhone.png";

import { Link } from "react-router-dom";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const [fontColor, setFontColor] = useState("white");
  const [hideLogo, setHideLogo] = useState(false);
  const [hideLogoTwo, setHideLogoTwo] = useState(true);
  const [HideSecondLogo, setHideSecondLogo] = useState(false);

  const [scrollY, setScrollY] = useState(0);
  const [prevScrollY, setPrevScrollY] = useState(0);

  const [menu, setMenu] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); 

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 10) {
        setFontColor("#FFD400");
      } else {
        setFontColor("white");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollY(currentScrollY);
      if (currentScrollY > prevScrollY) {
 
        setHideLogo(true);
        setHideLogoTwo(true);
      } else if (currentScrollY < prevScrollY) {
     
        if (currentScrollY === 0) {
      
          setHideLogo(false);
          setHideLogoTwo(false);
        } else if (hideLogoTwo) {
      
          setHideLogoTwo(false);
        }
      }

      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hideLogo, hideLogoTwo, prevScrollY]);

  return (
    <>
      {menu && (
        <div className="whiteOverlay" onClick={() => setMenu(false)}></div>
      )}
      <div className="header">
        {windowWidth < 1024 ? (
          <div
            className="linkOne"
            style={{
              display: hideLogo && hideLogoTwo ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#000000",
              cursor: "pointer",
              // width:"26.4%",
            }}
          >
            <Link to="/">
              <img
                className="headerLogo"
                src={!hideLogo ? logoYuna : !hideLogoTwo ? YHRELarge : null}
                alt=""
                style={{
                  transition:
                    window.innerWidth < 1024 ? "transform 0.3s ease" : "none",
                  transform:
                    hideLogo && hideLogoTwo ? "translateY(-100%)" : "none",
                }}
              />
            </Link>
          </div>
        ) : (
          <div
            className="linkOne"
            style={{
              display: hideLogo && hideLogoTwo ? "none" : "flex",
              background: hideLogoTwo ? "000000" : "none",
              justifyContent: "center",
              alignItems: !hideLogoTwo ? "baseline" : "center",
              // background: "#000000",
              // width:"15%",
              width: "196px",
              height: "140px",
              cursor: "pointer",
            }}
          >
            <Link to="/" style={{ width: "100%" }}>
              <img
                className="headerLogo"
                src={
                  !hideLogo ? logoYuna : !hideLogoTwo ? yunaLogoTwoPhone : null
                }
                alt=""
                style={{
                  transition:
                    !windowWidth < 1024 ? "transform 0.3s ease" : "none",
                  transform:
                    hideLogo && hideLogoTwo ? "translateY(-100%)" : "none",
                }}
              />
            </Link>
          </div>
        )}
        {/* <img
          className="headerLogo"
          src={!hideLogo ? logoYuna : !hideLogoTwo ? logoYunaTwo : null}
          alt=""
          style={{
            transition: "transform 0.3s ease",
            transform: hideLogo && hideLogoTwo ? "translateY(-100%)" : "none",
          }}
        /> */}

        <div className="headerRight">
          <span
            className="number"
            style={{ color: fontColor, transition: "color 0.5s ease" }}
          >
            (470) 660-3532
          </span>
          {/* <div className="menu-container"> */}
          {/* <button
              style={{
                transform:
                  menu && window.innerWidth > 450
                    ? "translateX(-690%)"
                    : menu && window.innerWidth < 450
                    ? "translateX(-355%)"
                    : null,
                transition: "transform 0.4s ease",
                // translateX:menu? "100px": null,
              }}
              className="headerBtn"
            >
              <img
                className="menuu"
                src={menu ? crossss : Menu}
                alt=""
                onClick={() => {
                  setMenu(!menu);
                }}
                style={{
                  width: "100%",
                }}
              />
            </button> */}
          {!menu && (
            <button
              style={{
                transform:
                  menu && windowWidth > 450
                    ? "translateX(-608%)"
                    : menu && windowWidth < 450
                    ? "translateX(-370%)"
                    : null,
                transition: "transform 0.4s ease",
                // translateX:menu? "100px": null,
              }}
              className="headerBtn"
            >
              <img
                className="menuu"
                src={menu ? crossss : Menu}
                alt=""
                onClick={() => {
                  setMenu(!menu);
                }}
                style={{
                  width: "100%",
                }}
              />
            </button>
          )}

          <div
            className={`menu ${menu ? "open" : ""}`}
       
          >
            <button
              style={{
                position: "absolute",
                top: "0",
                left: "-92px",
              }}
              className="headerBtn"
            >
              <img
                className="menuu"
                src={menu ? crossss : Menu}
                alt=""
                onClick={() => {
                  setMenu(!menu);
                }}
                style={{
                  width: "100%",
                }}
              />
            </button>

         
            <Link
              className={
                window.location.href.split("/").pop() == "guaranteedoffer"
                  ? "guaranteedLinkOpened"
                  : "guaranteedLink"
              }
              to="/guaranteedoffer"
            >
              <h3 className="toGuaranteed">Guaranteed Offer</h3>
            </Link>
            <Link
              className={
                window.location.href.split("/").pop() == "sell_your_home"
                  ? "guaranteedLinkOpened"
                  : "guaranteedLink"
              }
              to="/sell_your_home"
            >
              <h3 className="toGuaranteed">Sell Your Home</h3> 
            </Link>
            <Link
              className={
                window.location.href.split("/").pop() == "about-us"
                  ? "guaranteedLinkOpened"
                  : "guaranteedLink"
              }
              to="/about-us"
            >
              <h3 className="toGuaranteed">About Us</h3>
            </Link>
            <Link
              className={
                window.location.href.split("/").pop() == "contact"
                  ? "guaranteedLinkOpened"
                  : "guaranteedLink"
              }
              to="/contact"
            >
              <h3 className="toGuaranteedCont">Contact</h3>
            </Link>
            <a
              className="toGuaranteedanchor"
              style={{ textDecoration: "none" }}
              href="tel:(470) 660-3532"
            >
              <h3 className="toGuaranteed">(470) 660-3532</h3>
            </a>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Header;
