import { useEffect, useState } from "react";
import { React } from "react";
import "./AboutBlwStrategy.css";
import { Link, useLocation } from "react-router-dom";

import Slide from "react-reveal/Slide";
const AboutBlwStrategy = (props) => {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [index, setIndex] = useState(2);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const data = [
    {
      id: "1",
      topic: "Our Vision:",
      message:
        "is for Yuna Homes to be synonymous with trust, efficiency, and personalized service in the realm of residential real estate, setting the gold standard for customer experience in the industry.",
    },
    {
      id: "2",
      topic: "Our Values:",
      message:
        "Uphold a mindset of superiority, prioritize client needs and satisfaction, pursue constant innovation and enhancement, guide with empathy and dedication, foster a vibrant and supportive work culture.",
    },
    {
      id: "3",
      topic: "Our Mission:",
      message:
        "is to relentlessly pursue the trust of our clients, delivering an unmatched customer experience powered by our culture of superior service, continuous learning, and focused specialization.",
    },
  ];

  return (
    <>
      <div>
        <div className="AboutBlwStrategy">
          <Slide left>
            <div className="AboutBlackCard">
              <h3>
                <span className="boldWhite"> {data[index].topic} </span>{" "}
                {data[index].message}
              </h3>
            </div>
          </Slide>
        </div>
      </div>
    </>
  );
};

export default AboutBlwStrategy;
