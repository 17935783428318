import { React, useEffect, useState } from "react";
import "./ContactBanner.css";
import { Link } from "react-router-dom";
import Header from "./Header";
const ContactBanner = (first_name) => {
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);

    return () => {
  
      window.removeEventListener('resize', handleResize);
    };
  }, []);  


  return (
    <>
      <Header />
      {windowWidth < 850?   <div className="ContactBanner">
      <h1>Contact Yuna Homes Real Estate</h1>
      
      </div>:   <div className="ContactBanner">
      <h1>Contact Yuna Homes Real Estate</h1>
   
      </div>}
    
    </>
  );
};

export default ContactBanner;
