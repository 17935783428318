import React from "react";
import './ContactHistory.css'
const ContactHistory = () => {
  return (
    <>
      <div className="ContactHistory">
        <p className="AboutHistoryHeading">Our History</p>
        <p className="AboutHistoryPara">
          In the heart of a vibrant neighborhood, a single seed of ambition was
          sown by our founder, a humble local with a dream bigger than the
          city's skyline. Yuna Homes was born from a vision and a promise - to
          revolutionize the real estate industry while staying true to its
          roots.
        </p>
        <p className="AboutHistoryPara">Our founder, a relentless self-starter, believed in providing more than just a service. He envisioned a company that would build relationships and create opportunities for those who didn't have them. With this drive and passion for excellence, Yuna Homes sprouted from the ground, built on a foundation of integrity and dedication.</p>
        <p className="AboutHistoryPara">The journey wasn't easy, but every hurdle only reinforced our belief that our work was about more than just transactions. It was about people. About community. About turning houses into homes.</p>
        <p className="AboutHistoryPara">From humble beginnings, we have grown into a trusted name in real estate, transcending the ordinary, and redefining what it means to buy and sell property. Each year, we have consistently elevated our game, reaching new heights and expanding our reach, all while staying grounded in our commitment to put people before profit.</p>
        <p className="AboutHistoryPara">In just a few short years, we've helped thousands of families find their forever homes, and this is only the beginning. Our story is one of unstoppable growth and unwavering commitment to our clients and communities. We're not just working to be the #1 Real Estate company - we're building a legacy of excellence, one home at a time.</p>
        <p className="AboutHistoryPara">As we look to the future, we are driven by the promise that our best is yet to come. Our story is still being written, and with each new chapter, we strive to transform the real estate landscape and touch lives in meaningful ways.</p>
        <p className="AboutHistoryPara">Join us in our journey and become part of something greater than average - become part of the Yuna Homes family.</p>
      </div>
    </>
  );
};

export default ContactHistory;
