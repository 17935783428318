import React from "react";
import person from "../images/person.PNG";
import clock from "../images/clock.PNG";
import home from "../images/home.PNG";
import "./thirdSecGuaranteed.css";
import TestimonialsCarousel from "./TestimonialsCarousel";
import Slide from "react-reveal/Slide";
const ThirdSecGuaranteed = () => {
  return (
    <>
      <div className="thirdSecGua">
        <div style={{ width: "100%" }}>
          <TestimonialsCarousel />
        </div>
        <Slide left >
          <h4 class="text-center">
            We provide you options and control over the home selling process.
          </h4>
        </Slide>
        <Slide up >
          <div className="iconsMain">
            <div className="Icons">
              <img
                src={home}
                alt=""
                style={{
                  width: "16%",
                }}
              />
              <h4>Get your offer.</h4>
              <p>Unleash a premium cash offer on your home</p>
            </div>
            <div className="Icons">
              <img
                src={person}
                alt=""
                style={{
                  width: "16%",
                }}
              />
              <h4>Bypass the market.</h4>
              <p>Don’t worry about repairs, closing costs, and commissions.</p>
            </div>
            <div className="Icons">
              <img
                src={clock}
                alt=""
                style={{
                  width: "16%",
                }}
              />
              <h4>Streamline the sale.</h4>
              <p>
                Speedy & flexible close. <br />
                Move on to your future.
              </p>
            </div>
          </div>
        </Slide>
      </div>
    </>
  );
};

export default ThirdSecGuaranteed;
