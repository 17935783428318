import { React, useState, useEffect } from "react";
import "./info.css";
import logoYuna from "../images/logoYuna.png";
import { BsChevronDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Info = () => {
  const selectForms = useSelector((state) => state);

  const navigate = useNavigate();

  const isSmallScreen = window.innerWidth < 450;
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    interest: "",
    market: "",
    how_hear_about_us_platform: "",
    how_hear_about_us_specific: "",
  });
  const [prevPhone, setPrevPhone] = useState("");
  const [validFormat, setValidFormat] = useState(false);
  const selectedMarket = selectForms?.formCategroies?.marketplace?.find(
    (item) => item.name === data.market
  );

  let sourceOptionsKeys = [];
  if (selectedMarket) {
    sourceOptionsKeys = Object.keys(selectedMarket?.options);
  }

  let specificOptionsArray = [];
  if (selectedMarket && data.how_hear_about_us_platform) {
    specificOptionsArray =
      selectedMarket?.options[data?.how_hear_about_us_platform];
  }
  const optionsToRender = Array.isArray(specificOptionsArray)
    ? specificOptionsArray
    : [];

  const formatPhoneNumber = (str) => {
    // Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    // Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return {
        formattedNumber: "+1 (" + match[1] + ") " + match[2] + "-" + match[3],
        isValid: true,
      };
    }

    return {
      formattedNumber: str,
      isValid: false,
    };
  };

  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleChange = (event) => {
    const value = event.target.value;

    if (event.target.name === "phone") {
      if (
        value.length > prevPhone.length &&
        (value.match(/\d/g) || []).length <= 10
      ) {
        const result = formatPhoneNumber(value);
        setData({
          ...data,
          [event.target.name]: result.formattedNumber,
        });
        setValidFormat(result.isValid);
      } else if (value.length < prevPhone.length) {
        // User has pressed backspace
        setData({
          ...data,
          [event.target.name]: value,
        });
        setValidFormat(false);
      }

      setPrevPhone(value);
    } else {
      setData({
        ...data,
        [event.target.name]: value,
      });
    }
  };

  const handleSubmit = () => {
    const {
      first_name,
      last_name,
      email,
      phone,
      interest,
      market,
      how_hear_about_us_platform,
      how_hear_about_us_specific,
    } = data;
    if (
      first_name === "" ||
      last_name === "" ||
      email === "" ||
      phone === "" ||
      interest === "" ||
      how_hear_about_us_platform === ""
    ) {
      toast.error("All fields must be filled");
      return;
    }

    // Email validation regex. It checks for most standard email formats.
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      toast.error("Email format is incorrect");
      return;
    }

    // Phone number validation: Checks for exactly 10 digits.
    const phoneRegex = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneRegex.test(phone)) {
      toast.error("Phone number format is incorrect");
      return;
    }

    if (how_hear_about_us_platform === "") {
      return toast.error("Please Select A How'd You Hear About Us?");
    }
    if (how_hear_about_us_platform === "Online") {
      if (how_hear_about_us_specific === "") {
        return toast.error("Please Select A How'd You Hear About Us?");
      }
    }
    let url = `${process.env.REACT_APP_API_KEY_URL}?type=feedback`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(data).toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Feedback submitted successfully.") {
          setData({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            interest: "",
            how_hear_about_us_platform: "",
            how_hear_about_us_specific: "",
          });

          
          navigate("/response");
        }
      })

      .catch((error) => {

      });
  };
  return (
    <>
      <div className="info">
        <div className="logoDiv">
          <ToastContainer />
          <img src={logoYuna} alt="" />
        </div>
        <div className="sMarket">
          <h1 className="sMarketH1">Search By Market</h1>
          <p className="stateRed">
            <Link to="/sell-my-house-fast-georgia">Georgia</Link>
          </p>
          <div className="innerSMarket">
            <p>Alpharetta</p>
            <p>Buford</p>
          </div>
          <div className="innerSMarket">
            <p>Atlanta</p>
            <p>Marietta</p>
          </div>
          <div className="innerSMarket">
            <p>Athens</p>
            <p>Stockbridge</p>
          </div>
          <div className="line"></div>

          <p className="stateRed">Tennessee </p>
          <div className="innerSMarket">
            <p>Ohio</p>
            <p>Columbus </p>
          </div>
          <div className="line"></div>

          <p className="stateRed">North Carolina</p>
          <div className="innerSMarket">
            <p>Raleigh</p>
            <p>Charlotte</p>
          </div>
          <div className="innerSMarket">
            <p>Greensboro</p>
          </div>
          <div className="line"></div>

          <p className="stateRed"><Link to="/sell-my-house-fast-florida">Florida</Link></p>
          <div className="innerSMarket">
            <p>Orlando</p>
            <p>Tampa</p>
          </div>
          <div className="innerSMarket">
            <p>Jacksonville</p>
          </div>
          <div className="lineSpace"></div>
          <div className="lineNone"></div>

          <p className="stateRed">South Carolina</p>
          <div className="innerSMarket">
            <p>Greenville</p>
          </div>
          <div className="line"></div>

          <p className="stateRed">Alabama </p>
          <div className="innerSMarket">
            <p>Indiana</p>
            <p>Indianapolis </p>
          </div>
          <div className="line"></div>

          <p className="stateRed">Texas</p>
          <div className="innerSMarket">
            <p>Fort Worth</p>
            <p>Dallas</p>
          </div>
          <div className="line"></div>
        </div>

        <div className="sConnected">
          <h2>Stay Connected!</h2>
          <form className="formMain" action="">
            <input
              className="formm"
              value={data.first_name}
              onChange={handleChange}
              name="first_name"
              type="text"
              placeholder="First Name"
            />
            <input
              className="formm"
              onChange={handleChange}
              value={data.last_name}
              name="last_name"
              type="text"
              placeholder="Last Name"
            />
            <input
              className="formm"
              onChange={handleChange}
              value={data.email}
              name="email"
              type="text"
              placeholder="Email"
            />
            <input
              className="formm"
              name="phone"
              type="text"
              value={data.phone}
              placeholder="Phone"
              onChange={handleChange}
              onKeyUp={handleChange}
            />

            <div className="inputtt">
              <div className="selectArrowsInfo">
                <BsChevronDown />
              </div>
              <select
                className="select"
                onChange={handleChange}
                value={data.interest}
                name="interest"
                id="cars"
              >
                <option value="">Interest*</option>
                <option value="Market">Seller</option>
                <option value="mercedes">Buyer</option>
              </select>
            </div>

            <div className="inputtt">
              <div className="selectArrowsInfo">
                <BsChevronDown />
              </div>
              <select
                className="select"
                name="how_hear_about_us_platform"
                value={data.how_hear_about_us_platform}
                onChange={handleChange}
              >
                <option value="">How'd you hear about us?</option>
                <option value="Online">Online</option>
                <option value="Billboard">Billboard</option>
              </select>
            </div>

            {data.how_hear_about_us_platform == "Online" && (
              <div className="inputtt">
                <div className="selectArrowsInfo">
                  <BsChevronDown />
                </div>{" "}
                <select
                  className="select"
                  name="how_hear_about_us_specific"
                  value={data.how_hear_about_us_specific}
                  onChange={handleChange}
                >
                  <option value="">How'd you hear about us?</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Nextdoor">Nextdoor</option>
                </select>
              </div>
            )}
          </form>

          <button onClick={handleSubmit}>Send</button>

          <div className="Paras">
            {" "}
            <p style={{cursor:"pointer"}}>Terms of Use </p> <p style={{cursor:"pointer"}}>Privacy Policy</p>
          </div>

          <h4>(470) 660-3532</h4>
          <h6>(470) 660-3532</h6>
        </div>
      </div>
      <div className="footerLine">
        <div className="linee"></div>
        <p className="termsPara">
          © 2023 Yuna Homes Real Estate. All rights reserved. Terms of Use
          Privacy Policy
        </p>
      </div>
    </>
  );
};

export default Info;
