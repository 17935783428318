import { useEffect, useState } from "react";
import { React } from "react";
import "./fourthSec.css";
import { Link, useLocation } from "react-router-dom";
import Slide from "react-reveal/Slide";
import houseImage from '../images/house.png';

const FourthSec = (props) => {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
  
    window.addEventListener("resize", handleResize);

    return () => {

      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div 
   style={{
    backgroundImage: props.backgroundd,
  }}
    
      className="fourthSec">
        <Slide left>
          <div className="redCard">
            <h3>{props.heading}</h3>
            <Link to={props.link}>
              <button
                onClick={() => {
                  {
                    if (props?.function) {
                      props?.function();
                    }
                  }
           
                }}
                style={{
                  height:
                    location.pathname === "/guaranteedoffer" &&
                    windowWidth < 376
                      ? "78px"
                      : "",
                }}
                className="formToButton"
              >
                {props.buttonText}
              </button>
            </Link>
          </div>
        </Slide>
      </div>
    </>
  );
};

export default FourthSec;
