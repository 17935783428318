import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "./YourComponent.css";
import jstz from "jstz";
import DateTime from "react-datetime";
import { useDispatch } from "react-redux";
import moment from "moment-timezone"; // use moment-timezone instead of moment for handling timezones
import "react-datetime/css/react-datetime.css";
import { setStep } from "../../../../redux/slices/locationSlice";

import "./datePickerForm.css";
function DatePickerForm({
  setTimeslots,
  timeslotData,
  setForm,
  form,
  backStep,
  setTotalSteps,
  nextStep,
  setConfirmation,
  recordId,
  setRecordId
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  var timezone = jstz.determine();
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [loader, setLoader] = useState(false);

  var timezone = jstz.determine();
  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    setSelectedDate(`${year}-${month}-${day}`);
    let url = `${process.env.REACT_APP_API_KEY_URL
      }?type=timeslots&month=${moment(date).format("YYYY-MM")}`;

    fetch(url, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTimeslots(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    nextStep();
  }, []);

  const handleBack = () => {
    setLoader(true);
    let intervalId = setInterval(() => {
    }, 2000);
    setTimeout(() => {
      dispatch(setStep(3));
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  };
  const dates = Object.keys(timeslotData);
  const times = selectedDate ? timeslotData[formattedDate] || [] : [];
  const handleTimeChange = (time) => {
    setSelectedTime(time);
    setForm({
      ...form,
      meet_date: formattedDate,
      meet_time: selectedTime,
    });
  };
  const handleDateChange = (date) => {
    const formattedDates = moment(date).format("YYYY-MM-DD");
    setValue(formattedDates);
    setSelectedDate(formattedDates);
    setFormattedDate(formattedDates);

    if (moment(formattedDate, "YYYY-MM-DD", true).isValid()) {
      let url = `${process.env.REACT_APP_API_KEY_URL
        }?type=timeslots&month=${moment(formattedDate).format(
          "YYYY-MM"
        )}&timezone=${timezone.name()}`;

      fetch(url, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(formattedDate),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setTimeslots(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    const newYorkTime = moment
      .tz(
        `${formattedDate} ${selectedTime}`,
        "YYYY-MM-DD hh:mm",
        timezone.name()
      )
      .tz("America/New_York");

    setForm({
      ...form,
      meet_date: newYorkTime.format("YYYY-MM-DD"),
      meet_time: newYorkTime.format("hh:mm"),
    });
  };

  useEffect(() => {
    if (selectedTime) {
      setForm({
        ...form,
        meet_time: selectedTime,
      });
    }
  }, [handleTimeChange]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formattedDate && selectedTime) {
      setForm({
        ...form,
        meet_date: formattedDate,
        meet_time: selectedTime,
      });
      let urlWithParams = `${process.env.REACT_APP_API_KEY_URL}?type=query`;
      if (recordId) {
        urlWithParams = `${process.env.REACT_APP_API_KEY_URL}?type=query&record_id=${recordId}`
      }
      fetch(urlWithParams, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(form), // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.message == "Data inserted successfully" || data?.message == "Data updated successfully") {
            toast.success("Form submitted successfully");
            setLoader(false);
            setConfirmation(true);
          }
          if (data?.record_id) {
            setRecordId(data?.record_id);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      setLoader(true);
      let intervalId = setInterval(() => {
        setConfirmation(true);
      }, 2000);
      setTimeout(() => {
        setLoader(false);
        clearInterval(intervalId);
      }, 2000);
    } else if (!formattedDate) {
      toast.error("Please Select Date");
    } else if (!selectedTime) {
      toast.error("Please Select Time");
    }
  };
  return (
    <form className="step3">
      <ToastContainer />
      <div className="dateTime">
        <div className="input-field">
          <label>Select a Date:</label>
          <DateTime
            timeFormat={false}
            closeOnSelect={true}
            value={value}
            dateFormat="YYYY-MM-DD"
            className="date-picker"
            onChange={handleDateChange}
          />
        </div>
        {selectedDate && (
          <div className="input-field">
            <label>Select a Time:</label>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "3px" }}>
              {times.length > 0 ? (
                <>
                  {" "}
                  {times.map((time) => (
                    <button
                      key={time}
                      onClick={(e) => {
                        e.preventDefault();
                        handleTimeChange(time);
                      }}
                      className={
                        selectedTime === time ? "slots activer" : "slots"
                      }
                    >
                      {time}
                    </button>
                  ))}
                </>
              ) : (
                <p style={{ textAlign: "center", width: "100%" }}>
                  No timeslots available
                </p>
              )}
            </div>
          </div>
        )}{" "}
      </div>
      {loader && <Loader />}
      <div className="search">
        <button className="back" onClick={handleBack}>
          Market
        </button>

        <button onClick={handleSubmit} disabled={loader}>
          Submit
        </button>
      </div>
    </form>
  );
}

export default DatePickerForm;

//
