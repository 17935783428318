import React from "react";
import location from "../images/location.png";
import features from "../images/features.png";
import home from "../images/home.PNG";
import "./FloridaFourthSec.css";
import Slide from "react-reveal/Slide";
import swift from "../images/swift.png";
import noAgent from "../images/noAgent.png";
import fair from "../images/fair.png";

const FloridaFourthSec = ({title, summary, onet, twot, threet, one, two, three}) => {
  return (
    <div className="SellSettingPrice">
      <p className="SellPara">{title}</p>

      <Slide left>
        <p className="sellParaTwo">
        {summary}
        </p>
      </Slide>
      <Slide left>
        <div className="floridaLineYelloww"></div>
        {/* <h4
          style={{
            width: "80%",
            fontFamily: "proxima-nova, sans-serif",
            fontStyle: "normal",
            fontWeight: 300,
            color: "#4a4646",
            marginTop: "60px",
            marginBottom: "0px",
            fontSize: "25px",
            textAlign: "center",
            lineHeight: 1.5,
            opacity: 0.9,
          }}
          class="Selltext-center"
        >
          We will consider:
        </h4> */}
      </Slide>

      <div className="floridaFourthSelliconsMain">
      <Slide up> 
        <div className="floridaFourthSellIcons">
            <img 
              src={swift}
              className="zoom-on-hover"
              alt=""
              style={{
                width: "16%",
              }} 
            />
            <h4>{onet}</h4>
            <h5
            style={{marginTop:"0px"}}>
              {one}
 </h5>
        </div>
        </Slide> 
        <Slide up> 
        <div className="floridaFourthSellIcons">
         
            <img
              src={noAgent}
              alt=""
              style={{
                width: "16%",
              }}
            />
            <h4>{twot}</h4>
            {/* <p>Don’t worry about repairs, closing costs, and commissions.</p> */}
            <h5
            style={{marginTop:"0px"}}>
              {two}
 </h5>
        </div>
        </Slide>
         <Slide up>
        <div className="floridaFourthSellIcons"> 
         
            <img
              src={fair}
              alt=""
              style={{
                width: "16%",
              }}
            />
            <h4>{threet}</h4>
            <h5
            style={{marginTop:"0px"}}>
            {three}
            </h5>
        </div>
        </Slide>
      </div>

      {/* <p className="SellParaLast">
        Get Your Comparative Market Analysis and Sell Your Home Today With Yuna
        Homes Real Estate.{" "}
      </p> */}
    </div>
  );
};

export default FloridaFourthSec;
