import React from "react";
import "./AboutProud.css";
import proudIconThree from "../images/proudIconThree.png";
import thumbProud from "../images/thumbProud.png";
import proudIconTwo from "../images/proudIconTwo.png";
import proudIconFour from "../images/proudIconFour.png";
import proudIconFive from "../images/proudIconFive.png";
import proudIconSix from "../images/proudIconSix.png";
import proudIconSeven from "../images/proudIconSeven.png";
import proudIconEight from "../images/proudIconEight.png";
import Slide from "react-reveal/Slide";
const Proud = () => {
  return (
    <>
      <div className="aboutProud">
        <Slide left >
          <p>We’re proud to be:</p>
        </Slide>
    
          <div className="proudIconsMain">
            <div className="iconDiv">
              
              <div className="proudIconPara">
              <Slide up >
                <img src={thumbProud} alt="" />
                <p>
                  #1 most reviewed real estate company on Zillow in the U.S.
                </p>
                </Slide>
              </div>
            </div>
            <div className="iconDiv">
              <div className="proudIconPara">
              <Slide up >
                <img src={proudIconTwo} alt="" />
                <p>
                  #1,973 Inc. 5000 Fastest Growing Private Companies in America
                </p>
                </Slide>
              </div>
            </div>
            <div className="iconDiv">
              <div className="proudIconPara">
              <Slide up >
                <img src={proudIconThree} alt="" />
                <p>
                  Atlanta Business Chronicle: Top 100 Fastest Growing privately
                  held companies
                </p>
                </Slide>
              </div>
            </div>
            <div className="iconDiv">
              <div className="proudIconPara">
              <Slide up >
                <img src={proudIconFour} alt="" />
                <p>Atlanta Business Chronicle "Best Places to Work"</p>
                </Slide>
              </div>
            </div>
            <div className="iconDiv">
              <div className="proudIconPara">
              <Slide up >
                <img src={proudIconFive} alt="" />
               
                <p>
                  Bulldog 100 Award Winner: Fastest Growing Companies Owned by
                  UGA Alumni
                </p>
                </Slide>
              </div>
            </div>
            <div className="iconDiv">
              <div className="proudIconPara">
              <Slide up >
                <img src={proudIconSix} alt="" />
                <p>
                  Wall Street Journal / REAL Trends THOUSAND seventh year as a
                  Top Residential Real Estate Team including #1 in the U.S. for
                  closed transactions for 5 consecutive years
                </p>
                </Slide>
              </div>
            </div>
            <div className="iconDiv">
              <div className="proudIconPara">
              <Slide up >
                <img src={proudIconSeven} alt="" />
                <p>
                  Named a top-producing residential real estate firm on the Real
                  Trends 500 a list for four consecutive year
                </p>
                </Slide>
              </div>
            </div>
            <div className="iconDiv">
              <div className="proudIconPara">
              <Slide up >
                <img src={proudIconEight} alt="" />
                <p>Atlanta Journal Constitution: Top Workplaces in Atlanta</p>
                </Slide>
              </div>
            </div>
          </div>
       
      </div>
    </>
  );
};

export default Proud;
