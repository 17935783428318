import { React, useState, useEffect } from "react";
import "./AboutSecond.css";
import proudIconFourwhite from "../images/proudIconFourwhite.png";
import proudIconSixwhite from "../images/proudIconSixwhite.png";
import proudIconSevenwhite from "../images/proudIconSevenwhite.png";
import arrowAnimatios from "../images/arrowAnimatios.png";

import Slide from "react-reveal/Slide";

const AboutSecond = ({ scrollTargetId }) => {


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener("resize", handleResize);
   
    return () => {
   
      window.removeEventListener("resize", handleResize);
    };
  }, []); 

  const handleArrowClick = (id) => {
    const scrollTarget = document.getElementById(id);
    if (scrollTarget) {
      scrollTarget.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div>
        {windowWidth < 768 ? (
          <div className="aboutSecondMain">
            <div className="AboutSecond">
              <p className="secondRedAbout">Unrivaled Customer Satisfaction</p>

              <Slide left>
                <p className="secondParaAbout">
                  We pride ourselves on a growing collection of sterling
                  reviews, boasting a legion of satisfied clients who sing our
                  praises. Each 5-star review is a testament to our commitment
                  to deliver not just a service, but a seamless, stress-free
                  experience that puts you, our valued client, first
                
                </p>
              </Slide>
            </div>

            <div className="AboutSecondPics">
              <div className="aboutIconsMain">
                <div
                  onClick={() => handleArrowClick("scrollHistory")}
                  className="aboutIcons"
                >
                  <Slide left>
                    <img
                      src={proudIconSevenwhite}
                      alt=""
                      style={{
                        width: 53,
                        height: 53,
                      }}
                    />
                    <h4>History</h4>
                    <img
                      className="arrowAnimations"
                      style={{
                        width: "2rem",
                        height: "2rem",
                     
                      }}
                      src={arrowAnimatios}
                      alt=""
                    />
                 
                  </Slide>
                </div>

                <div
                  onClick={() => handleArrowClick("scrollCulture")}
                  className="aboutIcons"
                >
                  <Slide left>
                    <img
                      src={proudIconSixwhite}
                      alt=""
                      style={{
                        width: 53,
                        height: 53,
                      }}
                    />
                    <h4>Culture</h4>
                    <img
                      className="arrowAnimations"
                      style={{
                        width: "2rem",
                        height: "2rem",
                   
                      }}
                      src={arrowAnimatios}
                      alt=""
                    />
           
                  </Slide>
                </div>
                <div
                  onClick={() => handleArrowClick("scrollCarousel")}
                  className="aboutIcons"
                >
                  <Slide left>
                    <img
                      src={proudIconFourwhite}
                      alt=""
                      style={{
                        width: 53,
                        height: 53,
                      }}
                    />
                    <h4>Accomplishments</h4>
                    <img
                      className="arrowAnimations"
                      style={{
                        width: "2rem",
                        height: "2rem",
                  
                      }}
                      src={arrowAnimatios}
                      alt=""
                    />
           
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mainnnAnimations" style={{}}>
            <div className="aboutSecondMain">
              <div className="AboutSecond">
                <p className="secondRedAbout">
                  Unrivaled Customer Satisfaction
                </p>
                <Slide left>
                  <p className="secondParaAbout">
                    We pride ourselves on a growing collection of sterling
                    reviews, boasting a legion of satisfied clients who sing our
                    praises.
                  </p>
                </Slide>
              </div>
            </div>

            <div className="AboutSecondPics">
            

              <div
                className="aboutIcons"
                onClick={() => handleArrowClick("scrollHistory")}
              >
                <Slide left>
                  <img
                    src={proudIconSevenwhite}
                    alt=""
                    style={{
                      width: 53,
                      height: 53,
                    }}
                  />
                  <h4>History</h4>
                  <img
                    className="arrowAnimations"
                    style={{
                      width: "2rem",
                      height: "2rem",
                   
                    }}
                    src={arrowAnimatios}
                    alt=""
                  />
            
                </Slide>
              </div>

              <div
                className="aboutIcons"
                onClick={() => handleArrowClick("scrollCulture")}
              >
                <Slide left>
                  <img
                    src={proudIconSixwhite}
                    alt=""
                    style={{
                      width: 53,
                      height: 53,
                    }}
                  />
                  <h4>Culture</h4>
                  <img
                    className="arrowAnimations"
                    style={{
                      width: "2rem",
                      height: "2rem",
                      // marginTop:"20px"
                    }}
                    src={arrowAnimatios}
                    alt=""
                  />
                  {/* <p>Don’t worry about repairs, closing costs, and commissions.</p> */}
                </Slide>
              </div>

              <div
                className="aboutIcons"
                onClick={() => handleArrowClick("scrollCarousel")}
              >
                <Slide left>
                  <img
                    src={proudIconFourwhite}
                    alt=""
                    style={{
                      width: 53,
                      height: 53,
                    }}
                  />
                  <h4>Accomplishments</h4>
                  <img
                    className="arrowAnimations"
                    style={{
                      width: "2rem",
                      height: "2rem",
                      // marginTop:"20px"
                    }}
                    src={arrowAnimatios}
                    alt=""
                  />
               
                </Slide>
             
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AboutSecond;
