
import Banner from "../components/Banner";
import SecondSec from "../components/SecondSec";
import SixthFAQ from "../components/SixthFAQ";
import Info from "../components/Info";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import HousesFlorida from "../components/HousesFlorida";
import backgroundBanner from '../images/CMA-Background.webp'
import ParallexEffectFlorida from "../components/ParallexEffectFlorida";
import FloridaSecondSec from "../components/FloridaSecondSec";
import {Helmet} from "react-helmet";

const HomePageFlorida = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
       <Helmet>
        <title>Sell My House Fast in Florida | Buying a House in Florida</title>
        <meta
          name="description"
          content="Sell My House Fast in Florida with Yuna Homes - Get Cash Offer, Close Quickly, No Repairs, No Commissions. Buying a House in Florida? We Simplify the Process!"
        />
      </Helmet>
      <Banner background = {backgroundBanner} 
             headingg={
                "Sell Your House Fast in Florida: \n Offer Guaranteed!"
              }
       subHeadingg ={"Buy a House Fast in Florida, fill out the form now to receive your FREE CASH OFFER."}/>
      <HousesFlorida subHeadingg ={"Yuna Homes: Your Trusted Partner for Quick and Hassle-Free Home Sales in Florida. Your Go-To Choice for Selling a House Fast in Florida. No Repairs. No Commissions. \n \n Buy a House Fast in Florida, fill out the form now to receive your FREE CASH OFFER."} title={"We Buy Houses in Florida:"} onet = "1. Fast Cash Offers" twot="2. Local Experts" threet="3. Flexible Options" fourt="4. Flexible Closing" fivet="5. Avoid Foreclosure" one = "Need to sell your house quickly? We offer fast cash offers, ensuring you get your money in your hands sooner rather than later. Say goodbye to the uncertainty of waiting for potential buyers to secure financing." two="With a deep understanding of the Florida real estate market, we are your trusted local experts. We're here to guide you through the entire selling process, answering any questions." three="Every homeowner's situation is different. We offer flexible solutions, whether you're facing foreclosure, relocating, inheriting a property, or just want a hassle-free sale." four="We work on your timeline. You can choose the closing date that suits your needs, allowing you to plan your move without stress." five="If you're facing foreclosure, we can help you avoid the stress and credit implications by purchasing your house quickly."/>
      <FloridaSecondSec content= {"We believe that buying a house should be a joyous and simple experience."}/>
      {/* <SecondSec /> */}
      <ParallexEffectFlorida />
      {/* <SixthFAQ buttonText = "Get My Offer"/> */}
      <Info />
    </>
  );
};

export default HomePageFlorida;
