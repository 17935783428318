import { React, useState, useEffect } from "react";
import "./FloridaLast.css";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";

const FloridaLast = ({title, p1, p2, p3 ,p4, p5}) => {
  //   const isSmallScreen = window.innerWidth < 470;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);
  return (
    <>
      {windowWidth < 768 ? (
     <div className="floridaLastSellSecFourthMain"> 
        <div className="floridaLastSellSecFourth">
          <Slide left>
            <p className="SellSecondRed">
              {title}
            {/* Sell a House Fast in Florida */}
            </p>
            <div className="floridaLastYellow"></div>
          </Slide>
          <Slide left>
          <div className="parasFloridaBlack">
            <p className="floridaLastSellSecondPara">
              {p1}
            </p>
<p className="floridaLastSellSecondPara">
{p2}
</p>
<p className="floridaLastSellSecondPara">
{p3}</p>
<p className="floridaLastSellSecondPara">
{p4}</p>
<p className="floridaLastSellSecondPara">
{p5}
            </p> 
            </div>
          </Slide>
          {/* <Slide left>
            <Link to="/guaranteedoffer">
              <button onClick={() => setTop(!top)}>
                The Yuna Homes Guaranteed Offer
              </button>
            </Link>
          </Slide> */}
        </div>
      </div>
      ) : (
        <div className="floridaLastSellSecFourthMain"> 
        <div style={{display:"flex", justifyContent:"center"}}>
        <div className="floridaLastSellSecFourth">
          <Slide left>
            <p className="SellSecondRed">
            {title}
            </p>
            <div className="floridaLastYellow"></div>
          </Slide>
          <Slide left>
            <div className="parasFloridaBlack">
            <p className="floridaLastSellSecondPara">
{p1}            </p>
<p className="floridaLastSellSecondPara">
{p2}</p>
<p className="floridaLastSellSecondPara">
{p3}</p>
<p className="floridaLastSellSecondPara">
{p4}</p>
<p className="floridaLastSellSecondPara">
{p5}
            </p> 
            </div>
            <div />
          </Slide>
      
        </div>
        </div>
        </div>
      )}
    </>
  );
};

export default FloridaLast;
