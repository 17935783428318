import React from "react";
import { useState, useEffect} from "react";
import { useRef } from "react";
import "./Graphs.css";

import Jump from "react-reveal/Jump";

const Graphs = () => {
  const arr = [60, 45, 30, 15, 0];
  const arr2 = [60, 70, 80, 90, 100];
  const customPercentage = (value) => {
    return value * 0.6 * (arr.length - 1) + 2 * (arr.length - 1);
  };

  const [expanded, setExpanded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isAnimatingTwo, setIsAnimatingTwo] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
  }, []);

  useEffect(() => {
    // Trigger the animation after a delay
    const timer = setTimeout(() => {
      setExpanded(true);
    }, 100); // You can adjust the delay as needed

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, []);
  const targetRef = useRef(null);


 //for animations only once//
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const [animationTwoPlayed, setAnimationTwoPlayed] = useState(false);
   //for animations only once end//

  
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold as needed
    };
  
    const callback = (entries) => {
      entries.forEach((entry) => {
        console.log("Intersection observed:", entry.isIntersecting);
        if (entry.isIntersecting && !animationPlayed) {
          setIsAnimating(true);
          setAnimationPlayed(true);
        } else {
          setIsAnimating(false);
        }
      });
    };
  
    const observer = new IntersectionObserver(callback, options);
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
  
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [animationPlayed]);


  //width animation 

  const targetRefTwo = useRef(null);
  const targetRefPinkTwo = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && ! animationTwoPlayed) {
          setIsAnimatingTwo(true);
          setAnimationTwoPlayed(true)

        } else {
          setIsAnimatingTwo(false);
        }
      });
    };

    const observerTwo = new IntersectionObserver(callback, options);
    if (targetRefTwo.current && targetRefPinkTwo.current) {
      observerTwo.observe(targetRefTwo.current);
      observerTwo.observe(targetRefPinkTwo.current);
    }

    return () => {
      if (targetRefTwo.current && targetRefPinkTwo.current) {
        observerTwo.unobserve(targetRefTwo.current);
        observerTwo.unobserve(targetRefPinkTwo.current);
      }
    };
  }, [animationTwoPlayed]);
  //width animations end 
 

 



  return (
    <>
      <div className="graphs">
        <div className="graphsMain">
          <div className="graphOne" style={{ position: "relative" }}>
            <div
            ref={targetRef}
              className={`graphGrey ${expanded ? "expanded" : ""}`}
              style={{
                
                position: "absolute",
                height: isAnimating || animationPlayed ? customPercentage(37 + 23) : 0, 

                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                color: "black",
                fontWeight: "bold",
                fontSize: "30.15px",
                transition: "height 2s ease",
              }}
            >
              37%
            </div>
            <div
            // ref={targetRef}
              className="graphPink"
              style={{
                position: "absolute",
                height: isAnimating || animationPlayed ?  customPercentage(25 + 14) : 1,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                color: "black",
                fontWeight: "bold",
                fontSize: "30.15px",
                transition: "height 2s ease",
                zIndex: "1",
              }}
            >
              25%
            </div>
            {arr.map((item, index) => {
              return (
                <div
                  className="GraphLine"
                  key={index}
                  style={{
                    position: "relative",

                    alignItems: "baseline",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      marginLeft: "-30px",
                      marginTop: index == 0 ? -10 : 50,
                      color: "#CACACA",
                    }}
                  >
                    {item}
                  </div>
                  <div
                    className="graphLine"
                    style={{
                      marginTop: index == 0 ? 0 : 60,
                      background: "#CACACA",
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
          <div className="AverageDays">
            <p>Average Days On The Market</p>
            <p>Yuna Homes Average Days On The Market</p>
          </div>
        </div>
        <div className="graphTwo">
          <div className="graphsMainTwo">
            <div className="graphTwoRelative" style={{ position: "relative" }}>
              <div
                ref={targetRefTwo}
                className="graphGreyTwo"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  width: 291.2,
                  maxWidth: isAnimatingTwo || animationTwoPlayed ? customPercentage(91 + 27) : 0,
                  height: 90,
                  bottom: 113,
                  left: 0,
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "30.15px",
                  transition: "max-width 3s ease",
                }}
              >
                91%
              </div>
              <div
                className="graphPinkTwo"
                ref={targetRefPinkTwo}
                style={{
                  position: "absolute",
                  zIndex: 1,
                  width: 309.2,
                  maxWidth: isAnimatingTwo || animationTwoPlayed ? customPercentage(93 + 32.5) : 0,
                  height: 90,
                  bottom: 23,
                  left: 0,
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "30.15px",
                  transition: "max-width 3s ease",
                }}
              >
                93%
              </div>
              {arr2.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      alignItems: "baseline",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: "-10px",
                        marginTop: "-20px",
                        color: "#CACACA",
                      }}
                    >
                      {item}%
                    </div>
                    <div
                      style={{
                        width: 1,
                        height: 270,
                        // marginTop: index == 0 ? 0 : 60,
                        background: "#CACACA",
                      }}
                    ></div>
                  </div>
                );
              })}
            </div>
            <div className="AverageDaysTwo">
              <p>Sales Price as a Percentage of List Price</p>
              <p>Yuna Homes Sales Price as a Percentage of List Price</p>
            </div>
          </div>
        </div>
      </div>

      <div className="graphIcons">
        <div className="circleNpara">
          <div className="greyCircle"></div>
          <p>Local Market</p>
        </div>
        <div className="circleNparaTwo">
          <div className="yellowCircle"></div>
          <p>YHRE</p>
        </div>
      </div>
    </>
  );
};

export default Graphs;
