import { useEffect, useState } from "react";
import React from "react";
import "./AboutJoinTeam.css";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
const AboutJoinTeam = (props) => {
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);
  return (
    <>
      <div className="AboutJoinTeamMain">
        <div className="AboutJoinTeamMainHeading">
          <Slide left >
            <p>Join our team!</p>
          </Slide>
        </div>
        <div className="AboutJoinTeamMainPara">
          <Slide left >
            <p>
              Our team is growing rapidly, and we are looking for talented
              professionals to join us. We’re now hiring in Georgia, North
              Carolina, South Carolina, Tennessee, Florida, Alabama, and Texas!{" "}
            </p>
          </Slide>
        </div>
       
          <div className="vs">
          <Slide up >
            <div className="vsleft">
              <p className="vsLeftHead">{props.leftHead} </p>
              <p>{props.leftParaa}</p>
            </div>
            {/* <div className="vertLine"></div> */}
            <div className="vsright">
              <p className="vsLeftHead"> {props.rightHead}</p>
              <p>{props.rightParaa}</p>
            </div>
            </Slide>
          </div>
       
        <Slide left >
          <div style={{display: "flex",
    width: "100%",
    justifyContent: "center",}}>
          {/* <Link to="">
            <button >
              {props.JoinTeamButtonContent}{" "}
            </button>
          </Link> */}
          </div>
        </Slide>
      </div>
    </>
  );
};

export default AboutJoinTeam;
