import {React, useState, useRef} from "react";
import { Carousel } from "react-responsive-carousel";
import "./overrides.css";
import Card from "./Card";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loade
import ReviewPic1 from "../images/ReviewPic1.jpeg";
import ReviewPic2 from "../images/ReviewPic2.jpeg";
import ReviewPic3 from "../images/ReviewPic3.jpeg";
import Slide from "react-reveal/Slide";
const TestimonialsAbout = () => {
  const [index, setIndex] = useState(0);
  const carouselRef = useRef();
  return (
    <>
      <div
         style={{
          position: "relative",
        }}
      >
         <Slide left >
        <Carousel
          ref={carouselRef}
          autoPlay={true}
          stopOnHover={true}
          showStatus={false}
          // centerSlidePercentage = "33%"
          infiniteLoop={true}
          emulateTouch
          swipeable={true}
          showArrows={false}
          showThumbs={false}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          onChange={(e) => {
            setIndex(e);
            console.log("");
          }}
        >
          <Card
            testi="As an older individual, the thought of selling my home was quite daunting. I had spent decades in it, raising my family and creating beautiful memories. The wear and tear of the years were evident, and I was worried I would have to invest a lot of time and money into repairs before I could even think about selling. Then, I found Yuna Homes"
            image={ReviewPic1}
            name=""
          />
          <Card
            testi="Selling my inherited property seemed like a daunting task until I found Yuna Homes. Their straightforward approach, complete with a no-commission, no-closing-cost promise, truly simplified the process. And the best part? They worked around my schedule, providing the utmost flexibility. I'm truly grateful for Yuna Homes for making a potentially stressful situation, remarkably stress-free!"
            image={ReviewPic2}
            name=""
          />
          <Card
            testi=" I never thought selling my home could be this straightforward until I partnered with Yuna Homes. They took my property as-is, saving me from repair costs. With no commissions and closing fees, I kept more from my sale. Their flexible timeline was a real game-changer. Yuna Homes truly put my needs first. A top-notch experience!"
            image={ReviewPic3}
            name=""
          />
        </Carousel>
        <div
            className="dotsCarousel"
            style={{
              position: "absolute",
              bottom: 30,
              left: 70,
              display: "flex",
              cursor: "pointer",
            }}
          >
            <div
              onClick={() => {
                carouselRef.current.moveTo(0);
              }}
              style={{ background: index == 0 ? "#ffd400" : "#000" }}
              className="ControlDot"
            ></div>
            <div
              onClick={() => {
                carouselRef.current.moveTo(1);
              }}
              style={{ background: index == 1 ? "#ffd400" : "#000" }}
              className="ControlDot"
            ></div>
            <div
              onClick={() => {
                carouselRef.current.moveTo(2);
              }}
              style={{ background: index == 2 ? "#ffd400" : "#000" }}
              className="ControlDot"
            ></div>
          </div>
          </Slide>
      </div>
    </>
  );
};

export default TestimonialsAbout;
