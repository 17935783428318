import { React, useState, useEffect } from "react";
import "./AboutStoryWhite.css";
import { Link } from "react-router-dom";

const AboutStoryWhite = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => {

      window.removeEventListener("resize", handleResize);
    };
  }, []); 
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);
  return (
    <>
      {windowWidth < 768 ? (
        <div className="AboutStoryWhite">
          <p className="AboutSecondRedWhite">Check Out Our Story</p>
          <p className="AboutSecondParaWhite">
            <br />
            At Yuna Homes Real Estate, our clients always come first. YOU always
            come first.
          </p>
          <Link to="">
            <button>Watch Now</button>
          </Link>
        </div>
      ) : (
        <div className="AboutStoryWhite">
          <p className="AboutSecondRedWhite">Check Out Our Story</p>
          <p className="AboutSecondParaWhite">
            At Yuna Homes Real Estate, our clients always come first. YOU always
            come first.
          </p>
          <Link to="">
            <button>Watch Now</button>
          </Link>
        </div>
      )}
    </>
  );
};

export default AboutStoryWhite;
