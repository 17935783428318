
import BannerThreeSell from "../components/BannerThreeSell";
import SecondSecThreeSell from "../components/SecondSecThreeSell";
import FourthSec from "../components/FourthSec";
import Info from "../components/Info";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import Graphs from "../components/Graphs";
import SellSecFourBlack from "../components/SellSecFourBlack";
import Strategy from "../components/Strategy";
import SellBlwStrategy from "../components/SellBlwStrategy";
import SellSettingPrice from "../components/SellSettingPrice";
import "./sell.css";
import FormModal from "../components/FormModal";
import { useDispatch, useSelector } from "react-redux";
import sell_fourth_banner from '../images/sell_fourth_banner.webp';
import { setModalOpenTwo } from "../redux/slices/formSlice";
const Sell = () => {
  const dispatch = useDispatch();
  const {modalOpen, modalOpenTwo} = useSelector(state=>state.formCategroies)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const closeModalTwo = () => {
    dispatch(setModalOpenTwo(false))
  };

  return (
    <>
      {" "}
      <div 
      className={`${(modalOpen || modalOpenTwo)? "sellDisable":"sell"}`}>
        <BannerThreeSell />
        <SecondSecThreeSell />
        <Graphs />
        {/* <ThirdSecGuaranteed /> */}
        <SellSecFourBlack />

        <Strategy />
        <SellBlwStrategy />
        <SellSettingPrice />
        <FourthSec
          heading="Get maximum exposure for your listing today."
          buttonText="Sell Your Home"
          backgroundd = {`url(${sell_fourth_banner})`}
          // function={() => {
          //   dispatch(setModalOpenTwo(true))
          // }}        
          link={"/form"}
        />
        <Info />
        <FormModal isOpen={modalOpenTwo} onClose={closeModalTwo} />
      </div>
    </>
  );
};

export default Sell;
