import { useEffect } from "react";
import Banner from "../components/Banner";
import SecondSec from "../components/SecondSec";
import SixthFAQ from "../components/SixthFAQ";
import Info from "../components/Info";
import "react-toastify/dist/ReactToastify.css";
import HousesFlorida from "../components/HousesFlorida";
import ParallexEffectFlorida from "../components/ParallexEffectFlorida";
import FloridaSecondSec from "../components/FloridaSecondSec";
import backgroundBanner from '../images/CMA-Background.webp'
import ParallexEffectDrip from "../components/ParallexEffectDrip";
import {Helmet} from "react-helmet";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
          <Helmet>
        <title>Sell My House Fast in Georgia | Buying a House in Georgia</title>
        <meta
          name="description"
          content="Buy or Sell My House Fast in Georgia with Yuna Homes. Cash offers for Buying a 
          House in Georgia. No fees, no hassle. Get your cash offer now!"
        />
      </Helmet>
      <Banner background = {backgroundBanner} 
             headingg={
                "Sell Your House Fast in Georgia \n with Confidence!"
              }
       subHeadingg ={`Buying a House in Georgia? Fill out the form now for a FREE CASH OFFER. `}/>
      
      <HousesFlorida
subHeadingg={"Yuna Homes: Your Trusted Partner for Quick and Hassle-Free Home Sales Instant Cash Offers for Homes in Any Condition Throughout Georgia No Repairs. No Commissions."}
      title="We Buy Houses in Georgia:" onet = "1. Simplifying Home Sales:" twot="2. Fast Cash Solutions:" threet="3. Local Expertise:" fourt="4. Removing Agent Drawbacks:" fivet="5. Covering Closing Costs:" one = "We understand that selling your home can be a daunting and time-consuming task. Our primary goal is to simplify this process for you. No real estate agents, no fees, and no waiting. We've designed our home buying process to make your life easier." two="When you need to sell your house fast in Georgia, you can count on us. We buy houses for cash, ensuring that you receive the funds you need quickly. This means you can move forward with your plans without delay." three="We believe in the power of local knowledge. That's why 
we connect you with a local investor in Georgia who specialises in your area's 
real estate market. This ensures that you receive an offer that's tailored to 
your specific needs and market conditions." four="Working with real estate agents can come 
with drawbacks like commissions and waiting for the right buyer. We eliminate
these inconveniences, allowing you to sell your house on your terms." five="Yuna Homes goes the extra mile by covering 
closing costs. This means more money in your pocket when the sale is 
finalized."/>
      <FloridaSecondSec content= {"We believe that buying a house should be a joyous and simple experience."}/>
      {/* <SecondSec /> */}
      <ParallexEffectDrip />
      {/* <ParallexEffectFlorida /> */}
      {/* <SixthFAQ buttonText = "Get My Offer"/> */}
      <Info />
      
      
      {/* <SecondSec />
      <ParallexEffectDrip />
      <SixthFAQ buttonText = "Get My Offer"/>
      <Info /> */}
    </>
  );
};

export default HomePage;
