import { React, useEffect, useState } from "react";
import "./AboutBanner.css";
import { Link } from "react-router-dom";
import Header from "./Header";
import Slide from "react-reveal/Slide";
const AboutBanner = (first_name) => {
  const [top, setTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [top]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Clean up function
    return () => {
      // Remove event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <>
      <Header />
      <Slide up>
        {windowWidth < 850 ? (
          <div className="aboutBanner">
            <h1>
              #1 Real Estate Team With Over $2.9 billion in lifetime sales!
            </h1>
            <p>
              Yuna Homes is revolutionizing the real estate industry with
              unparalleled customer satisfaction.{" "}
            </p>
            {/* <Link to="/form">
          <button onClick={() => setTop(!top)}>Sell Your Home</button>
        </Link> */}
          </div>
        ) : (
          <div className="aboutBanner">
            <h1>
              #1 Real Estate Team With Over $2.9 billion in lifetime sales!
            </h1>
            <p>
              Yuna Homes is revolutionizing the real estate industry with
              unparalleled customer satisfaction.{" "}
            </p>
            {/* <Link to="/form">
          <button onClick={() => setTop(!top)}>Sell Your Home</button>
        </Link> */}
          </div>
        )}
      </Slide>
    </>
  );
};

export default AboutBanner;
