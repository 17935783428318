/** @format */

import "./formpage.css";
import InputField from "./Component/inputField";
import { useEffect, useState } from "react";
import ProgressBar from "./Component/ProgressBar/Progressbar";
import Form from "./Component/SelectInput";
import { useLocation } from "react-router-dom";
import Stepper1 from "./Component/Form1/Stepper1";
import Stepper2 from "./Component/Form2/Stepper2";
import Stepper3 from "./Component/Form3/Stepper3";
import Stepper4 from "./Component/Form4/Stepper4";
import Stepper5 from "./Component/Form5/Stepper5";
import Stepper6 from "./Component/Form6/Stepper6";
import Stepper7 from "./Component/Form7/Stepper7";
import { useSelector } from "react-redux";
import { setStep } from "../../redux/slices/locationSlice";
import { useDispatch } from "react-redux";
import React, { useContext } from "react";
import FormStepContext from "./Component/FormStepContext"; // Replace with the actual path
import { setMarketplace, setCategories } from "../../redux/slices/formSlice";

function FormPage() {
  const { currentStep, setCurrentStep } = useContext(FormStepContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);
  const dispatch = useDispatch();
  const addressContent = useSelector((state) => state.location);
  const [step, setSteps] = useState(addressContent.step);
  const [totalSteps, setTotalSteps] = useState(7);
  const [recordId, setRecordId] = useState();
  const [form, setForm] = useState({
    address: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    market_type: "",
    how_hear_about_us_platform: "",
    how_hear_about_us_specific: "",
    bedrooms: "",
    bathrooms: "",
    square_footage: "",
    property_type: "",
    hoa_type: "",
    annual_hoa_fee: "",
    hvac_age: "",
    roof_age: "",
    foundation_type: "",
    finished_basement: "",
    kitchen_countertops: "",
    kitchen_appliances: "",
    has_pool: "",
    is_listed: "",
    waste_handling: "",
    interest_in_selling: "",
    property_owner: "",
    setup_meeting: "",
    meet_date: "",
    meet_time: "",
  });

  useEffect(() => {
    const submitName = async () => {
      let formData = new FormData();
      formData.append("name", "weweame");
      const url = process.env.REACT_APP_API_KEY_URL;
      fetch(url, {
        method: "POST", // or 'PUT'
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          dispatch(setMarketplace(json));
          dispatch(setCategories(json));
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    submitName(); // calling the async function
  }, []);
  const nextStep = () => {
    setSteps((prevStep) => Math.min(prevStep + 1, totalSteps));
    setCurrentStep(step);
  };
  const backStep = () => {
    setSteps((prevStep) => Math.min(prevStep - 1, totalSteps));
    setCurrentStep(step);
  };
  const [confirmation, setConfirmation] = useState(false);

  const location = useLocation();
  const data = location.state ? location.state.data : null;

  const clearAll = () => {
    setForm({
      address: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      market_type: "",
      how_hear_about_us_platform: "",
      how_hear_about_us_specific: "",
      bedrooms: "",
      bathrooms: "",
      square_footage: "",
      property_type: "",
      hoa_type: "",
      annual_hoa_fee: "",
      hvac_age: "",
      roof_age: "",
      foundation_type: "",
      finished_basement: "",
      kitchen_countertops: "",
      kitchen_appliances: "",
      has_pool: "",
      is_listed: "",
      waste_handling: "",
      interest_in_selling: "",
      property_owner: "",
      setup_meeting: "",
      meet_date: "",
      meet_time: "",
    });
    setSteps(1);
    dispatch(setStep(1));
  };

  const TimerApiCall = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let cleaned = ("" + form?.phone)?.replace(/\D/g, "");
    if (emailRegex.test(form?.email) || cleaned?.length == 11) {
      let urlWithParams = `${process.env.REACT_APP_API_KEY_URL}?type=query`;
      if (recordId) {
        urlWithParams = `${process.env.REACT_APP_API_KEY_URL}?type=query&record_id=${recordId}`;
      }
      await fetch(urlWithParams, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(form),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.record_id) {
            setRecordId(data?.record_id);
          }
        })
        .catch((error) => {
          console.log("ERROR =========", error);
        });
    }
  };

  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);

  // TIMER COMP

  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  let intervalId;

  useEffect(() => {
    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    if (timer >= 600) {
      TimerApiCall();
      setTimer(0);
    }

    return () => clearInterval(intervalId);
  }, [isRunning, timer]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setTimer(0);
    TimerApiCall();
  }, [step]);

  // TIMER COMP  WND

  return (
    <div className='form-modal'>
      {/* <div>{timer}</div> */}
      {confirmation == false ? (
        <h2 style={{ color: "black" }}>Let's Get To Know Your Home</h2>
      ) : (
        ""
      )}
      {step < totalSteps + 1 && confirmation == false ? (
        <>
          <p style={{ margin: "5px auto", fontSize: "13px" }}>
            Step <span>{step}</span> of <span>{totalSteps} </span>
            {step == 1 && "- Property Address"}
            {step == 2 && ""}
            {step == 3 && "- General Information"}
            {step == 4 && "- Property Information"}
            {step == 5 && "- Structural Information"}
            {step == 6 && "- Kitchen Details"}
            {step == 7 && "- Exterior Information"}
            {step == 8 && "- Booking Time"}
          </p>
          <div className='progressbar'>
            <ProgressBar currentStep={step} totalSteps={totalSteps} />
          </div>
        </>
      ) : null}
      {step == 1 && (
        <Stepper1
          nextStep={nextStep}
          setAddress={setAddress}
          address={address}
          form={form}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          setForm={setForm}
        />
      )}
      {step == 2 && (
        <Stepper2
          selectedLocation={selectedLocation}
          nextStep={nextStep}
          backStep={backStep}
          setAddress={setAddress}
          address={address}
          form={form}
          setForm={setForm}
        />
      )}
      {step == 3 && (
        <Stepper3
          nextStep={nextStep}
          backStep={backStep}
          form={form}
          setForm={setForm}
          recordId={recordId}
          setRecordId={setRecordId}
        />
      )}
      {step == 4 && (
        <Stepper4
          nextStep={nextStep}
          backStep={backStep}
          form={form}
          setForm={setForm}
        />
      )}
      {step == 5 && (
        <Stepper5
          nextStep={nextStep}
          backStep={backStep}
          form={form}
          setForm={setForm}
        />
      )}
      {step == 6 && (
        <Stepper6
          nextStep={nextStep}
          backStep={backStep}
          form={form}
          setForm={setForm}
        />
      )}
      {step >= 7 && (
        <Stepper7
          confirmation={confirmation}
          setConfirmation={setConfirmation}
          nextStep={nextStep}
          backStep={backStep}
          form={form}
          setForm={setForm}
          setTotalSteps={setTotalSteps}
          recordId={recordId}
          setRecordId={setRecordId}
        />
      )}

      <div className='starter'>
        {step == 1 || step == totalSteps ? (
          <p></p>
        ) : (
          <span style={{ fontStyle: "italic" }} onClick={clearAll}>
            Start Over
          </span>
        )}
        <span style={{ alignSelf: "flex-end", fontStyle: "italic" }}>
          Terms of Use
        </span>
      </div>
    </div>
  );
}
export default FormPage;
