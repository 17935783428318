import React from "react";
import "./AboutAbout.css";
import paraImageAboutAbout from "../images/ReviewPic1.jpeg";
import Slide from "react-reveal/Slide";

const AboutAbout = () => {
  return (
    <>
      <div className="AboutAboutMain">
        <Slide left>
          <div className="paraImage">
            {" "}
            <img src={paraImageAboutAbout} alt="" />
          </div>
        </Slide>

        <div className="para">
          <Slide left>
            <h3>About Yuna Homes</h3>
            <p>
              Yuna Homes is a specialized real estate company that delivers
              superior service tailored to meet your unique needs. We are not
              just a real estate company, we are your partners in navigating the
              often complex world of property transactions.
            </p>
          </Slide>
          <Slide left>
            <h4 className="expert">Our Expert Real Estate Team</h4>
            <p>
              Our team comprises dedicated, full-time professionals who are not
              only local and experienced but also passionately committed to
              making your real estate journey smooth and successful. We offer
              highly trained specialists who prioritize your needs, guiding you
              towards making the best possible decisions.
            </p>
          </Slide>
          <Slide left>
            <p>
              What sets us apart is our unique business model. We buy homes
              as-is, sparing you the hassle of repairs or refurbishments.
              Furthermore, we handle all closing costs, saving you from hidden
              expenses that often accompany property transactions. Our clients
              enjoy the luxury of zero commission, making us an incredibly
              cost-effective choice.
            </p>
          </Slide>
          <Slide left>
            <h4 className="expert">Your Lifetime Real Estate Partner</h4>
            <p>
              At Yuna Homes, we lead with empathy and a service-oriented
              mindset. Our aim is to form lifelong relationships with our
              clients. Our process is designed to work with your timeline,
              offering you the flexibility to move or transition at a pace that
              suits you.
            </p>
          </Slide>
          <Slide left>
            <h4 className="expert">Recognized Excellence</h4>
            <p>
              We are a rapidly rising star in the real estate industry, gaining
              recognition for our innovative approach and commitment to customer
              satisfaction. With Yuna Homes, you're not just engaging a real
              estate company, you're aligning with a dedicated partner who has
              your best interest at heart. Your journey is our journey.
            </p>
          </Slide>
          <div className="aboutUsEndLine"></div>
        </div>
      </div>
    </>
  );
};

export default AboutAbout;
