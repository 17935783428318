import React from "react";
import "./card.css";
import commas from "../images/commas.png";

const Card = (props) => {
  return (
    <>
      <div className="card">
 
        <div className="testimonial">
          <img className="testomonialImage" src={commas} alt="" />
          <p>{props.testi}</p>
       
          <p className="testName">{props.name}</p>
        </div>
        <div className="testimonialImages">
          <img src={props.image} alt="" />
        </div>
  
      </div>
    </>
  );
};

export default Card;
