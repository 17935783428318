import React from "react";
import room from "../images/room.webp";
import "./parallexEffect.css";
import FourthSec from "./FourthSec";
import ThirdSec from "./ThirdSec";
import FifthReady from "./FifthReady";
import house from "../images/house.png"
import { ImImages } from "react-icons/im";

const ParallexEffect = () => {
  return (
    <>
      <ThirdSec />
      <div class="">
        <FourthSec
          heading="Ready For Your Offer? "
          buttonText="Get My Offer"
          link={"/form"}
          backgroundd = {`url(${house})`}
        />
        <FifthReady />
      </div>
    </>
  );
};

export default ParallexEffect;
