import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import "./Stepper5.css";
import Loader from "../Loader/Loader";
import { BsChevronDown } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';

import { useSelector } from "react-redux";
function Stepper5({ nextStep, backStep, setForm, form }) {
  const selectForms = useSelector(state=>state)
  function toPascalCase(str) {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
  const [focusedInput, setFocusedInput] = useState(null);

  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };
  const handleBack = () => {
    setLoader(true);
    let intervalId = setInterval(() => {
      backStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  };
  const [loader, setLoader] = useState(false);
  const handleChange = (event) => {
    let value = event.target.value;
    if (event.target.type === 'number' && value < 0) {
      value = 0;
    }
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleNext = () => {
    if (!form.hvac_age) {
      return toast.error("Please Fill HVAC Age");
    }
    if(form.hvac_age < 1){
    return toast.error("HVAC Age Cannot Be Negative");
    }

    if (!form.roof_age) {
      return toast.error("Please Fill Roof Age");
    }
     if(form.roof_age < 1){
      return toast.error("Roof Age Cannot Be Negative");
    }
  
    // if (!form.foundation_type) {
    //   return toast.error("Please Fill Foundation Type");
    // }
    // if(form.foundation_type == "Basement" && !form.finished_basement){
    //   return toast.error("Please Fill Finished Basement");
    // }
  
    // All fields validated
    setLoader(true);
    let intervalId = setInterval(() => {
      nextStep();
    }, 2000);
    setTimeout(() => {
      setLoader(false);
      clearInterval(intervalId);
    }, 2000);
  };

  return (
    <div className="step3">
      <ToastContainer />
      <div className="input-field">
        <label>
          Approximate age of HVAC Units<span className="importent">*</span>
        </label>
        <input
          name="hvac_age"
          onFocus={() => handleInputFocus("hvac_age")}
          onBlur={handleInputBlur}
          className={focusedInput === "hvac_age" ? "focused" : ""}
          value={form.hvac_age}
          min={1}
          onChange={handleChange}
          
          placeholder=""
          type="number"
        />
      </div>
      <div className="input-field">
        <label>
          About how old is the roof<span className="importent">*</span>
        </label>
        <input
          name="roof_age"
          value={form.roof_age}
          onChange={handleChange}
          min={1}
          placeholder=""
          onFocus={() => handleInputFocus("roof_age")}
          onBlur={handleInputBlur}
          className={focusedInput === "roof_age" ? "focused" : ""}
          type="number"
        />
      </div>
      
      {loader && <Loader />}
      <div className="search">
        <button className="back" onClick={handleBack}>
          Back
        </button>
        <button onClick={handleNext} disabled={loader}>Next</button>
      </div>
    </div>
  );
}

Stepper5.propTypes = {
  nextStep: PropTypes.func.isRequired,
  backStep: PropTypes.func.isRequired,
};

export default Stepper5;
